<template>
  <form
    autocomplete="off"
    @submit.prevent="submitContactUsForm()"
    id="contact-us-form"
    data-test="contact-us-form"
    class="md:px-6 text-left">
    <div v-if="isItMygoalgetter" class="text-16px text-center pb-4">
      Our support hotline is available:
      <a href="tel:0730180400" class="text-black" target="_blank">(07) 3018 0400</a>
    </div>

    <input-text
      class="w-full my-6"
      input-classes="paragraph-2 text-left"
      place-holder="Name"
      label="Name"
      name="contact-us-name"
      :value="name"
      @input="updateName"
      :error="errors.name" />
    <input-text
      type="email"
      class="w-full my-6"
      input-classes="paragraph-2 text-left"
      place-holder="Email address"
      label="Email"
      name="contact-us-email"
      :value="email"
      @input="updateEmail"
      :error="errors.email"
      :allow-digits="true" />
    <input-phone-number
      class="w-full my-6 gap-3"
      rows="120"
      place-holder="Enter your phone number"
      label="Phone"
      name="contact-phone-number"
      :value="phone_number"
      @input="updatePhoneNumber" />
    <input-text
      v-if="type === 'business'"
      class="w-full my-6"
      place-holder="Enter the name of your company"
      label="Company"
      name="business"
      :value="business"
      @input="updateBusiness"
      :error="errors.business"
      :allow-digits="true" />
    <input-select-box
      v-if="type === 'default'"
      class="w-full my-6"
      :options="messageTypes"
      label="What's your message about?"
      name="Message Type" />
    <contact-message-types
      class="md:flex items-center my-6 text-left"
      v-if="type === 'adviser' && this.isItSurmount"
      :message-types="surmountMessageTypes"
      :errors="errors.alternateType"
      @click="selectSurmountMessageType" />
    <contact-message-types
      class="md:flex items-center my-6 text-left"
      v-else-if="type === 'adviser'"
      :message-types="this.alternateMessageTypes"
      :errors="errors.alternateType"
      @click="selectMessageType" />
    <input-select-box
      v-if="type === 'adviser' && this.isItFordscott"
      :select-input="this.ageRange"
      @update-select-box="updateAgeRange"
      :options="this.ageBrackets"
      name="Age Brackets"
      class="md:flex my-6 text-left w-full"
      label="Age" />

    <input-text-area
      class="w-full my-6"
      rows="120"
      place-holder="Type in your message here"
      label="Message"
      name="contact-us-message"
      :value="message"
      @input="updateMessage"
      :error="errors.message" />
    <div class="flex justify-center">
      <custom-button
        :loading="loading"
        colour="white"
        class="w-52 bg-white text-black border-otivo-blue border my-6 text-center"
        @click="submitContactUsForm()">
        Send
      </custom-button>
    </div>
  </form>
</template>

<script>
import ContactMessageTypes from '@/components/BrandComponents/ContactUs/ContactMessageTypes.vue'
import CustomButton from '@/components/CustomButton/CustomButton.vue'
import InputPhoneNumber from '@/components/BasicInputs/InputPhoneNumber.vue'
import InputSelectBox from '@/components/BasicInputs/InputSelectBox.vue'
import InputText from '@/components/BasicInputs/InputText.vue'
import InputTextArea from '@/components/BasicInputs/InputTextArea.vue'
import MiscellaneousService from '@/services/MiscellaneousService'
import { mapGetters } from 'vuex'
import { alternateContactMessageTypes } from '@/lib/WhiteLabelHelpers'
import { validateEmailAddress } from '@/lib/ValidationHelper'
import { useToast } from '@/composables/useToast.ts'

const { successToast, errorToast } = useToast()

export default {
  name: 'ContactUsForm',
  components: {
    ContactMessageTypes,
    InputSelectBox,
    InputPhoneNumber,
    InputText,
    InputTextArea,
    CustomButton,
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      business: '',
      phone_number: 61,
      loading: false,
      errors: {
        name: '',
        email: '',
        message: '',
        business: '',
      },
      messageTypes: [
        {
          label: 'General enquiry',
          value: 'general',
          selected: true,
          disabled: false,
        },
        {
          label: 'Feedback',
          value: 'feedback',
          selected: false,
          disabled: false,
        },
        {
          label: 'Media',
          value: 'media',
          selected: false,
          disabled: false,
        },
        {
          label: 'Careers',
          value: 'careers',
          selected: false,
          disabled: false,
        },
      ],
      surmountMessageTypes: [
        {
          name: 'Salary packaging',
          value: 'salary_packaging',
          selected: false,
        },
        {
          name: 'Insurance',
          value: 'insurance',
          selected: false,
        },
        {
          name: 'Other',
          value: 'other',
          selected: false,
        },
        {
          name: 'Superannuation',
          value: 'super',
          selected: false,
        },
        {
          name: 'Investments',
          value: 'investments',
          selected: false,
        },
      ],
      ageBrackets: [
        {
          label: '-',
          value: null,
          selected: true,
        },
        {
          label: '18-35',
          value: '18-35',
        },
        {
          label: '36-50',
          value: '36-50',
        },
        {
          label: '51-64',
          value: '51-64',
        },
        {
          label: '65+',
          value: '65+',
        },
      ],
      ageRange: null,
      alternateTypesSelected: 0,
    }
  },

  props: {
    type: {
      type: String,
      default: 'default',
    },
  },

  created() {
    this.email = this.profile.email
    this.name = this.profile.firstName
    this.whitelabel_id = this.getCurrentWhitelabelObject.id
  },

  computed: {
    ...mapGetters([
      'profile',
      'isItMygoalgetter',
      'getCurrentWhitelabelObject',
      'isItAhomeformumordad',
      'isItFordscott',
      'isItSurmount',
    ]),
    alternateMessageTypes() {
      const messageTypes = alternateContactMessageTypes()
      if (this.isItAhomeformumordad) {
        messageTypes.splice(0, 1, {
          name: 'Aged Care',
          value: 'aged_care',
          selected: false,
        })
      }
      return messageTypes
    },
  },

  methods: {
    submitContactUsForm() {
      if (this.validateContactUsForm()) {
        this.loading = true
        const payload = {
          firstName: this.name,
          email: this.email,
          message: this.message,
          phone_number: this.phone_number,
          whitelabel_id: this.whitelabel_id,
          business: this.business,
        }
        if (this.type === 'adviser') {
          payload.type = this.type
          const types = this.isItSurmount ? this.surmountMessageTypes : this.alternateMessageTypes
          payload.discussions = types
            .filter((el) => el.selected)
            .map((el) => el.value)
            .join(',')
        }
        if (this.type === 'adviser' && this.isItFordscott) payload.age_bracket = this.ageRange
        MiscellaneousService.submitContactUsForm(payload)
          .then(() => {
            if (this.type === 'adviser') this.$store.dispatch('toggleAdviserContactModal')
            this.loading = false
            successToast('We got your message, we will be in touch.')
            this.name = ''
            this.email = ''
            this.message = ''
            this.phone_number = 61
            this.$store.dispatch('renderContactUsModal', false)
          })
          .catch((err) => {
            this.loading = false
            errorToast('Error occurred, please try again.')
            this.$store.dispatch('renderContactUsModal', false)
            throw err
          })
      }
    },
    validateContactUsForm() {
      this.errors = {
        name: '',
        email: '',
        message: '',
        business: '',
        alternateType: '',
      }
      let validated = true
      if (this.name === '') {
        validated = false
        this.errors.name = 'Please enter your name'
      }

      if (!validateEmailAddress(this.email)) {
        validated = false
        this.errors.email = 'Please valid email address'
      }

      if (this.message === '') {
        validated = false
        this.errors.message = 'Please provide a message'
      }

      if (this.business && this.business === '') {
        validated = false
        this.errors.business = 'Please provide your companies name, or submit a user contact form.'
      }

      if (this.type === 'adviser' && this.isItSurmount && this.alternateTypesSelected < 1) {
        validated = false
        this.errors.alternateType = 'Please select at least one of the message types available'
      }

      return validated
    },
    selectSurmountMessageType(value, index) {
      value ? (this.alternateTypesSelected += 1) : (this.alternateTypesSelected -= 1)
      this.surmountMessageTypes[index].selected = value
    },
    selectMessageType(value, index) {
      value ? (this.alternateTypesSelected += 1) : (this.alternateTypesSelected -= 1)
      this.alternateMessageTypes[index].selected = value
    },
    updateAgeRange(value) {
      this.ageRange = value
    },
    updateName(value) {
      this.name = value
    },
    updateEmail(value) {
      this.email = value
    },
    updatePhoneNumber(value) {
      this.phone_number = value
    },
    updateBusiness(value) {
      this.business = value
    },
    updateMessage(value) {
      this.message = value
    },
  },
}
</script>

<style scoped></style>
