<template>
  <div>
    <p class="mb-3">Member declaration</p>
    <div class="flex gap-3 my-2" data-test="memberDeclarationCheckbox">
      <SmallCheckbox
        class="check-box items-center"
        :checked="memberDeclaration"
        name="Member Declaration"
        @click="handleToggleDeclaration"
        @keyup.enter="handleToggleDeclaration"
        :tabindex="1"
        label="I declare that:" />
    </div>
    <!--    SUPERFUND_SWITCH_CONFIRM_DECLARATION_BLOCK declarations-->
    <ul class="list-disc list-inside">
      <li v-html="props.content.declaration_1"></li>
      <li v-html="props.content.declaration_2"></li>
      <li v-html="props.content.declaration_3"></li>
      <li v-html="props.content.declaration_4"></li>
      <li v-html="props.content.declaration_5"></li>
      <li v-html="props.content.declaration_6"></li>
      <li v-html="props.content.declaration_7"></li>
      <li v-html="props.content.declaration_8"></li>
      <li v-if="props.content?.declaration_9" v-html="props.content.declaration_9"></li>
    </ul>
    <br />
    <!-- CONFIRM_TMD_TEXT   -->
    <p v-if="props.content.confirm_tmd_text">
      {{ props.content.confirm_tmd_text }}
      <a target="_blank" :href="props.content.tmd_link">{{ props.content.tmd_link }}</a>
    </p>
    <p class="mt-6" v-if="productName">Note that you're invested in: {{ productName }}</p>
    <!--    END -->
  </div>
</template>

<script setup lang="ts">
import SmallCheckbox from '@/components/BasicInputs/CustomCheckBox/SmallCheckbox.vue'

type Props = {
  memberDeclaration: boolean
  productName: string
  content: {
    declaration_1: string
    declaration_2: string
    declaration_3: string
    declaration_4: string
    declaration_5: string
    declaration_6: string
    declaration_7: string
    declaration_8: string
    declaration_9?: string
    confirm_tmd_text: string
    tmd_link: string
  }
}

const props = defineProps<Props>()
const emits = defineEmits<{ (e: 'onToggle', val: boolean): void }>()

const handleToggleDeclaration = () => {
  emits('onToggle', !props.memberDeclaration)
}
</script>
