<template>
  <section class="max-w-[560px]">
    <BeatLoader
      v-if="loadingProduct"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      color="var(--otivo-blue)"
      size="25px" />
    <div class="flex flex-col gap-y-[20px]" v-else>
      <h5 class="font-bold pt-12">Please confirm before proceeding...</h5>
      <div v-if="showTrialCopy" class="flex flex-col items-center text-blue-1 gap-y-[20px]">
        <p>
          You'll have unlimited access to Otivo’s advice for 7 days from
          {{ format(currentDate, 'dd MMMM yyyy') }} ending on
          {{ format(trialExpiry, 'dd MMMM yyyy') }}.
        </p>
        <p>
          At the end of your trial period, you’ll be charged ${{ price }}, unless you cancel it
          prior to the expiry date.
        </p>
        <p>
          Once you've read the privacy collection statement and agree to proceed, you'll receive an
          instant email providing guidance and access to your personal advice portal.
        </p>
      </div>
      <div v-else class="flex flex-col items-center text-blue-1 gap-y-[20px]">
        <p>You’ll have unlimited access to Otivo’s advice for 12 months, from {{ format(currentDate, 'dd MMMM yyyy') }}
          to {{ format(addYears(currentDate, 1), 'dd MMMM yyyy') }}. We’ll send you a friendly reminder 30 days before
          your subscription ends, so you can decide if you’d like to continue receiving our advice. If at any time you
          need us, we can be contacted at info@otivo.com</p>
        <p>If you choose to continue and agree to the privacy collection statement, you'll receive another email giving
          you access to your personal advice portal.</p>
      </div>

      <div
        class="rounded-sm w-full bg-white p-4 pr-2 border-1px border-grey-field overflow-auto"
        :class="{ 'h-auto': open }">
        <div class="flex cursor-pointer" @click="acknowledgePrivacyCollection">
          <span class="w-full text-grey-3 paragraph-2">
            Please read our privacy collection statement
          </span>
          <ChevronDown
            class="self-center mr-4"
            :class="{
              'transform rotate-180': open,
            }" />
        </div>
        <div v-if="open" class="mt-4 py-3 bg-blue-5 rounded px-4 text-grey-1 collapsible__content">
          <span>
            Otivo Pty Ltd ABN 47 602 457 732 (Otivo, we, us, or our) is committed to protecting your
            privacy. We collect your personal information from you (or your partner) when you (or
            your partner) register on, and use our platform to create financial plans.
            <br />
            <br />
          </span>
          <span>
            We use the personal information we collect for the purpose of providing advice on your
            financial situation. We do not trade, rent or sell your information or disclose it to
            any third parties.<br /><br />
          </span>
          <span>
            Our Privacy Policy contains more information about how to access and correct the
            information we hold about you and how to make a privacy related complaint. You can read
            it by visiting our website
            <a href="/content/privacy" target="_blank"> Privacy Policy. </a>
          </span>
        </div>
      </div>
      <div class="flex flex-row gap-x-[10px] cursor-pointer" @click="agreed = !agreed">
        <BaseCheckbox v-model:checked="agreed" name="terms-and-conditions" />
        <p class="paragraph-2 text-grey-1">
          I understand that the advice is provided by Otivo (Otivo Pty Ltd ABN 47 602 457 732 AFSL
          and Australian Credit Licence No. 485665) and is limited and does not cover all aspects of
          my circumstances. I agree to receive all communications electronically.
        </p>
      </div>

      <OtivoButton @click="handleNext" :loading="loading" class="mt-[60px] w-full sm:w-fit"
        >Next</OtivoButton
      >
      <span class="otivo-error-message text-center" v-if="error"> {{ error }}</span>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import ChevronDown from '@/components/icons/ChevronDown.vue'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { sendLegalWelcomeEmail } from '@/store/pinia/adminPortal/EmailManager.ts'
import { userAuth0Id } from '@/lib/AuthenticatorPlugin.ts'
import { addDays, addYears, format } from 'date-fns'
import { useProductStore } from '@/store/pinia/ProductStore.ts'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'

//todo: refactor accountNumber to user store in hydra
const accountCreationStore = useAccountCreationStore()
const userStore = useUserStore()
const userAccountNumber = accountCreationStore.userAccountNumber
const getUser = computed(() => userStore.getUser)
//end todo
const emit = defineEmits<{
  (e: 'next', data: string): void
}>()
const productStore = useProductStore()

const showTrialCopy = useSessionStore().getWhitelabelData.identifier === 'OTIVO'

const product = computed(() => productStore.product)
const price = computed(() => product.value?.yearly_price)
const currentDate = ref(new Date())
const trialExpiry = ref(addDays(new Date(), 7))

const agreed = ref(false)
const open = ref(false)
const loading = ref(false)
const loadingProduct = ref(false)
const error = ref('')
const acknowledgedPrivacy = ref(false)
const acknowledgePrivacyCollection = () => {
  open.value = !open.value
  if (open.value) acknowledgedPrivacy.value = true
}
const handleNext = async () => {
  error.value = ''
  loading.value = true
  // todo: refactor consent management in hydra
  if (agreed.value && acknowledgedPrivacy.value) {
    try {
      const account_id = Number(userAccountNumber ?? getUser.value?.account_id)
      if (!account_id) {
        throw new Error('Account ID not found')
      }
      await accountCreationStore.setTermsAndConditionsAgreed(account_id)
      await sendLegalWelcomeEmail(userAuth0Id.value)

      emit('next', 'terms and conditions accepted')
    } catch (e) {
      console.error(e)
      loading.value = false
    }
  } else {
    error.value = 'Please agree to the privacy collection statement to continue'
  }
  loading.value = false
}
onBeforeMount(async () => {
  loadingProduct.value = true
  await productStore.fetchProductDetails()
  loadingProduct.value = false
})
</script>

<style></style>
