<template>
  <BaseView>
    <div id="about-us" v-if="pageContent?.currentPage === 'aboutOtivo'" class="bg-blue-5">
      <Breadcrumbs class="bg-blue-5" />
      <OurStory />
      <BigOffsetCurve fill="white" />
      <OurMission />
      <RegularCurve fill="blue-5" class="bg-white" />
      <LeadershipTeam />
      <RegularCurve fill="blue-1" />
      <OtivoBoard />
      <NamingOtivo class="bg-[#000032] py-10 px-[20px]" />
      <JoinTheTeam />
      <RegularCurve fill="blue-1" class="bg-grey-1 h-6 w-full md:hidden" :rotate="true" />
      <ThreeTileDisplay
        class="bg-grey-1"
        text-colour="white"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
      <RegularCurve fill="midnight" class="bg-grey-1 h-6 w-full md:hidden" />
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import BaseView from '@/views/BaseView.vue'
import BigOffsetCurve from '@/components/Shapes/BigOffsetCurve.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import NamingOtivo from '@/components/BrandComponents/ExtraContent/NamingOtivo.vue'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import LeadershipTeam from '@/components/BrandComponents/ExtraContent/LeadershipTeam.vue'
import OtivoBoard from '@/components/BrandComponents/ExtraContent/OtivoBoard.vue'
import OurStory from '@/components/BrandComponents/ExtraContent/OurStory.vue'
import OurMission from '@/components/BrandComponents/ExtraContent/OurMission.vue'
import JoinTheTeam from '@/components/BrandComponents/ExtraContent/JoinTheTeam.vue'
import { useHead } from '@unhead/vue'

const store = useStore()
onBeforeMount(() => store.dispatch('ContentStore/getContent', 'aboutOtivo'))
useHead({
  title: 'About Otivo digital advice solutions',
})
const pageContent = computed(() => {
  const x = store.getters['ContentStore/pageContent']
  console.log(x)
  return x
})
</script>
