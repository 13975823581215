<template>
  <div class="flex flex-col">
    <h3>Are you sure you want to make this change?</h3>
    <p class="paragraph-1 mt-5">
      Updating your {{ routeName }} will reset your
      <strong class="text-grey-2">investments, salary sacrifice, and/or debt</strong>
      advice, so please let us know if you want to proceed.
    </p>
    <div class="flex flex-col justify-end md:flex-row mt-10 gap-5 md:gap-4">
      <OtivoButton @click="onClose" colour="white" data-test="cancelBtn">Cancel</OtivoButton>
      <OtivoButton @click="onProceed" colour="blue" data-test="proceedBtn">Proceed</OtivoButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import OtivoButton from '@/components/OtivoButton.vue'

type Props = {
  onClose: () => void
  onProceed: () => void
}

defineProps<Props>()

const route = useRoute()
const routeName = computed(() =>
  route.path?.includes('financial') ? 'financial summary' : 'account details',
)
</script>
