<template>
  <div data-test="unsavedChanges" class="h-fit">
    <h3 class="text-blue-1">{{ heading }}</h3>
    <p class="paragraph-1 mt-[40px]">
      You're about to leave this page without saving, so any changes will be lost. Are you sure you
      want to continue without saving?
    </p>

    <div class="flex flex-col md:flex-row gap-[20px] mt-[40px]">
      <OtivoButton colour="white" data-test="leaveWithoutSaving" @click="leaveWithoutSaving">Leave without saving</OtivoButton>
      <OtivoButton data-test="continueEditing" @click="continueEditing">Continue editing</OtivoButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'

const modalStore = useModalStore()

interface Props {
  heading: string
  leaveWithoutSaving: () => void
  continueEditing: () => void
}

const props = defineProps<Props>()

const leaveWithoutSaving = () => {
  props.leaveWithoutSaving()
  modalStore.closeModal()
}

const continueEditing = () => {
  props.continueEditing()
  modalStore.closeModal()
}
</script>

<style scoped></style>
