import { ref } from 'vue'
import { defineStore } from 'pinia'
import investmentsService from '@/services/InvestmentsService.ts'
import { Investment } from '@/types/Investment.ts'

export const useInvestmentStore = defineStore('investmentStore', () => {
  const investments = ref<Array<Investment>>([])

  const fetchInvestments = async (params: Record<string, unknown>) => {
    try {
      const response = await investmentsService.getAllInvestments(params)
      investments.value = response?.data.data || []
    } catch (error) {
      console.error('Failed to fetch liabilities', error)
      investments.value = []
    }
  }

  const updateInvestment = async (investment: Partial<Investment>) => {
    try {
      await investmentsService.updateInvestment(investment)
    } catch (error) {
      console.error('Failed to update investment', error)
    }
  }

  return {
    investments,
    fetchInvestments,
    updateInvestment,
  }
})
