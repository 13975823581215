<template>
  <RouterLink
    :to="to"
    class="w-full flex items-center rounded-sm p-[10px] font-semibold text-base text-blue-1 hover:text-otivo-blue hover:bg-blue-5"
    active-class="bg-blue-5 text-otivo-blue">
    <component :is="icon" v-if="icon" class="w-6 h-6 p-px mr-[10px]" />
    <div class="flex w-full pr-[10px] grow whitespace-nowrap">{{ label }}</div>
  </RouterLink>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import type { Component, VNode } from 'vue'
type ExtraType =
  | string
  | VNode // For HTML or component
  | Component // In case you want to accept a component type directly
  | (() => VNode | string); //

const props = withDefaults(
  defineProps<{
    to: RouteLocationRaw
    icon: ExtraType | null
    label: string
    active?: boolean
  }>(),
  {
    icon: null,
    active: false,
  },
)
</script>

<style scoped></style>
