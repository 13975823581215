<template>
  <basic-input class="flex gap-3" :class="{ 'md:flex-col': stackLabel }">
    <template #input-label>
      <div v-if="label !== ''" class="flex set-w-28 md:set-w-48 pt-4 paragraph-2">
        <input-label :for="name" :label="label" />
      </div>
    </template>
    <template #input-box>
      <div class="flex flex-row">
        <BaseDropDown
          class="w-[220px]"
          container-class="w-full"
          input-class="!border-grey-2 rounded-l rounded-r-none w-full bg-white border-r-0 "
          name="message-type"
          :default-value="selectedNumberOption"
          :options="numberOptions"
          @selected="handleSelectedNumberOption" />
        <input
          autocomplete="off"
          class="block w-full bg-transparent border border-grey-2 hover:otivo-outline focus-within:otivo-outline active:otivo-outline rounded-r shadow-none p-4 -ml-px z-10"
          :class="
            error ? inputClass + ' text-red-dark border-red-1 ring ring-red-light' : inputClass
          "
          :id="name"
          type="text"
          :placeholder="selectedNumberOption.placeholder"
          :value="phoneNumber"
          @input="setValue(($event.target as HTMLInputElement).value)"
          :disabled="disabled" />
      </div>
    </template>
    <template #input-errors>
      <input-error v-if="computedError" :error="computedError" />
    </template>
  </basic-input>
</template>
<!--
 TODO: Temporary component to handle phone number input with country code
-->
<script lang="ts" setup>
import { computed, ref } from 'vue'
import InputError from './InputError.vue'
import InputLabel from './InputLabel.vue'
import BasicInput from '@/components/BasicInputs/BasicInput.vue'
import BaseDropDown from '@/components/Inputs/BaseDropDown.vue'

type Props = {
  name: string
  label?: string
  phoneNumber: string | null
  countryCode: string
  inputClass?: string
  error?: string
  stackLabel?: boolean
  disabled?: boolean
  onlyAustralianNumber?: boolean
}

type CountryDropdownType = {
  label: string
  value: string
  placeholder: string
  areaCodeLength: number
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  inputClass: 'paragraph-2',
  error: '',
  stackLabel: false,
  disabled: false,
  onlyAustralianNumber: true,
})

const emit = defineEmits<{
  (e: 'update:phoneNumber', value: string | null): void
  (e: 'update:countryCode', value: string): void
}>()

const allNumberOptions = [
  { label: '🇦🇺 AUS +61', value: '+61', placeholder: '412 345 678', areaCodeLength: 3 },
  { label: '🇮🇳 IND +91', value: '+91', placeholder: '22 1234 5678', areaCodeLength: 2 },
] as Array<CountryDropdownType>

const selectedNumberOption = ref<CountryDropdownType>(
  allNumberOptions.find((option) => option.value === props.countryCode) || allNumberOptions[0],
)

const localError = ref(props.error)

const numberOptions = computed(() => {
  if (props.onlyAustralianNumber) {
    return allNumberOptions.filter((option) => option.value === '+61')
  }
  return allNumberOptions
})

const computedError = computed(() => props.error || localError.value)

const setValue = (value: string) => {
  value = value.replace(/\D/g, '')
  if (!value.length) {
    emit('update:phoneNumber', null)
    return
  }
  const addToAreaCodeLength = value.charAt(0) === '0' ? 1 : 0

  if (value.length <= selectedNumberOption.value.areaCodeLength + addToAreaCodeLength) {
    emit('update:phoneNumber', value)
    return
  }

  switch (selectedNumberOption.value.value) {
    case '+61':
      value = formatPhoneNumber(
        value,
        selectedNumberOption.value.areaCodeLength + addToAreaCodeLength,
        [3, 3],
      )
      break
    case '+91':
      value = formatPhoneNumber(
        value,
        selectedNumberOption.value.areaCodeLength + addToAreaCodeLength,
        [4, 4],
      )
      break
  }
  emit('update:phoneNumber', value)
}

const formatPhoneNumber = (
  phoneNumber: string,
  areaCodeLength: number,
  localNumberSplit: Array<number>,
) => {
  const cleanNumber = phoneNumber.replace(/\D/g, '')

  // Extract the area code and local number based on provided lengths
  const areaCode = cleanNumber.substring(0, areaCodeLength)
  let localNumber = cleanNumber.substring(areaCodeLength)

  // Format the local number in parts if specified
  let formattedLocalNumber = ''
  localNumberSplit.forEach((splitSize, index) => {
    if (index > 0) formattedLocalNumber += ' '
    formattedLocalNumber += localNumber.substring(0, splitSize)
    localNumber = localNumber.substring(splitSize)
  })
  formattedLocalNumber = formattedLocalNumber.trim()

  return `${areaCode} ${formattedLocalNumber}`
}

const handleSelectedNumberOption = (option: CountryDropdownType) => {
  emit('update:phoneNumber', props.phoneNumber || null)
  emit('update:countryCode', option.value)
  selectedNumberOption.value = option
}
</script>
