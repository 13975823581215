<template>
  <div id="fitness-test-intro" class="pt-5 pb-10  px-10" data-test="fitnessTestIntroModal">
    <h3 class="py-2 text-grey-1">Free financial fitness test</h3>
    <div class="flex relative max-w-3xl py-5">
      <img :src="ThisCouldBeYou" alt="ThisCouldBeYou" class="hidden md:block absolute" />
      <img
        :src="FitnessFreedom"
        alt="FitnessFreedom"
        class="hidden md:block ml-16 mt-2" />
    </div>

    <div class="py-5">
      <p class="paragraph-1 md:py-4 text-grey-1">
        Want to know how you shape up against other Aussies? Take our quick survey and we’ll let you
        know your financial fitness score.
      </p>
      <p class="paragraph-1 pt-4 text-grey-1">
        To start your free workout, just add your email and click the button below.
      </p>
    </div>

    <div class="flex gap-6 text-grey-1">
      <form @submit.prevent="startTheTest" class="flex-1">
        <UserEmail
          class="py-4 max-w-sm"
          label=""
          name="fitness-email"
          place-holder="Email address"
          :error="emailError" />

        <p class="paragraph-1 py-5">We will send you the score to your email.</p>

        <CustomButton
          class="text-white bg-otivo-blue mt-4 w-42 h-16"
          colour="blue"
          type="submit"
          data-test="fitnessTestStartBtn"
          :large-text="true"
          :disabled="!validatedEmail">
          Let's go
        </CustomButton>
      </form>
    </div>
    <img :src="FitnessFreedom" alt="FitnessFreedom" class="m-auto h-80% w-80% pb-6 md:hidden" />
  </div>
</template>

<script lang="ts" setup>
import UserEmail from '@/components/Profile/BasicInfo/Email/UserEmail.vue'
import CustomButton from '@/components/CustomButton/CustomButton.vue'
import ThisCouldBeYou from '@/components/SVGS/ThisCouldBeYou.svg?url'
import FitnessFreedom from '@/components/SVGS/FitnessFreedom.svg?url'
import { computed, ref } from 'vue'
import { validateEmailAddress as validate } from '@/lib/ValidationHelper'
import { useStore } from 'vuex'

const store = useStore()
const emit = defineEmits(['revealQuestions'])

const emailError = ref('')
const validatedEmail = computed(() => validate(store.state.profile.email))

const startTheTest = () => {
  if (validatedEmail.value) {
    emit('revealQuestions')
  } else {
    emailError.value = 'Please provide valid email'
  }
}
</script>
