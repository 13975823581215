import { inject, provide, ref, Ref } from 'vue'
import Authenticator, { ISignupObject, LoginObject } from '@/lib/Authenticator'
import getApiInstance from '@/services/Api'
import { GetTokenSilentlyOptions, IdToken } from '@auth0/auth0-spa-js'
import { getRootUrl } from '@/composables/getRootUrl'
import { User as Auth0User } from '@auth0/auth0-spa-js/src/global.ts'
import useInactivityTimer from '@/composables/inactivityTimeout.ts'

const api = getApiInstance()

export interface User extends Auth0User {
  user_has_password: boolean
  user_role: string
  legacy_user: boolean
}

export interface IAuth0Plugin {
  isAuthenticated: Ref<boolean>
  auth0Client: Ref<Authenticator>
  userAuth0Id: Ref<string>
  user: Ref<User>
  createClient: () => Promise<void>
  createAccount: (redirectUrl?: string) => void
  login: () => Promise<void>
  logout: () => Promise<void>
  handleRedirectCallback: () => Promise<void>
  checkSession: () => Promise<void>
  getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>
}

export const auth0Client = ref<Authenticator>()
export const isAuthenticated = ref(false)
export const user = ref<User>()
export const userRole = ref<string>('')
export const userAuth0Id = ref<string>('')
const error = ref<unknown>()
const connection = ref<string | null>()

export const Auth0Symbol = Symbol('auth0')

const AUTH0_SCOPE = 'openid offline_access profile email'

export const createClient = async (
  override: string | null,
  connectionString?: string,
  to?: string,
) => {
  auth0Client.value = new Authenticator({
    domain: import.meta.env.VITE_DOMAIN,
    clientId: override || import.meta.env.VITE_CLIENT_ID,
    audience: import.meta.env.VITE_AUDIENCE,
    scope: AUTH0_SCOPE,
    useRefreshTokens: true,
    useTokenFallback: true,
  })
  if (connectionString) connection.value = connectionString
  console.group('Auth0 setup')
  try {
    await auth0Client.value?.createClient()
    const auth = await auth0Client.value?.checkIfAuthenticated()
    if (auth) {
      const activeManagedUser = JSON.parse(
        window.localStorage.getItem('activeManagedUser') as string,
      )
      if (activeManagedUser) api.setClientHeader(activeManagedUser.auth0Id)
      const url = await handleAuthenticatedSession(to)
      console.info('[Auth0] session authenticated - redirect to:', url)
      return url
    }
  } catch (e) {
    console.error('error creating client', e)
  } finally {
    console.groupEnd()
  }
}

export const createAccount = (redirectUrl = `${window.location.origin}/create-account`) => {
  try {
    auth0Client.value?.createAccount({
      authorizationParams: {
        connection: connection.value,
        redirect_uri: redirectUrl,
        screen_hint: 'signup',
      },
    })
  } catch (err) {
    console.warn('error creating account @ plugin', err)
  }
}

export const login = (connectionOverride?: string) => {
  try {
    auth0Client.value?.login({
      authorizationParams: {
        connection: connectionOverride ?? connection.value,
        redirect_uri: `${window.location.origin}/login/callback`,
      },
    })
  } catch (err) {
    console.warn('error logging in @ plugin', err)
    logout()
  }
}

export const loginWithEmail = async (object: LoginObject) => {
  if (!auth0Client.value) return
  return auth0Client.value?.loginWithEmail(object)
}

export const logout = (returnTo?: string) => {
  if (!auth0Client.value) {
    return
  }
  useInactivityTimer().stop()
  isAuthenticated.value = false
  auth0Client.value?.logout({
    returnTo: returnTo || window.location.origin,
  })
}

const hasValidSearchParams = () => {
  const params = new URLSearchParams(window.location.search)
  const hasError = params.has('error')
  const hasCode = params.has('code')
  const hasState = params.has('state')

  if (hasError) {
    // error.value = new Error(params.get('error_description') || 'error completing login process')
    throw new Error(params.get('error_description') as string)
  }

  return hasCode && hasState
}

export const handleCreateCallback = async () => {
  console.info('[Auth0] handling create callback')
  try {
    if (hasValidSearchParams()) {
      await createNewSession()
      if (user.value?.user_has_password) return '/dashboard'

      return '/create-account/details'
    }
  } catch (e) {
    console.error(e)
    return '/logout'
  }
}

const handleAuthenticatedSession = async (redirectUrl?: string): Promise<string | undefined> => {
  if (!auth0Client.value) {
    throw new Error('No Auth0 Client')
  }

  user.value = await auth0Client.value.getUser()
  if (!user.value) {
    throw new Error(
      'No User returned from Auth0, if you see this something has happened with auth0',
    )
  }

  console.info('[Auth0] handling authenticated session')
  await getAccessToken()

  return redirectUrl ?? getRootUrl(userRole.value)
}

export const handleRedirectCallback = async (redirectUrl?: string): Promise<string | undefined> => {
  if (!auth0Client.value) {
    throw new Error('No Auth0 Client')
  }

  const sessionAuthenticated = await auth0Client.value.checkIfAuthenticated()
  if (sessionAuthenticated) {
    return handleAuthenticatedSession(redirectUrl)
  }

  if (hasValidSearchParams()) {
    return createNewSession()
  }
}

const createNewSession = async (): Promise<string | undefined> => {
  console.info('[Auth0] creating new session')
  if (!auth0Client.value) {
    throw new Error('No Auth0 Client')
  }

  try {
    await auth0Client.value.handleRedirectCallback()

    const sessionAuthenticated = await auth0Client.value.checkIfAuthenticated()
    if (sessionAuthenticated) {
      return handleAuthenticatedSession()
    }
  } catch (err) {
    console.error('Error creating new session', err)
    error.value = err
    logout()
  }
}

export const checkSession = async () => {
  if (!auth0Client.value) {
    return
  }
  await auth0Client.value?.checkSession()
}

export const getAccessToken = async (
  options?: GetTokenSilentlyOptions,
): Promise<string | undefined> => {
  if (!auth0Client.value) {
    return
  }
  let token: string | undefined
  console.time('[TIMER] Auth0 doing token exchange...')
  try {
    console.time(`[TIMER] getAccessToken`)
    token = (await auth0Client.value?.getAccessToken(options)) as string
    console.timeEnd(`[TIMER] getAccessToken`)
  } catch (error) {
    console.warn('Trying to authenticate with popup for local development', error)
    console.time(`[TIMER] getTokenWithPopup`)
    token = (await auth0Client.value?.getTokenWithPopup(options)) as string
    console.timeEnd(`[TIMER] getTokenWithPopup`)
    if (!token) logout()
  }
  console.timeEnd('[TIMER] Auth0 doing token exchange...')

  if (!token) return

  await setUserRole() // 0ms await
  await api.setAuthenticated(true, token) // 0ms await
  isAuthenticated.value = true
  return token
}

export const getIdTokenClaims = async (): Promise<IdToken | undefined> => {
  if (!auth0Client.value) return undefined
  return auth0Client.value?.getIdTokenClaims()
}

export const signUp = (object: ISignupObject) => {
  if (!auth0Client.value) return
  return auth0Client.value?.signUpWithEmail(object).catch((err) => {
    console.error('error signing up', err)
    return err
  })
}

export const signUpWithSocial = (object: object) => {
  if (!auth0Client.value) return
  auth0Client.value?.signUpWithSocial(object)
}

export const parseHash = async () => {
  if (!auth0Client.value) return false
  return auth0Client.value?.parse()
}

export const setUserRole = async () => {
  const claims = await getIdTokenClaims()
  if (!claims) return
  userAuth0Id.value = claims.sub
  userRole.value = claims.user_role === '' ? 'standard' : claims.user_role
}

export const provideAuth0 = () => {
  const auth0 = {
    isAuthenticated,
    auth0Client,
    userRole,
    userAuth0Id,
    user,
    createClient,
    createAccount,
    login,
    logout,
    signUp,
    signUpWithSocial,
    handleRedirectCallback,
    handleCreateCallback,
    checkSession,
    getAccessToken,
    getIdTokenClaims,
    setUserRole,
  }
  provide(Auth0Symbol, auth0)
}

export const provideObject = () => {
  return {
    Auth0Symbol,
    auth0: {
      isAuthenticated,
      auth0Client,
      userRole,
      userAuth0Id,
      user,
      createClient,
      createAccount,
      login,
      logout,
      signUp,
      signUpWithSocial,
      handleRedirectCallback,
      handleCreateCallback,
      checkSession,
      getAccessToken,
      getIdTokenClaims,
      setUserRole,
    },
  }
}

export const useAuth0 = () => {
  const auth0 = inject(Auth0Symbol)
  if (!auth0) {
    throw new Error('No Auth0 provided')
  }
  return auth0 as IAuth0Plugin
}
