import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import CfsService from '@/services/CfsService.ts'
import Investments from '@/types/api/super/InvestmentOption.ts'
import { IntrafundAdviceRequestType, TermDepositResponse } from '@/types/CallcentrePortalTypes.ts'
import { InvestmentOption } from '@/components/SuperWidget/SuperWidgetAdviceType.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'

/**
 * TODO: This is Module 1 store (InvestmentOptionsInsideSuper - Intrafund Advice)
 * Super link up for User
 */
// expecting this to become snake_cased
export type SuperWidgetData = {
  condition: string
  options: InvestmentOption[]
  intrafundRecommendationId: number
}

export const useIntrafundAdviceStore = defineStore('intrafundAdviceStore', () => {
  const termDepositConsent = ref<TermDepositResponse>()
  const superWidgetGraphData = ref<SuperWidgetData>()
  const superWidgetFieldsLoading = ref<boolean>(false)
  const requestedAllocation = ref<Array<Investments>>([
    {
      chant_west_investment_name: '',
      chant_west_investment_id: 0,
      percent_allocation: 100,
      is_cash_option: false,
      is_term_deposit: false,
      has_cash_option: false,
      restricted_product: false,
    },
  ])
  const supersArray = computed(() => useSuperStore().getSupersArray)
  const activeSuperObject = computed(() => useSuperStore().getActiveSuperObject)
  const getTermDepositConsent = computed(() => termDepositConsent.value)
  const getRequestedAllocation = computed(() => requestedAllocation.value)
  const getSuperWidgetGraphData = computed(() => superWidgetGraphData.value)
  const getSuperWidgetFieldsLoading = computed(() => superWidgetFieldsLoading.value)
  const getHasCashAndTermDepositInvestments = computed(() => {
    const cashOrTdInvestments = supersArray.value.filter((superannuation) => {
      return superannuation.investments.find((allocation) => {
        return allocation.has_cash_option
      })
    })
    return cashOrTdInvestments.length > 0
  })

  const termDepositConsentStatus = computed(() => {
    const state = {
      waiting: false,
      notGiven: false,
      given: false,
    }

    if (!termDepositConsent.value) return state

    const { has_term_deposit, answered, consented, super_id } = termDepositConsent.value

    if (activeSuperObject.value.id === super_id) {
      state.waiting = has_term_deposit && !answered && !consented
      state.notGiven = has_term_deposit && answered && !consented
      state.given = has_term_deposit && answered && consented
    }

    return state
  })

  const setTermDepositConsent = async (consented: boolean) => {
    if (activeSuperObject.value !== undefined) {
      const payload = {
        superId: activeSuperObject.value?.id,
        consented: consented,
      }
      const response = await CfsService.setTermDepositConsentFlag(payload)
      termDepositConsent.value = response.data
    }
  }

  const updateTermDepositConsent = (value: TermDepositResponse) => {
    termDepositConsent.value = value
  }

  const fetchTermDepositConsent = async () => {
    if (activeSuperObject.value?.id) {
      const response = await CfsService.getTermDepositConsentFlag(activeSuperObject.value.id)
      termDepositConsent.value = response.data
    }
  }

  const setSuperWidgetFieldsLoading = (value: boolean) => {
    superWidgetFieldsLoading.value = value
  }

  const getModularisedSuperAdvice = (payload: { superId: string; auth0id: string }) => {
    // Whitelabel Id is set automatically for all endpoints
    return CfsService.getModularisedSuperAdvice({
      cfsClientSuperId: payload.superId,
      cfsClientAuthId: payload.auth0id,
    })
  }

  const setRequestedAllocation = (payload: Array<Investments>) => {
    requestedAllocation.value = payload
  }

  const setSuperWidgetGraphData = (value: SuperWidgetData) => {
    superWidgetGraphData.value = value
  }

  const updateIntrafundAdviceRequest = (payload: IntrafundAdviceRequestType) => {
    return CfsService.updateIntrafundAdviceRequest(payload, activeSuperObject.value.id)
  }

  return {
    getTermDepositConsent,
    getHasCashAndTermDepositInvestments,
    getRequestedAllocation,
    getSuperWidgetFieldsLoading,
    getSuperWidgetGraphData,
    fetchTermDepositConsent,
    getModularisedSuperAdvice,
    setTermDepositConsent,
    setRequestedAllocation,
    setSuperWidgetFieldsLoading,
    setSuperWidgetGraphData,
    termDepositConsent,
    termDepositConsentStatus,
    updateTermDepositConsent,
    updateIntrafundAdviceRequest,
  }
})
