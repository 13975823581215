import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  SimpleSpanProcessor,
} from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { Resource } from '@opentelemetry/resources'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import {
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_DEPLOYMENT_ENVIRONMENT,
  SEMRESATTRS_SERVICE_VERSION,
} from '@opentelemetry/semantic-conventions'

const traceExporter = new OTLPTraceExporter({
  url: 'http://localhost:4318/v1/traces',
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

const resource = new Resource({
  [SEMRESATTRS_SERVICE_NAME]: 'Otivo-FE',
  [SEMRESATTRS_SERVICE_VERSION]: '1.0.0',
  [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: import.meta.env.MODE,
})

console.log('resource', resource)
export const provider = new WebTracerProvider({
  resource,
  spanProcessors: [new BatchSpanProcessor(traceExporter)],
})

// Register automatic instrumentations
registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      '@opentelemetry/instrumentation-xml-http-request': {
        enabled: true,
        propagateTraceHeaderCorsUrls: [
          /http:\/\/localhost:.*/,
          /https:\/\/api\.otivo\.com:8080\/*/,
          /https:\/\/api-*\.otivo\.com:8080\/*/,
          /https:\/\/api\.otivo\.com.*/,
          /https:\/\/api-*\.otivo\.com\/*/,
          /https:\/\/api-*\.otivo\.dev\/*/,
        ],
      },
    }),
  ],
})

// Set the context manager to use zone.js
provider.register({
  contextManager: new ZoneContextManager(),
})

// Create a tracer for our application
export const tracer = provider.getTracer('frontend-tracer')

// Initialize OpenTelemetry - call this when your app starts
export function initializeOpenTelemetry() {
  console.log('OpenTelemetry initialized')
  return { provider, tracer }
}
