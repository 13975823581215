<template>
  <BaseView>
    <div class="flex flex-col bg-blue-6 h-full w-full items-center relative py-[60px]">
      <div class="max-w-[960px] text-center">
        <h3 class="lg:h1 w-full max-w-5xl px-6" v-html="content.heading" />
        <div class="intro-1 mt-[20px] px-6">{{ content.subHeading }}</div>
      </div>
      <section class="flex flex-col py-10 lg:py-20 w-full px-5">
        <div class="max-w-screen-lg w-full grid lg:grid-cols-2 gap-20 m-auto items-center p-20">
          <div class="grid rounded-circle border-1 border-grey-4 w-full items-center">
            <Vue3Lottie
              class="w-full h-auto row-start-1 col-start-1"
              :animation-data="StruggleMeter" />
            <div class="text-center text-blue-1 row-start-1 col-start-1">
              <div class="mt-[40%] px-20">
                <div class="menu-1 uppercase">Without advice</div>
                <div class="text-[27px] font-bold leading-8 mt-[10px] mb-5">
                  Longer debt means<br />
                  more interest.
                </div>
              </div>
            </div>
          </div>
          <div class="grid rounded-circle border-1 border-blue-4.5 w-full items-center shadow">
            <Vue3Lottie
              class="w-full h-auto row-start-1 col-start-1"
              :animation-data="OtivoMeter" />
            <div class="text-center text-otivo-red row-start-1 col-start-1">
              <div class="mt-[40%] px-20">
                <div class="menu-1 uppercase">With advice</div>
                <div class="text-[27px] font-bold leading-8 mt-[10px] mb-5">
                  Pay off your<br />debt 4X faster.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OtivoButton
        class="mt-[20px] md:mt-0"
        size="large"
        @click="createAccountWithModuleStream('debtPlan')">
        Help me clear my debt faster
      </OtivoButton>
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import BaseView from '@/views/BaseView.vue'
import createAccountWithModuleStream from '@/composables/createAccountWithModuleStream.ts'
import OtivoButton from '@/components/OtivoButton.vue'
import { Vue3Lottie } from 'vue3-lottie'
import OtivoMeter from '@/assets/animated/nuggets/OtivoMeter.json'
import StruggleMeter from '@/assets/animated/nuggets/MeterWithoutOtivoStruggle.json'

const content = {
  heading: "How can I pay off my loans <span class='text-otivo-red'>faster</span>?",
  subHeading:
    'Did you know the average credit cardholder carries a monthly balance of $3,043 and uses their card about 22.7 times a month?',
}
</script>

<style scoped></style>
