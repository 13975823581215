import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import type { Router as RouterType } from 'vue-router'
import getCommonRoutes from '@/router/common.ts'
import { ISessionStore, useSessionStore } from '@/store/pinia/SessionStore.ts'
import { IUserStore, useUserStore } from '@/store/pinia/UserStore.ts'
import { IAccountFlagsStore, useAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { initDone } from '@/main.ts'
import LoadingPage from '@/views/LoadingPage.vue'

class Router {
  private static instance: Router | null = null

  public static getInstance(routes?: Array<RouteRecordRaw>): RouterType {
    if (!Router.instance && routes) {
      const common = getCommonRoutes(
        useSessionStore() as ISessionStore,
        useUserStore() as IUserStore,
        useAccountFlagsStore() as IAccountFlagsStore,
      )

      Router.instance = createRouter({
        history: createWebHistory(),
        routes: [...common, ...routes],
        scrollBehavior(to, from, savedPosition) {
          if (savedPosition) {
            return {
              savedPosition,
              behavior: 'smooth',
            }
          }
          if (to.hash) {
            return {
              el: to.hash,
              behavior: 'smooth',
            }
          }
          return {
            top: 0,
          }
        },
      })
    }
    try {
      return Router.instance as RouterType
    } catch (e) {
      console.error(e)
      throw new Error('Router instance not created')
    }
  }
}

// Creates router instance as singleton
export default (routes?: Array<RouteRecordRaw>): RouterType => {
  return Router.getInstance(routes)
}
