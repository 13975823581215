<template>
  <div class="mt-10 border-[1px] border-blue-4 rounded-[20px] bg-white">
    <div class="p-5 md:p-10">
      <h5 class="bold text-blue-1">Discover how to pay down your debts faster</h5>
      <Status class="mt-[20px]" state="infoNeeded" status-style="outline" />
      <p class="intro-1 text-blue-1 mt-[40px]">
        Deciding which debt to tackle first can be tricky. That’s why we analyse all your info, then
        deliver the one piece of advice that’ll make the biggest difference for you right now.
      </p>
      <p class="intro-1 text-blue-1 mt-[40px]">
        We do this by looking at your debt, savings, and income (including your partner’s, if
        needed) to make sure high-interest debts, like credit cards and personal loans, are tackled
        first. Once those are under control, we’ll guide you toward other goals, like paying down
        your home loan.
      </p>
      <OtivoButton
        data-test="startOnboarding"
        @click="acknowledgePreamble"
        class="mt-10 w-full md:w-auto"
        size="large">
        Let’s see if this applies to you
      </OtivoButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import Status from '@/components/Status.vue'
import OtivoButton from '@/components/OtivoButton.vue'

interface Props {
  acknowledgePreamble: () => void
}

defineProps<Props>()
</script>

<style scoped></style>
