<template>
  <BaseView class="h-full">
    <div>
      <CFSHome v-if="subdomain.includes('cfs')" />
      <!-- local is for local development -->
      <Home v-else />
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import BaseView from '@/views/BaseView.vue'
import CFSHome from '@/views/Clients/CFS/CFSHome.vue'
import Home from '@/views/Otivo/Brand/Home.vue'
import { store } from '@/store'

const subdomain = computed(() => window.location.host.split('.')[0])
</script>

<style scoped>
:deep(.custom-input input) {
  @apply px-4 border-solid border rounded !important;
}

:deep(.custom-input .input-label) {
  @apply pb-2 !important;
}

:deep(.custom-input textarea) {
  @apply p-4 h-32 border-solid border rounded !important;
}
</style>
