<template>
  <div class="col-span-2 md:col-span-1">
    <span class="button-2 text-grey-1">Is this card paid off monthly?</span>
    <TabButtonRow
      class="w-full grid grid-cols-2 mt-[5px] !p-0"
      name="credit card paid monthly"
      data-test="debtPaidOffMonthly"
      :existing-value="liability.paid_monthly ? 'yes' : 'no'"
      :tab-button-data="[
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ]"
      @toggle="(selected) => updatePaidMonthly(selected)" />
  </div>
</template>

<script setup lang="ts">
import TabButtonRow from '@/components/Onboarding/TabButtonRow.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { computed } from 'vue'

const liability = computed(() => useLiabilityStore().liability)
const emit = defineEmits<(event: 'change', message: string) => void>()

const updatePaidMonthly = (selected) => {
  liability.value.paid_monthly = selected.value === 'yes'
  emit('change', 'Credit card paid monthly updated')
}
</script>

<style scoped></style>
