<template>
  <div 
  class="flex flex-col p-10 md:bg-white">
    <h5 class="font-bold">What type is your debt?</h5>
    <TabButtonRow
      :tab-button-data="debtTypes"
      class="grid grid-cols-1 md:grid-cols-3"
      @toggle="selectDebtType" />
    <p class="paragraph-1 mt-[40px] text-blue-1">
      To help us provide recommendations to you, we need to know all the details about your debts.
    </p>
  </div>
</template>

<script setup lang="ts">
import TabButtonRow from '@/components/Onboarding/TabButtonRow.vue'
import { useRouter, useRoute } from 'vue-router'
import { useModalStore } from '@/store/pinia/ModalStore.ts'

const route = useRoute()
const router = useRouter()
const modalStore = useModalStore()

type Props = {
  heading?: string
  body?: string
  confirmFunction: () => void | unknown
}

withDefaults(defineProps<Props>(), {
  heading: '', // TODO Replace with module contents
  body: '',
})

const selectDebtType = (debtType) => {
  modalStore.closeModal()
  router.push({
    name: route.meta.isAdminPortal ? 'AdminDebtDetails' : 'DebtDetails',
    params: { type: debtType.value, id: 'new' },
  })
}

const debtTypes = [
  { value: 'credit-card', label: 'Credit card' },
  { value: 'car-loan', label: 'Car loan' },
  { value: 'personal-loan', label: 'Personal loan' },
  { value: 'mortgage', label: 'Mortgage' },
  { value: 'investment', label: 'Investment loan' },
]
</script>

<style scoped></style>
