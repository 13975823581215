<template>
  <div
    @mouseenter="setHover(true)"
    @mouseleave="setHover(false)"
    @click="tile.action"
    class="tile flex flex-col gap-3 rounded h-full bg-white group group-hover:otivo-focus-shadow group-hover:cursor-pointer"
    :class="[tile.disabled ? 'border border-grey-field ' : 'border border-blue-3 otivo-shadow']"
    :data-test="tile.type">
    <div
      class="flex flex-col h-full rounded group-hover:outline outline-[2px] group-hover:outline-otivo-blue">
      <div class="relative shrink-0">
        <p
          v-if="tile.disabled"
          class="leading-tight px-1.5 absolute z-10 right-5 top-5 bg-white text-otivo-blue menu-2 border-[1.5px] border-otivo-blue rounded-[3px]">
          Coming soon
        </p>
        <img
          loading="lazy"
          :src="tile.image"
          alt="Default state image"
          :class="{ 'grayscale opacity-[45%]': tile.disabled }"
          class="transition-opacity duration-300 ease-in-out transform scale-100 rounded-t" />
        <!-- h-full is to stop size jumping -->
        <video
          ref="video"
          v-show="tile.animatedImage"
          muted
          playsinline
          autoplay
          loop
          class="absolute inset-0 rounded-t h-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
          <!-- h-full is to stop size jumping -->
          <source :src="tile.animatedImage" type="video/webm" />
          <img loading="lazy" :src="tile.image" alt="Animated state image" />
        </video>
      </div>
      <div
        class="flex-grow p-4 flex flex-col gap-4"
        :class="{ 'grayscale opacity-[45%]': tile.disabled }">
        <span class="flex items-center gap-1">
          <component
            :is="tile.icon"
            class="w-3.5 h-3.5 text-otivo-blue group-hover:text-otivo-red"
            :fill2="[hover ? 'otivo-red' : 'otivo-blue']" />
          <p class="text-otivo-blue menu-1 uppercase">{{ tile.type }}</p>
        </span>
        <h6 class="text-blue-1">{{ tile.title }}</h6>
        <p class="paragraph-2" v-html="tile.description"></p>
      </div>
      <div
        class="px-4 my-4 flex items-end h-full"
        :class="[
          tile.status ? 'justify-between' : 'justify-end',
          { 'grayscale opacity-[45%]': tile.disabled },
        ]">
        <span class="flex items-center gap-1">
          <ClipLoader
            size="15px"
            v-if="loading"
            class="text-otivo-blue"
            :color="hover ? '#0037a1' : '#0064ff'" />
          <Status v-else :state="tile.status ?? ''" status-style="outline" />
        </span>
        <ArrowWide
          class="w-3 h-3 rotate-90"
          :fill="hover ? '#0037a1' : '#0064ff'"
          :stroke-width="hover ? 3 : 1" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { ref } from 'vue'
import type { Component } from 'vue'
import ArrowWide from '../icons/ArrowWide.vue'
import Status from '../Status.vue'

export interface TileType {
  image: string
  animatedImage: string
  // eslint-disable-next-line no-undef
  icon: Component
  type: string
  title: string
  description: string
  status?: string
  action?: () => void
  disabled?: boolean
}

interface Props {
  tile: TileType
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
})

const hover = ref(false)

const setHover = (value: boolean) => {
  if (props.tile.disabled) {
    return false
  }
  hover.value = value
}
</script>
