<template>
  <div class="col-span-2 md:col-span-1">
    <span class="button-2">Interest type </span>
    <div class="relative">
      <Dropdown
        class="w-full mt-[5px]"
        name="debt-variable_rate"
        place-holder="name"
        type="responsible"
        data-test="debtInterestType"
        :existing-item="mapInterestType(liability.variable_rate)"
        :items="interestTypeOptions"
        @selected="(selected) => updateInterestType(selected)" />
      <p v-if="liabilityErrors?.variable_rate" class="otivo-error-message absolute right-0">
        {{ liabilityErrors?.variable_rate?.[0] }}
      </p>
    </div>
  </div>
  <div class="col-span-2 md:col-span-1">
    <span class="button-2">Offset balance</span>
    <BaseInput
      v-model:value="liability.offset_balance"
      type="currency"
      class="w-full mt-[5px]"
      name="debt-offset_balance"
      data-test="debtOffsetBalance"
      placeholder="0" />
  </div>
  <div class="col-span-2 md:col-span-1">
    <span class="button-2 text-grey-1">Is this an investment property?</span>
    <TabButtonRow
      class="w-full mt-[5px] !p-0 grid grid-cols-2"
      name="debt-is_investment_property"
      data-test="debtIsInvestmentProperty"
      :existing-value="liability.is_investment_property ? 'yes' : 'no'"
      :tab-button-data="[
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ]"
      @toggle="(selected) => updateIsInvestmentProperty(selected)" />
  </div>
</template>

<script setup lang="ts">
import BaseInput from '@/components/Inputs/BaseInput.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { computed } from 'vue'
import { useDebtFormLogic } from '@/composables/useDebtFormLogic.ts'
import TabButtonRow from '@/components/Onboarding/TabButtonRow.vue'

const liabilityStore = useLiabilityStore()
const liability = computed(() => liabilityStore.liability)
const liabilityErrors = computed(() => liabilityStore.liabilityErrors)

const { mapInterestType, interestTypeOptions } = useDebtFormLogic(liabilityStore)

const emit = defineEmits<(event: 'change', message: string) => void>()

const updateInterestType = (selected) => {
  liability.value.variable_rate = selected.value
  emit('change', 'Debt interest type updated')
}

const updateIsInvestmentProperty = (selected) => {
  liability.value.is_investment_property = selected.value === 'yes'
  emit('change', 'Is investment property updated')
}
</script>

<style scoped></style>
