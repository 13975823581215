<template>
  <div class="flex flex-col">
    <div class="text-center mb-10">
      <h3 class="h3 my-10">That's right, you could be better off by <span class="inline-grid items-start">
        <span>$371K</span>
        <svg width="98" height="6" viewBox="0 0 98 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 4C15.6238 4 27.4334 2 41.2024 2C50.8838 2 60.5652 2 70.2466 2C74.5388 2 78.831 2 83.1233 2C88.4939 2 90.6294 4 96 4"
            stroke="#FF0000" stroke-width="4" stroke-linecap="round" />
        </svg>
      </span>!
      </h3>
      <p class="intro-1">When people who use Otivo follow our advice all the way, they’re better off on average by these
        amounts…</p>
    </div>
    <div class="max-w-[540px] mx-auto" v-for="(item, index) in items" :key="index">
      <div class="flex flex-col items-center w-full text-center space-y-5 mb-5">
        <h3 class="h3 text-blue-1">{{ item.saving }}</h3>
        <h6 class="font-bold h6">{{ item.label }}</h6>
        <div class="mp1 paragraph-1" v-html="item.body"></div>
      </div>
      <AdditionIcon v-if="index !== items.length - 1" class="mx-auto mb-5" fill="#F00" />
      <EqualsIcon v-else class="m-auto" fill1="otivo-red" />
    </div>
    <div class="grid text-center items-baseline w-full py-10">
      <svg class="row-start-1 col-start-1 f-center mx-auto" width="215" height="62" viewBox="0 0 215 62" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M109.109 12.5319C108.928 8.74058 108.815 6.24983 110.419 2.8418" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
        <path d="M81.4575 13.5176C80.8195 9.58989 79.1821 5.94678 79.1387 2" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
        <path d="M138.069 16.6153C139.675 12.9429 140.27 9.02503 142.396 5.6272" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
        <path d="M49.6724 21.4238C48.5959 15.3205 44.0937 12.9264 39.7577 9.61759" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
        <path d="M24.9236 36.8275C20.0033 35.7931 14.9939 34.9444 10.1586 33.6006" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
        <path d="M15.0521 58.9543C10.9868 58.6956 6.87897 58.4349 2.83177 58.6192" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
        <path d="M165.319 23.9467C166.421 17.8435 171.028 15.4494 175.465 12.1405" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
        <path d="M191.472 40.6265C195.967 38.1795 200.605 35.8835 204.924 33.1662" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
        <path d="M201.12 59.652C205.004 58.9254 208.929 58.1917 212.832 57.8662" stroke="#0064FF" stroke-width="4"
              stroke-linecap="round" />
      </svg>
      <h3 class="row-start-1 col-start-1 text-blue-1">$371,031</h3>
    </div>
    <OtivoButton class="mt-10 mx-auto" @click="auth0.createAccount">Get started now</OtivoButton>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import EqualsIcon from '@/components/icons/EqualsIcon.vue'
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin.ts'

defineEmits(['close'])

const auth0 = useAuth0()
const items = ref([
  // {
  //   label: 'lowering your interest',
  //   saving: '$114,763',
  //   body: 'By helping them find a lower interest rate, our customers are on track to save an average of $113,897 by the time their mortgage is paid off and $866 until their credit card is paid off.',
  // },
  {
    label: 'With faster repayments',
    saving: '$52,030',
    body: 'By showing people how much they can repay each month, customers on average are <strong>$1,200</strong> better off with their credit cards and <strong>$50,830</strong> better off with their home mortgage throughout the life of their loans.'
  },
  {
    label: 'With smarter investments',
    saving: '$138,645',
    body: 'By recommending smarter investment options within their super fund, our customer’s super is boosted on average by <strong>$138,645</strong> by retirement.'
  },
  {
    label: 'With super charged super',
    saving: '$180,356',
    body: 'By showing people how much to contribute, their super is increased on average by <strong>$180,356</strong> (in today\'s dollars) by retirement.'
  }
])
</script>

<style scoped>
</style>
