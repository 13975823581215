import getApiInstance from '@/services/Api.ts'
import { Investment } from '@/types/Investment.ts'

const api = getApiInstance()

export default {
  getAllInvestments(params: Record<string, unknown>) {
    return api.Get<Array<Investment>>('/investment', { params })
  },
  updateInvestment(investment: Partial<Investment>) {
    return api.Put(`/investment/${investment.id}`, investment)
  },
}
