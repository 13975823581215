<template>
  <h6 class="text-otivo-blue flex items-center cursor-pointer select-none" @click="toggleDrawer">
    <slot name="header"></slot>
  </h6>
  <section>
    <div
      class="overflow-hidden max-h-0 opacity-0 transition-all duration-300"
      :class="{ 'max-h-[1000px] opacity-100': open }">
      <slot name="drawer"></slot>
    </div>
  </section>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    open: boolean
  }>(),
  {
    open: false,
  },
)
const emit = defineEmits(['toggle'])
const toggleDrawer = () => {
  emit('toggle')
}
</script>
