import { computed } from 'vue'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { ISuperStore, useSuperStore } from '@/store/pinia/SuperStore.ts'
import { IUserStore } from '@/store/pinia/UserStore.ts'
import calculateAge from '@/composables/users/calculateAge.ts'
import { getSuperFundNames } from '@/composables/getSuperFundNames.ts'
import { exclusionsHandlers } from '@/components/CFS/IntrafundInvestment/Utils/exclusionsHandlers.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import CfsService from '@/services/CfsService.ts'

export const superInvestmentRecommendationHandlers = (
  userStore: IUserStore,
  superStore: ISuperStore,
  isUser: () => boolean,
) => {
  const intrafundAdviceStore = useIntrafundAdviceStore()

  const superWidgetGraphData = computed(() => intrafundAdviceStore.getSuperWidgetGraphData)
  const activeSuperObject = computed(() => useSuperStore().getActiveSuperObject)
  const user = computed(() => userStore.getUser)
  const userAge = computed(() => calculateAge(user.value.dob))
  const { applyExceptionRules } = exclusionsHandlers()

  const fetchSuperInvestmentAdvice = async () => {
    //  I think these should be checked beforehand, these errors should never go off or prevent the graph/recommendation from running
    if (!user.value?.has_consented) throw new Error('User has not consented')
    if (!activeSuperObject.value?.id) throw new Error('No active super object')

    await applyExceptionRules()

    if (!state.isEligibleForRecommendations) return

    state.loading = true
    state.showRecommendations = false
    // state.graphLoaded = false
    try {
      const res = await intrafundAdviceStore.getModularisedSuperAdvice({
        superId: activeSuperObject.value.id,
        auth0id: user.value.auth0id,
      })
      // If they are able to receive advice, update the advice stage to advice ready
      if (
        activeSuperObject.value.advice_request?.advice_stage &&
        activeSuperObject.value.advice_request?.advice_stage <= 2
      ) {
        // Must only trigger once
        const optimisationText = res.data.data.options[1]
          ? JSON.stringify(res.data.data.options[1].copy.intro_1)
          : ''
        await intrafundAdviceStore.updateIntrafundAdviceRequest({
          advice_stage: 3,
          optimisationText: optimisationText,
        })
        await useModuleStatusesStore().fetchModuleStatuses()
      }

      intrafundAdviceStore.setSuperWidgetGraphData(res.data?.data)
      if (res.data?.data?.options[0]) {
        state.currentRiskProfile = res.data.data.options[0].risk_profile?.toLowerCase() || ''
      }
      if (res.data?.data?.options[1]) {
        state.riskRecommendation = res.data.data.options[1].risk_profile?.toLowerCase() || ''
      }
      if (isUser()) {
        await CfsService.setConsentFlag({
          advice_received: true,
          account_id: user.value.account_id,
        })
      }
      state.graphLoaded = true
      state.showRecommendations = true
    } catch (e) {
      state.showRecommendations = false
      console.error(e)
    } finally {
      state.graphLoaded = true
      state.loading = false
    }
  }

  const hasMultipleAlternatives = computed(
    () =>
      (superWidgetGraphData.value?.options.filter((val) => val.tag === 'alternative') || [])
        .length > 1,
  )

  const resetAdviceState = async () => {
    await getSuperFundNames()
    if (isUser()) {
      // Is CFS Client
      if (activeSuperObject.value && activeSuperObject.value.id !== '') {
        // Super is already set, proceed
        await fetchSuperInvestmentAdvice()
      } else {
        // Super isn't set yet, set it then proceed
        superStore.setActiveSuperObject(superStore.getSupersArray[0])
        await fetchSuperInvestmentAdvice()
      }
    }
  }

  return {
    userAge,
    resetAdviceState,
    fetchSuperInvestmentAdvice,
    hasMultipleAlternatives,
  }
}
