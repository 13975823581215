<template>
  <div id="portal-engagement">
    <div class="flex flex-col py-[40px] gap-[40px]">
      <div class="flex gap-[20px] box-border bg-blue-5 w-fit p-[8px] rounded-[10px]">
        <BaseTabButton
          class="!py-[10px] !box-border"
          :class="
            option.key === activeTab.key
              ? 'bg-white text-grey-1'
              : '!bg-blue-5 text-grey-2 !border-blue-5 !hover:box-shadow-none !focus:box-shadow-none !focus:outline-none'
          "
          v-for="(option, index) in moduleOptions"
          :key="index"
          :is-active="option.key === activeTab.key"
          :option="option"
          :disabled="option.disabled"
          @change="setActiveTab" />
      </div>
      <div class="flex bg-white flex-col p-[40px] rounded-[20px] otivo-shadow">
        <div class="flex justify-between w-100%">
          <h5 class="flex text-blue-1 font-bold">Module Engagement</h5>
        </div>
        <div class="w-100% flex flex-col md:flex-row gap-[40px]">
          <div class="w-100% md:w-60% flex flex-col mt-[40px]">
            <canvas id="engagement-chart"></canvas>
            <div class="gap-[5px] my-[40px] paragraph-3 flex self-center">
              Date: <span class="button-3">{{ range.start }}</span> to
              <span class="button-3">{{ range.end }}</span>
            </div>
            <div id="legend" class="self-center" />
          </div>
          <div
            class="w-100% md:w-40% -mt-[20px] flex flex-col rounded-[20px] border-[1px] border-blue-3 otivo-shadow p-[40px] pt-[20px]">
            <h5 class="flex text-blue-1 font-bold">Member Engagement</h5>
            <div class="flex self-center my-[40px]">
              <DoughnutChart
                canvas-id="engagement-doughnut"
                :active-members="activeMembers"
                :advice-issued="adviceIssued"
                :active-tab="activeTab" />
            </div>
            <div class="flex flex-row self-center">
              <div id="doughnut-legend"></div>
              <div class="flex flex-col ml-4 gap-[16px] align-middle justify-center">
                <div class="button-1" :style="`color: ${activeTab.colour}`">
                  {{ adviceIssued }}
                </div>
                <div class="button-1 text-grey-3">{{ activeMembers }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseTabButton from '@/components/Inputs/BaseTabButton.vue'
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  shallowRef,
  watch,
} from 'vue'
import { useCallcentreReportingStore } from '@/store/pinia/CallcentrePortalReportingStore.ts'
import { drawGraph } from '@/composables/charts/base/barChart.ts'
import { Chart } from 'chart.js/auto'
import { cloneObject } from '@/utilities.ts'
import configs from '@/composables/charts/portalReporting/barChartConfigs.ts'
import { moduleType, PeriodMetrics } from '@/services/CallcentreReportingService.ts'
import { format, parse } from 'date-fns'
import DoughnutChart from '@/components/ChartJS/DoughnutChart.vue'
import { ExclusionRules, useWhitelabelExclusionRules } from '@/composables/useWhitelabelExclusionRules.ts'

const { hasExclusionRule } = useWhitelabelExclusionRules()
const portalReportingStore = useCallcentreReportingStore()

export type ModuleOption = {
  label: string
  key: moduleType
  disabled: boolean
  colour: string
}
const moduleOptions: Array<ModuleOption> = [
  {
    label: 'Any module',
    key: 'all',
    disabled: false,
    colour: '#FF0000',
  },
  {
    label: 'Investment options',
    key: 'super-investments',
    disabled: false,
    colour: '#62A3FF',
  },
  {
    label: 'Salary sacrifice',
    key: 'salary-sacrifice',
    disabled: false,
    colour: '#BB01CF',
  },
  {
    label: 'Deductible contributions',
    key: 'deductible-contributions',
    disabled: false,
    colour: '#FD8900',
  },
  {
    label: 'Insurance',
    key: 'insurance',
    disabled: false,
    colour: '#00966E',
  },
  {
    label: 'Debt',
    key: 'debt',
    disabled: hasExclusionRule(ExclusionRules.DEBT_MODULE),
    colour: '#0037A1',
  }
]

const barChart = shallowRef()
// const activeTab = ref<ModuleOption>({} as ModuleOption)
const activeTab = computed(() => portalReportingStore.activeTab)
const activeChartConfig = computed(() => cloneObject(configs[activeTab.value.key]))
const engagementData = computed(() => portalReportingStore.getEngagementMetrics)
const chartFrequency = computed(() => portalReportingStore.getChartFrequency)
const activeMembers = ref(0)
const adviceIssued = ref(0)

const setActiveTab = (obj: ModuleOption) => {
  portalReportingStore.setActiveTab(obj)
  const apiParams: PeriodMetrics = {
    ...portalReportingStore.getPostParams(),
    frequency: chartFrequency.value,
  }
  portalReportingStore.updateModuleEngagementData(obj.key as string, apiParams as PeriodMetrics)
}

watch(engagementData.value, (newVal) => {
  const memberEngagementMetrics = newVal.memberEngagement[activeTab.value.key]
  activeMembers.value = memberEngagementMetrics.activeMembers
  adviceIssued.value = memberEngagementMetrics.adviceIssued
  updateBarChart()
})

const range = computed(() => portalReportingStore.searchRange)

const createCharts = async () => {
  const bar = document.getElementById('engagement-chart') as HTMLCanvasElement
  let chartConfig = activeChartConfig.value
  // Creating charts will always start at 'ALL'
  if (engagementData.value.allModuleEngagement) chartConfig = mapAllModuleDatasets()
  barChart.value = await drawGraph(chartConfig, bar)
}

const mapAllModuleDatasets = () => {
  const newData = cloneObject(activeChartConfig.value)
  newData.datasets.forEach((dataset, index) => {
    switch (index) {
      case 0: {
        // todo: fix this when api updates
        dataset.data = engagementData.value.allModuleEngagement['super-investments'] ?? [0, 0]
        break
      }
      case 1: {
        dataset.data = engagementData.value.allModuleEngagement['salary-sacrifice']
        break
      }
      case 2: {
        dataset.data = engagementData.value.allModuleEngagement['deductible-contributions']
        break
      }
      case 3: {
        dataset.data = engagementData.value.allModuleEngagement['insurance']
        break
      }
      case 4: {
        dataset.data = engagementData.value.allModuleEngagement['debt']
        break
      }
      default: {
        console.error('no data')
      }
    }
  })

  return newData
}

const updateChartDatasets = () => {
  const newData = cloneObject(activeChartConfig.value)
  newData.labels = createLabels(engagementData.value.moduleEngagement.labels)
  newData.datasets.forEach((dataset, index) => {
    dataset.data =
      index === 0
        ? engagementData.value.moduleEngagement.datasets.issued
        : engagementData.value.moduleEngagement.datasets.actioned
  })

  return newData

  function createLabels(labelObject) {
    let labels = Object.values(labelObject)
    labels = labels.map((label) => {
      const date = parse(label as string, 'yyyy-MM-dd', new Date())
      return format(date, 'dd MMM yyyy')
    })
    return labels
  }
}

const updateBarChart = () => {
  const newData = cloneObject(activeChartConfig.value)
  const bar = Object.values(Chart.instances)
    .filter((c) => c.canvas.id == 'engagement-chart')
    .pop() as Chart

  //  'all' module labels are fixed
  if (activeTab.value.key === 'all') {
    newData.datasets.forEach((dataset, index) => {
      switch (index) {
        case 0: {
          dataset.data = engagementData.value.allModuleEngagement['super-investments']
          break
        }
        case 1: {
          dataset.data = engagementData.value.allModuleEngagement['salary-sacrifice']
          break
        }
        case 2: {
          dataset.data = engagementData.value.allModuleEngagement['deductible-contributions']
          break
        }
        case 3: {
          dataset.data = engagementData.value.allModuleEngagement['insurance']
          break
        }
        case 4: {
          dataset.data = engagementData.value.allModuleEngagement['debt']
          break
        }
        default: {
          console.error('no data')
        }
      }
    })
  } else {
    let labels = Object.values(engagementData.value.moduleEngagement.labels)
    labels = labels.map((label) => {
      const date = parse(label as string, 'yyyy-MM-dd', new Date())
      return format(date, 'dd MMM yyyy')
    })
    newData.labels = labels

    newData.datasets.forEach((dataset, index) => {
      dataset.data =
        index === 0
          ? engagementData.value.moduleEngagement.datasets.issued
          : engagementData.value.moduleEngagement.datasets.actioned
    })
  }

  bar.data = newData
  nextTick(() => {
    bar.update()
  })
}

onBeforeMount(() => setActiveTab(moduleOptions[0]))
onMounted(() => createCharts())
onUnmounted(() => barChart.value?.destroy())
</script>

<style scoped></style>
