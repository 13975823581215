<template>
  <div
    class="border border-grey-4 p-4 shadow-md m-auto py-4 md:p-8 bg-white rounded rounded-b-none border-y-0">
    <div class="flex flex-grow justify-between py-2">
      <h5 class="text-blue-1 bold">Optimising your investment options</h5>
    </div>
    <div class="transition slide-in pt-[10px]">
      <div class="flex items-center mt-[20px] gap-[20px]">
        <div v-if="adviceLocked" class="flex items-center gap-[4px]">
          <RefreshIcon class="w-[14px] h-[14px] flex-none" fill1="#0064FF" />
          <p class="menu-2 text-otivo-blue">Processing</p>
        </div>
        <div v-else class="flex items-center">
          <AlertLightning class="w-[14px] h-[14px] flex-none" fill1="white" fill2="otivo-red" />
          <p class="menu-2 text-otivo-red">Advice Ready</p>
        </div>
        <div v-if="adviceLocked">
          <div class="flex items-end gap-[4px] text-blue-3 uppercase paragraph-3">
            <CheckIcon class="h-[12px] w-[12px]" fill1="#70D6FF" />
            ACTIONED {{ activeSuper.advice_request?.last_actioned }}
          </div>
        </div>
      </div>
      <!-- todo: whitelabel refactor -->
      <div
        v-if="activeSuper.advice_request?.last_actioned && adviceLocked"
        class="button-1 mt-[20px] text-red-dark">
        CFS is currently putting your advice into action, so for now, you won't be able to make any
        changes.
      </div>
      <div class="flex gap-[10px] mt-[40px]">
        <AlertLightning class="w-[24px] h-[24px] mt-[6px] flex-none" />
        <div
          v-if="intraRecommendationCopy"
          class="intro-2 text-grey-1"
          v-html="intraRecommendationCopy" />
      </div>
      <RiskComparison :investment-options-array="superWidgetGraphData?.options || []" />
      <!--  Recommendations Copy -->
      <div class="mt-[40px]" v-if="superWidgetGraphData?.options[1]?.tag === 'recommended'">
        <h6 class="text-blue-1 mb-[20px]">Why we've made this recommendation</h6>
        <p class="intro-1">
          Superannuation is a long-term investment, so the two biggest things we look at are when
          you can access your super and how long you're expected to enjoy your retirement.
        </p>
        <br />
        <p class="paragraph-1">
          {{ intraRecExplainBody }}
        </p>
        <InvestmentTypeExplainer
          :risk-recommendation="state.riskRecommendation"
          :current-risk-profile="state.currentRiskProfile"
          :user-age="userAge"
          :has-multiple-alternatives="hasMultipleAlternatives" />
      </div>
      <div class="mt-[40px]" v-if="superWidgetGraphData?.options[1]?.tag === 'mysuper'">
        <h6 class="text-blue-1 mb-[20px]">Why we've made this recommendation</h6>
        <p class="intro-1 mb-[20px]">
          We have carefully analysed your age and overall financial profile to determine the most
          suitable investment option for you.
        </p>
        <p class="intro-1 mb-[20px]">
          The <span class="text-otivo-red">{{ superWidgetGraphData?.options[1].name }}</span> has
          been selected because it aligns well with your current stage of life, aiming to maximise
          your financial growth while managing risk effectively.
        </p>
        <p class="intro-1">
          However, it is important to note that this recommendation is the default option due to the
          limited investment choices currently available. There are no other options that adequately
          match your specific risk category. Our goal is to provide you with an investment strategy
          that offers a balance of growth and security, tailored to your unique circumstances.
        </p>
        <br />
        <p class="paragraph-1">
          {{ intraRecExplainBody }}
        </p>
        <InvestmentTypeExplainer
          :risk-recommendation="state.riskRecommendation"
          :current-risk-profile="state.currentRiskProfile"
          :user-age="userAge"
          :has-multiple-alternatives="hasMultipleAlternatives" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import AlertLightning from '@/components/icons/AlertLightning.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import InvestmentTypeExplainer from '@/components/SuperWidget/InvestmentTypeExplainer.vue'
import RefreshIcon from '@/components/icons/RefreshIcon.vue'
import RiskComparison from '@/components/SuperWidget/RiskComparison.vue'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { superInvestmentRecommendationHandlers } from '@/components/CFS/IntrafundInvestment/Utils/superInvestmentRecommendationHandlers.ts'
import { useIntraRecommendationCopy } from '@/components/CFS/IntrafundInvestment/Utils/useIntraRecommendationCopy.ts'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'

const superStore = useSuperStore()
const intrafundAdviceStore = useIntrafundAdviceStore()
const activeSuper = computed(() => superStore.getActiveSuperObject)
const superWidgetGraphData = computed(() => intrafundAdviceStore.getSuperWidgetGraphData)
const moduleStatusesStore = useModuleStatusesStore()

const adviceLocked = computed(() =>
  ['processing', 'actioned'].includes(moduleStatusesStore.getStatuses.superInvestments),
)

const { isUser } = useCheckUser()
const { hasMultipleAlternatives, userAge } = superInvestmentRecommendationHandlers(
  useUserStore(),
  superStore,
  isUser,
)
const { intraRecommendationCopy, intraRecExplainBody } = useIntraRecommendationCopy()
</script>
