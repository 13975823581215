export type Frequency = 'weekly' | 'monthly' | 'fortnightly' | 'quarterly' | 'annually'
export type LoanParams = {
  balance: number
  interestRate: number // ALWAYS as percentage (e.g., 5.5 for 5.5%)
  frequency: Frequency
  term?: number // term in years, optional for credit cards
  offsetBalance?: number // offset balance for interest-only loans
}

// constants
const FREQUENCIES: Record<Frequency, number> = {
  weekly: 52,
  fortnightly: 26,
  monthly: 12,
  quarterly: 4,
  annually: 1,
} as const
const MIN_CREDIT_CARD_PAYMENT = 20
const CREDIT_CARD_RATE = 3.0 // 3% as percentage

// Validation
const validateLoanParams = (params: LoanParams): void => {
  const { balance, interestRate, frequency } = params

  if (Number.isNaN(balance) || !Number.isFinite(balance) || balance <= 0) {
    throw new Error(`Balance must be a valid number, received: ${balance}`)
  }

  if (Number.isNaN(interestRate) || !Number.isFinite(interestRate)) {
    throw new Error(`Interest rate must be a valid number, received: ${interestRate}`)
  }

  // Check if interest rate is likely provided as decimal instead of percentage
  if (interestRate < 1) {
    throw new Error(
      `Interest rate must be provided as a percentage (e.g., 5.5 for 5.5%), received: ${interestRate}`,
    )
  }

  if (!FREQUENCIES[frequency]) {
    throw new Error(
      `Invalid frequency: ${frequency}. Must be one of: ${Object.keys(FREQUENCIES).join(', ')}`,
    )
  }

  if (params.term !== undefined) {
    if (Number.isNaN(params.term) || !Number.isFinite(params.term)) {
      throw new Error(`Loan term must be a valid number, received: ${params.term}`)
    }
  }
}

const getPaymentFrequency = (frequency: Frequency): number => {
  if (!FREQUENCIES[frequency]) {
    throw new Error(`Invalid frequency: ${frequency}`)
  }
  return FREQUENCIES[frequency]
}

/**
 * @param frequency The frequency the loan is paid
 * @param years Term of the loan in months
 */
const calculateTotalPeriods = (frequency: Frequency, months: number) => {
  if (months <= 0) return 0
  const periods = (months / 12) * getPaymentFrequency(frequency)
  if (periods <= 0) {
    throw new Error(`Invalid total periods calculated: ${periods}`)
  }
  return periods
}

const calculatePeriodicRate = (annualRate: number, frequency: Frequency): number => {
  const periodicRate = annualRate / 100 / getPaymentFrequency(frequency)

  if (periodicRate <= 0) {
    throw new Error(`Invalid periodic rate calculated: ${periodicRate}`)
  }

  return periodicRate
}

/**
 * Calculates loan repayment using the standard amortization formula
 */
const calculateAmortizedPayment = (params: LoanParams): number => {
  const { balance, interestRate, frequency, term } = params
  const ir = calculatePeriodicRate(interestRate, frequency)
  const totalPeriods = calculateTotalPeriods(frequency, term)
  const powerFactor = Math.pow(1 + ir, totalPeriods)

  if (!Number.isFinite(powerFactor)) {
    throw new Error('Power factor calculation resulted in non-finite number')
  }

  const numerator = ir * powerFactor
  const denominator = Math.abs(powerFactor - 1)

  if (denominator === 0) {
    throw new Error('Division by zero in payment calculation')
  }

  return Math.ceil(balance * (numerator / denominator))

  // if (!Number.isFinite(payment)) {
  //   throw new Error('Payment calculation resulted in non-finite number')
  // }

  // return Number(payment)
}

// Exported calculation functions
export const calculateMinCreditCardRepayment = (params: Omit<LoanParams, 'periods'>): number => {
  if (!params.frequency) params.frequency = 'monthly'
  if (params.balance === 0) return 0
  validateLoanParams(params)
  const { balance } = params

  const payment = Math.ceil(balance * (CREDIT_CARD_RATE / 100))
  return payment < MIN_CREDIT_CARD_PAYMENT ? MIN_CREDIT_CARD_PAYMENT : payment
}

export const calculateMinLoanRepayment = (params: LoanParams): number => {
  if (params.balance === 0 || (params.term !== undefined && params?.term === 0)) return 0
  validateLoanParams(params)
  return calculateAmortizedPayment(params)
}

export const calculateMinInterestOnlyRepayment = (params: Omit<LoanParams, 'periods'>): number => {
  if (params.balance === 0) return 0
  validateLoanParams(params)
  const { balance, interestRate, frequency, offsetBalance = 0 } = params

  const periodicRate = calculatePeriodicRate(interestRate, frequency)
  return Math.ceil((balance - offsetBalance) * periodicRate)

  // if (!Number.isFinite(payment)) {
  //   throw new Error(`Interest-only payment calculation resulted in non-finite number ${payment}`)
  // }

  // return Number(payment)
}
