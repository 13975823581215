<template>
  <div
    id="logo"
    class="flex flex-row h-full items-center md:gap-10"
    :class="[{ 'cursor-pointer': !guidancePortal }]"
    @click.stop="pushToHome">
    <div class="flex items-center h-full object-contain">
      <img
        src="@/assets/img/otivo-logo-white-text.png"
        class="max-w-[120px] h-auto"
        alt="otivo-logo" />
    </div>
    <div
      v-if="isCallCentre"
      class="h-full hidden md:flex menu-1 text-blue-2 items-center pt-1 whitespace-nowrap">
      <div>GUIDANCE TEAM</div>
    </div>
    <div
      v-else-if="showWhitelabelLogo"
      class="h-full w-auto border-l border-otivo-blue hidden lg:flex md:pl-10 items-center shrink-0">
      <img
        class="w-auto h-full max-h-16 place-self-center"
        :src="whitelabel.logo"
        :alt="`${whitelabel.name}-logo`"
        @click="pushToHome()" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useRouter } from 'vue-router'

const router = useRouter()
const sessionStore = useSessionStore()
const whitelabel = computed(() => sessionStore.getWhitelabelData)
const guidancePortal = computed(() => whitelabel.value.callcentre_portal)
const showWhitelabelLogo = computed(() => whitelabel.value.render_whitelabel_logo)
const isCallCentre = computed(() => userRole.value === 'callcentre')

const pushToHome = () => {
  if (router.currentRoute.value.path !== '/' && router.currentRoute.value.path !== '/dashboard')
    router.push('/')
}
</script>

<style scoped></style>
