<template>
  <div class="flex flex-col gap-[20px]" :key="updateKey">
    <div
      :data-test="`question-${id}-row-${idx}`"
      :data-row="idx"
      class="flex flex-col gap-[15px] items-center"
      :key="idx"
      v-for="(row, idx) in dataRows"
      v-show="!row.delete">
      <div
        class="flex flex-col gap-y-[4px] w-full md:flex-row md:w-[386px] md:gap-y-0 md:gap-x-[20px]"
        v-for="(question, questionIdx) in row.questions"
        :key="question.id">
        <p
          class="button-2 self-start md:self-center w-full md:w-[183px]"
          :class="question.component === 'dropdown' ? 'hidden md:block' : ''">
          {{ question.title }}
        </p>
        <component
          class="w-full md:mt-[10px] md:w-[183px]"
          @update:value="(val: unknown) => updateRow(idx, question, val)"
          @toggle="(val: unknown) => updateRow(idx, question, val)"
          @selected="(val: unknown) => updateRow(idx, question, val)"
          :is="componentImports[question.component]"
          v-bind="row.props[questionIdx]" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { InputItem } from '@/types/InputItem.ts'
import { GenericObject } from '@/types/GlobalTypes.ts'
import BaseInput, { BaseInputProps } from '@/components/Inputs/BaseInput.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { Answer, TableQuestion } from './Table.type.ts'
/**
 * TODO: refactor this internal shape to be an array of objects that represent each question, not the row
 */
type Props = {
  id: number
  questions: Array<TableQuestion>
  existingAnswers?: Array<Array<Answer>>
  static: boolean
}
export type rowObject = {
  questions: TableQuestion[]
  props: { [key: string | number]: unknown }
  data: {
    [key: string | number]: unknown
  }
  delete: boolean
}
const props = defineProps<Props>()
const emits = defineEmits({
  'update:value': (answer: Array<{ [key: string | number]: unknown }>) => answer,
})
const dataRows = reactive<rowObject[]>([])
const validRows = computed(() => {
  const filtered = dataRows.filter((row: rowObject) => !row.delete)
  return {
    questionId: props.id,
    answer: filtered.map((row: rowObject) => {
      return row.data
    }),
  }
})
defineExpose({ validRows })

watch(dataRows, () => {
  emits('update:value', validRows.value.answer)
})

const updateKey = ref(0)
onMounted(() => {
  if (props.existingAnswers?.length) mapExistingRows()
  else mapNewRow()
})

const mapComponentProps = () => {
  for (let row of dataRows) {
    row.props = {}
    for (let question of row.questions) {
      const questionIndex = row.questions.indexOf(question)
      row.props[questionIndex] = getComponentProperties(
        dataRows.indexOf(row),
        questionIndex,
        question,
      )
    }
  }
}
const mapExistingRows = () => {
  if (!props.existingAnswers) return
  for (let row of props.existingAnswers) {
    const newRow = {
      questions: [] as TableQuestion[],
      data: {} as rowObject['data'],
      props: {} as rowObject['props'],
      delete: false,
    } as rowObject
    newRow.questions = props.questions
    for (let answer of row) {
      newRow.data[answer.questionId] = answer.answer
    }
    dataRows.push(newRow)
    mapComponentProps()
  }
}
const updateRow = (rowIndex: number, question: TableQuestion, value: unknown) => {
  const row = dataRows[rowIndex]
  const index = row.questions.indexOf(question)
  const props = row.props[index] as rowObject['props']
  row.data[question.id] = value

  if (question.component === 'dropdown') props.existingItem = value
  else props.value = value
}

const mapNewRow = () => {
  const rowIndex = dataRows.length
  // refactor shape
  const newRow = {
    questions: [] as TableQuestion[],
    data: {} as rowObject['data'],
    props: {} as rowObject['props'],
    delete: false,
  } as rowObject

  newRow.questions = props.questions

  for (let question of newRow.questions) {
    const index = newRow.questions.indexOf(question)
    newRow.props[index] = getComponentProperties(rowIndex, index, question)
    newRow.data[question.id] = question.defaultValue || undefined // set default value
  }
  dataRows.push(newRow)
}

// component mapping
const componentImports: GenericObject<unknown> = {
  input: BaseInput,
  dropdown: Dropdown,
}
// prop mapping
const getComponentProperties = (rowIndex: number, index: number, question: TableQuestion) => {
  switch (question.component) {
    case 'input':
      return mapInputProperties(rowIndex, index, question)
    case 'dropdown':
      return mapDropdownProperties(rowIndex, index, question)
  }
}

const mapInputProperties = (
  rowIndex: number,
  index: number,
  question: TableQuestion,
): BaseInputProps => {
  let answer: string | number | null = null
  if (props.existingAnswers && props.existingAnswers[rowIndex]) {
    answer = props.existingAnswers[rowIndex][index].answer as string | number
  } else if (question.existingValue) {
    answer = question.existingValue
  }
  return {
    value: answer || question.defaultValue || null,
    name: `question-${question.id}-row-${index}-${question.inputType}`,
    type: question.inputType,
    placeholder: question.placeholderText ?? null,
  }
}

const mapDropdownProperties = (rowIndex: number, index: number, question: TableQuestion) => {
  let answer: InputItem<string | number> | null = null
  if (props.existingAnswers && props.existingAnswers[rowIndex]) {
    answer = props.existingAnswers[rowIndex][index].answer as InputItem<string | number>
  }

  return {
    items: question.dropdownOptions || [],
    type: `${question.id}-row-${index}-dropdown`,
    existingItem: answer || question.defaultValue || null,
    placeholder: question.placeholderText ?? 'Select an option',
  }
}

watch(
  () => props.existingAnswers,
  (newVal) => {
    dataRows.splice(0, dataRows.length)
    if (newVal?.length) mapExistingRows()
    else mapNewRow()
  },
)

onUnmounted(() => {
  dataRows.splice(0, dataRows.length)
})
</script>

<style scoped></style>
