<template>
  <section>
    <!-- Edge case: if their allocation is 100% in Cash/Term Deposit -->
    <div
      v-if="allInCashOption && hasExclusionRule(ExclusionRules.ALL_IN_CASH_OR_TERM_DEPOSITS)"
      class="flex flex-col justify-center py-4 md:p-8 bg-white rounded shadow-md border border-t-0 border-grey-4 my-[20px]"
      data-test="allInCashMessage"
      v-html="pageContent?.intraSuperFixedTerm?.body" />
    <template v-if="hasExclusionRule(ExclusionRules.SOME_IN_CASH_OR_TERM_DEPOSITS)">
      <div
        v-if="termDepositConsentStatus.notGiven && !allInCashOption"
        class="flex flex-col justify-center py-4 md:p-8 bg-white rounded shadow-md border border-t-0 border-grey-4 my-[20px]"
        data-test="tdConsentNoMessage">
        You currently have elected not to receive advice since you have a portion of your
        investments in term deposits. You will not be charged for this advice if you only have one
        account.
      </div>
      <div
        v-if="termDepositConsentStatus.given && !allInCashOption"
        class="flex flex-col justify-center py-4 md:p-8 bg-white rounded shadow-md border border-t-0 border-grey-4 my-[20px]"
        data-test="tdConsentYesMessage">
        Because you've made a conscious decision to invest some of your super in Term Deposits we
        recommend that you keep them until the maturity date is reached to avoid break fees and loss
        of investment earnings. Given this, our advice excludes the Term Deposits and is focussed on
        identifying suitable investment options for the remaining balance of your super.
      </div>
    </template>
    <div
      v-if="
        !!lifeStageSuperAllocation && hasExclusionRule(ExclusionRules.INVESTED_IN_LIFE_STAGE_OPTION)
      "
      class="flex flex-col justify-center py-4 md:p-8 bg-white rounded shadow-md border border-t-0 border-grey-4 my-[20px]"
      data-test="lifeStageMessage">
      <!-- Edge case if they are in the appropriate life stage allocation -->
      <div>
        <div class="inline" v-html="pageContent?.intraSuperLifestage?.body" />
        <span class="font-bold">{{ lifeStageSuperAllocation?.chant_west_investment_name }}</span>
        <br />
        <br />
        <div class="inline" v-html="pageContent?.intraSuperLifestage?.footer" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import ClientConfirmationTermDepositConsent from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationTermDepositConsent.vue'
import { exclusionsHandlers } from '@/components/CFS/IntrafundInvestment/Utils/exclusionsHandlers.ts'
import { usePageContentStore } from '@/store/pinia/PageContentStore.ts'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import { superInvestmentRecommendationHandlers } from '@/components/CFS/IntrafundInvestment/Utils/superInvestmentRecommendationHandlers.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { webSocketHandler } from '@/components/CFS/IntrafundInvestment/Utils/webSocketHandlers.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { ExclusionRules } from '@/composables/useWhitelabelExclusionRules.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'

const modalStore = useModalStore()
const superStore = useSuperStore()
const userStore = useUserStore()
const intrafundAdviceStore = useIntrafundAdviceStore()
const { isUser, isAdmin } = useCheckUser()
const { setupTDConsentWebsocket } = webSocketHandler()

const termDepositConsentLoading = ref(false)
const { fetchSuperInvestmentAdvice } = superInvestmentRecommendationHandlers(
  userStore,
  superStore,
  isUser,
)
const {
  needTermDepositConsent,
  allInCashOption,
  lifeStageSuperAllocation,
  hasSomeInTermDepositOption,
  hasExclusionRule,
} = exclusionsHandlers()

const pageContent = computed(() => usePageContentStore().pageContent)
const termDepositConsentStatus = computed(() => intrafundAdviceStore.termDepositConsentStatus)
const activeSuper = computed(() => superStore.getActiveSuperObject)

const handleProceed = async (consented: boolean) => {
  try {
    termDepositConsentLoading.value = true
    await intrafundAdviceStore.setTermDepositConsent(consented)
    termDepositConsentLoading.value = false
    if (consented) {
      await fetchSuperInvestmentAdvice()
    }
    modalStore.closeModal()
  } catch (e) {
    console.error('handleProceed', e)
  }
}

watch(
  () => needTermDepositConsent.value,
  (newVal) => {
    if (
      isAdmin() &&
      hasSomeInTermDepositOption &&
      hasExclusionRule(ExclusionRules.SOME_IN_CASH_OR_TERM_DEPOSITS)
    )
      setupTDConsentWebsocket(activeSuper.value?.id)
    if (isUser() && newVal && hasExclusionRule(ExclusionRules.SOME_IN_CASH_OR_TERM_DEPOSITS)) {
      try {
        modalStore.preventAutoClose()
        modalStore.openModal(ClientConfirmationTermDepositConsent, {
          onProceed: handleProceed,
          loading: termDepositConsentLoading.value,
        })
      } catch (error) {
        console.error('Error opening term deposit consent modal', error)
      }
    }
  },
  {
    immediate: true,
  },
)
</script>

<style scoped></style>
