import OptimizeAdviceService from '@/services/OptimizeAdviceService'

type ListObjectType = {
  value: string
  name: string
}

export const getOccupationList = async (string: string) => {
  const search = `occupation:${string}`
  const response = await OptimizeAdviceService.getOccupations(search).catch((err) =>
    console.error(err)
  )
  if (response) {
    const occupations: ListObjectType[] = response.data.data.map((occupation) => {
      return {
        value: occupation.id,
        name: occupation.occupation
      }
    })
    return occupations
  }
}
