// src/composables/useDebtFormLogic.ts
import { computed, ref } from 'vue'
import { Liability } from '@/types/Liability'
import { ILiabilityStore } from '@/store/pinia/LiabilityStore.ts'

export function useDebtFormLogic(liabilityStore: ILiabilityStore) {
  const liability = computed(() => liabilityStore.liability)

  const totalLoanTerm = ref({ years: '', months: '' })

  // Options could potentially be moved to a separate composable or static data file if they don't change
  const ownerOptions = [
    { label: 'You', value: 'single' },
    { label: 'Partner', value: 'partner' },
    { label: 'Shared', value: 'shared' },
  ]

  const paymentTypeOptions = [
    { label: 'Principal + interest', value: 0 },
    { label: 'Interest only', value: 1 },
  ]

  const interestTypeOptions = [
    { label: 'Variable', value: 1 },
    { label: 'Fixed Term', value: 0 },
  ]

  const frequencyTypeOptions = [
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Fortnightly',
      value: 'fortnightly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ]

  const removeLoan = (liability: Liability) => liabilityStore.removeLiability(liability.id)
  const mapOwner = (owner: string | null) => ownerOptions.find((r) => r.value === owner) || null
  const mapPaymentType = (type: boolean | null) =>
    paymentTypeOptions.find((r) => r.value == type) || null
  const mapInterestType = (type: boolean | null) =>
    interestTypeOptions.find((r) => r.value == type) || null

  const handleSave = (liability: Liability) => {
    const filteredEntries = Object.entries(liability).filter(([_, value]) => value !== null)
    const payload: Partial<Liability> = Object.fromEntries(filteredEntries)
    // CC form can't set payment frequency so default to monthly
    if (liability.type === 'credit_card' && !liability.payment_frequency)
      liability.payment_frequency = 'monthly'

    try {
      if (liability.id) {
        return liabilityStore.updateLiability(payload)
      } else {
        return liabilityStore.createLiability(payload)
      }
    } catch (error) {
      console.error('Failed to save liability', error)
    }
  }

  return {
    liability,
    totalLoanTerm,
    ownerOptions,
    paymentTypeOptions,
    interestTypeOptions,
    frequencyTypeOptions,
    mapOwner,
    mapPaymentType,
    mapInterestType,
    handleSave,
    removeLoan,
  }
}
