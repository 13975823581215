<template>
  <button
    :class="[isActive ? 'activeTab bg-blue-5 border-otivo-blue' : 'inactiveTab bg-white']"
    class="text-grey-1 md:px-[20px] py-[11px] button-2 border-[2px] border-grey-3 border-solid rounded-[5px] transition-colors duration-[469ms] ease-in-out hover:border-otivo-blue focus:border-otivo-blue focus:outline-none disabled:bg-grey-4 disabled:text-grey-3 disabled:border-grey-4 disabled:pointer-events-none"
    @click.prevent="handleClick">
    {{ option.label }}
  </button>
</template>

<script lang="ts" setup>
import { InputItem } from '@/types/InputItem'

type Props = {
  option: InputItem
  isActive: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{
  (e: 'change', val: InputItem): void
}>()

const handleClick = () => {
  emits('change', props.option)
}
</script>

<style scoped>
.activeTab {
  box-shadow: var(--otivo-2px-shadow);
}

button:hover,
button:focus {
  box-shadow: var(--otivo-focus-shadow);
}

.activeTab,
button:hover,
button:focus {
  outline-offset: -1px;
}
</style>
