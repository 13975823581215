<template>
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M10 15.625a5.625 5.625 0 1 1 0-11.25 5.625 5.625 0 0 1 0 11.25Z"
        :class="`fill-${fill1}`" />
      <path
        d="M10 3.125a1.25 1.25 0 0 1-1.25-1.25V1.25a1.25 1.25 0 0 1 2.5 0v.625A1.25 1.25 0 0 1 10 3.125ZM10 20a1.25 1.25 0 0 1-1.25-1.25v-.625a1.25 1.25 0 0 1 2.5 0v.625A1.25 1.25 0 0 1 10 20ZM1.875 11.25H1.25a1.25 1.25 0 0 1 0-2.5h.625a1.25 1.25 0 0 1 0 2.5ZM18.75 11.25h-.625a1.25 1.25 0 0 1 0-2.5h.625a1.25 1.25 0 0 1 0 2.5ZM4.256 5.506a1.25 1.25 0 0 1-.884-.369l-.444-.45a1.25 1.25 0 0 1 1.76-1.76l.44.445a1.25 1.25 0 0 1-.881 2.134h.009ZM16.187 17.438a1.25 1.25 0 0 1-.875-.366l-.44-.444a1.25 1.25 0 1 1 1.765-1.765l.435.45a1.25 1.25 0 0 1-.885 2.125ZM15.744 5.506a1.25 1.25 0 0 1-.881-2.134l.45-.444a1.25 1.25 0 0 1 1.76 1.76l-.445.44a1.25 1.25 0 0 1-.884.378ZM3.812 17.437a1.25 1.25 0 0 1-.884-2.125l.443-.44a1.25 1.25 0 1 1 1.766 1.765l-.45.435a1.25 1.25 0 0 1-.875.365Z"
        :class="`fill-${fill2}`" />
    </g>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue',
  fill2: 'otivo-red',
})
</script>

<style scoped></style>
