<template>
    <p class="paragraph-1 text-blue-1 mt-[20px]">
      Please include the current annual costs for childcare and primary and secondary education. Don't
      worry about any future cost increases, we take these into account in calculating how much cover
      you need to pay for childcare and education in the future.
    </p>
    <div
      class="flex relative flex-col gap-[20px] w-full md:w-[615px] mx-auto mt-[20px]"
      :key="updateKey">
      <div
        :data-test="`question-${id}-row-${idx}`"
        :data-row="idx"
        class="flex flex-col gap-[15px] justify-center"
        :key="idx"
        v-for="(row, idx) in dataRows">
        <p class="button-2">Child {{ idx + 1 }}</p>
        <div class="flex flex-nowrap flex-col md:flex-row md:flex-wrap gap-[20px]">
          <div
            class="flex flex-col justify-between align-middle"
            v-for="(question, questionIdx) in row.questions?.slice(0, 3)"
            :key="question.id">
            <p
              class="paragraph-2 self-start"
              :class="question.component === 'dropdown' ? 'hidden md:block' : ''">
              {{ question.title }}
            </p>
            <component
              class="mt-[10px] w-full md:w-[153px]"
              @update:value="(val: unknown) => updateRow(idx, question, val)"
              @toggle="(val: unknown) => updateRow(idx, question, val)"
              @selected="(val: unknown) => updateRow(idx, question, val)"
              :is="componentImports[question.component]"
              v-bind="row.props[questionIdx]" />
          </div>
        </div>

        <p class="button-2">Annual childcare costs</p>
        <div class="flex flex-nowrap flex-col md:flex-row md:flex-wrap gap-[20px]">
          <div
            class="flex flex-col justify-between align-middle"
            v-for="(question, questionIdx) in row.questions?.slice(-3)"
            :key="question.id">
            <p
              class="paragraph-2 self-start"
              :class="question.component === 'dropdown' ? 'hidden md:block' : ''">
              {{ question.title }}
            </p>
            <component
              class="mt-[10px] w-full md:w-[153px]"
              @update:value="(val: unknown) => updateRow(idx, question, val)"
              @toggle="(val: unknown) => updateRow(idx, question, val)"
              @selected="(val: unknown) => updateRow(idx, question, val)"
              :is="componentImports[question.component]"
              v-bind="row.props[questionIdx + 3]" />
          </div>
        </div>
        <div
          class="flex md:absolute md:right-[20px] flex-row-reverse md:flex-col md:self-end justify-evenly md:justify-center h-[49px]">
          <div @click="mapNewRow" class="cursor-pointer text-otivo-blue button-1 block md:hidden">
            Add +
          </div>
          <div class="flex cursor-pointer button-2 text-red-dark" @click="() => deleteRow(idx)">
            Remove
          </div>
        </div>
      </div>
      <div class="justify-center hidden md:flex">
        <div
          @click="mapNewRow"
          class="cursor-pointer text-otivo-blue button-1 inline-flex items-center gap-x-1">
          Add another child <span class="text-6xl font-thin">+</span>
        </div>
      </div>
    </div>
    <p class="paragraph-1 text-blue-1 mt-[20px]">Annual costs</p>
    <p class="paragraph-1 text-blue-1 mt-[20px]">
      The more you include in your annual costs, the higher the level of cover that we will recommend,
      which also means that your insurance premiums will be higher.
    </p>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { id } from 'date-fns/locale'
import BaseInput, { BaseInputProps } from '@/components/Inputs/BaseInput.vue'
import BaseDateInput from '@/components/Inputs/BaseDateInput.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { InputItem } from '@/types/InputItem.ts'
import { GenericObject } from '@/types/GlobalTypes.ts'
import { Answer, TableQuestion } from './Table.type.ts'

type Props = {
  id: number
  questions: Array<TableQuestion>
  existingAnswers?: Array<Array<Answer>>
}
export type rowObject = {
  questions: TableQuestion[]
  props: { [key: string | number]: unknown }
  data: {
    [key: string | number]: unknown
  }
}
const props = defineProps<Props>()
const emits = defineEmits({
  'update:value': (answer: Array<{ [key: string | number]: unknown }>) => answer
})
const dataRows = reactive<rowObject[]>([])
const validRows = computed(() => {
  return {
    questionId: props.id,
    answer: dataRows.map((row: rowObject) => row.data)
  }
})
defineExpose({ validRows })

watch(dataRows, () => {
  emits('update:value', validRows.value.answer)
})

const updateKey = ref(0)
onMounted(() => {
  if (props.existingAnswers?.length) {
    mapExistingRows()
    return
  }
  mapNewRow()
})

const mapComponentProps = () => {
  for (let row of dataRows) {
    row.props = {}
    for (let question of row.questions) {
      const questionIndex = row.questions.indexOf(question)
      row.props[questionIndex] = getComponentProperties(
        dataRows.indexOf(row),
        questionIndex,
        question
      )
    }
  }
}
const mapExistingRows = () => {
  if (!props.existingAnswers) return
  for (let row of props.existingAnswers) {
    const newRow = {
      questions: [] as TableQuestion[],
      data: {} as rowObject['data'],
      props: {} as rowObject['props']
    } as rowObject
    newRow.questions = props.questions
    for (let answer of row) {
      newRow.data[answer.questionId] = answer.answer
    }
    dataRows.push(newRow)
    mapComponentProps()
  }
}
const updateRow = (rowIndex: number, question: TableQuestion, value: unknown) => {
  const row = dataRows[rowIndex]
  const index = row.questions.indexOf(question)
  const props = row.props[index] as rowObject['props']
  row.data[question.id] = value

  if (question.component === 'dropdown') props.existingItem = value
  else props.value = value
}

const deleteRow = (idx: number) => {
  dataRows.splice(idx, 1)
}

const mapNewRow = () => {
  const newRowIndex = dataRows.length
  // refactor shape
  const newRow = {
    questions: [] as TableQuestion[],
    data: {} as rowObject['data'],
    props: {} as rowObject['props']
  } as rowObject

  newRow.questions = props.questions

  for (let question of newRow.questions) {
    const index = newRow.questions.indexOf(question)
    const prop = getComponentProperties(newRowIndex, index, question) as BaseInputProps
    newRow.props[index] = { ...prop, value: '' }
    newRow.data[question.id] = question.defaultValue || undefined // set default value
  }
  dataRows.push(newRow)
}

// component mapping
const componentImports: GenericObject<unknown> = {
  input: BaseInput,
  dropdown: Dropdown,
  dob: BaseDateInput
}
// prop mapping
const getComponentProperties = (rowIndex: number, index: number, question: TableQuestion) => {
  switch (question.component) {
    case 'input':
    case 'dob':
      return mapInputProperties(rowIndex, index, question)
    case 'dropdown':
      return mapDropdownProperties(rowIndex, index, question)
  }
}

const mapInputProperties = (
  rowIndex: number,
  index: number,
  question: TableQuestion
): BaseInputProps => {
  let answer: string | number | boolean | null = null
  if (props.existingAnswers && props.existingAnswers[rowIndex]) {
    answer = props.existingAnswers[rowIndex][index].answer as string | number | boolean
  } else if (question.existingValue) {
    answer = question.existingValue
  }
  return {
    value: answer || question.defaultValue || null,
    name: `question-${question.id}-row-${index}-${question.inputType}`,
    type: question.inputType,
    placeholder: question.placeholderText ?? null
  }
}

const mapDropdownProperties = (rowIndex: number, index: number, question: TableQuestion) => {
  let answer: InputItem<string | number> | null = null
  if (props.existingAnswers && props.existingAnswers[rowIndex]) {
    answer = props.existingAnswers[rowIndex][index].answer as InputItem<string | number>
  }

  return {
    items: question.dropdownOptions || [],
    type: `${question.id}-row-${index}-dropdown`,
    existingItem: answer || question.defaultValue || null,
    placeholder: question.placeholderText ?? 'Select an option'
  }
}

watch(
  () => props.existingAnswers,
  (newVal) => {
    dataRows.splice(0, dataRows.length)
    if (newVal?.length) {
      mapExistingRows()
      return
    }
    mapNewRow()
  }
)

onUnmounted(() => {
  dataRows.splice(0, dataRows.length)
})
</script>
