import {
  handleCreateCallback,
  handleRedirectCallback,
  isAuthenticated,
  login,
  user as Auth0User,
  User,
  userRole,
} from '@/lib/AuthenticatorPlugin.ts'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { Router } from 'vue-router'
import checkAccountStatus from '@/composables/checkAccountStatus.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { IUserStore } from '@/store/pinia/UserStore.ts'

export const accountCreationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  userStore: IUserStore,
) => {
  if (!isAuthenticated.value) login()
  if (to.query.code && to.query.state) {
    const url = (await handleCreateCallback()) as string
    next(url)
  } else {
    console.info(`[Guard] redirecting to login`, to.name)
    if (to.name !== 'UserDetailsForm' && !userStore.getUser.auth0id) userStore.fetchUser()
    next()
  }
}

const adminUserRoles = ['superadmin', 'admin', 'callcentre', 'backoffice']
export const handleLoginCallback = async (router: Router) => {
  const redirectUrl = (await handleRedirectCallback()) as string
  const incompleteAccountRedirectUrl = await checkAccountStatus(Auth0User.value as User)

  if (adminUserRoles.includes(userRole.value)) {
    router.push(redirectUrl)
    return
  } else {
    try {
      router.push(incompleteAccountRedirectUrl.name ? incompleteAccountRedirectUrl : redirectUrl)
    } catch (e) {
      throw new Error('error handling redirect callback', e)
    }
  }
}

export const adviceAuthGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  userStore: IUserStore,
): Promise<void> => {
  if (isAuthenticated.value) {
    const { isAdmin } = useCheckUser()
    // adding this check to allow users to checkout content/privacy etc when they're logged in
    if (isAdmin()) {
      next()
      return
    }
    // handles refresh get
    const user = userStore.getUser
    if (!user.auth0id) userStore.fetchUser()
    try {
      const incompleteAccountRedirectUrl = await checkAccountStatus(Auth0User.value as User)
      // Go to 'create account step user dropped from'
      if (incompleteAccountRedirectUrl.name) next(incompleteAccountRedirectUrl)
      else next()
    } catch (e) {
      console.error('error adviceAuthGuard', e)
    }
  } else {
    login()
  }
}
