<template>
  <div class="w-full" v-if="!stopRender">
    <!-- Common content above the form -->
    <section class="w-full common-above flex flex-col mt-5 md:mt-20 px-[5px]">
      <canvas ref="chart" id="repayment-chart"></canvas>
      <div id="legend" class="mt-5 md:mt-10 self-center md:self-end"></div>
    </section>
    <button
      class="text-otivo-blue px-5 button-2 items-center mt-5 md:-mt-6 underline underline-offset-4 block mx-auto md:mx-0"
      data-test="worked-out"
      @click="handleWorkedOutModal">
      How we worked this out
    </button>

    <section
      class="flex flex-col gap-10 mt-10 bg-white md:border-blue-3 md:border md:rounded-lg px-5 py-10 md:p-[40px]">
      <ManageRepayments
        v-if="!liabilityStore.loadingStates.fetchingSingle && liability"
        :save-in-interest="interestSaved"
        :min-repayment="getMinimumRepayment()"
        :max-repayment="Math.ceil(maxRepaymentValue)"
        :current-repayment="+liability?.repayment_amount ?? 0"
        :recommended-repayment="
          isRecommendedPlan ? recommendation.debt.repayment_amount : undefined
        "
        :repayments="repayments"
        @update:repayment="handleUpdateRepayment" />

      <PayDebtDownFasterCard
        @updated="resetGraphs"
        v-if="isRecommendedPlan"
        :disabled="liability?.module_status === 'actioned'" />
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { Chart } from 'chart.js/auto'
import { format } from 'date-fns'
import { add } from 'date-fns/add'
import ManageRepayments from '../Components/ManageRepayments.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import {
  calculateEffectiveInterestRate,
  calculatePeriodRate,
  frequencyMap,
  LoanDetails,
  LoanParams,
  useCalculateLoanRepayments,
} from '@/composables/debt/computeLoanRepayments.ts'
import type { Frequency, LoanParams as MinRepaymentParams } from '@/composables/debt/computeMinRepayments.ts'
import {
  calculateMinCreditCardRepayment,
  calculateMinInterestOnlyRepayment,
  calculateMinLoanRepayment,
} from '@/composables/debt/computeMinRepayments.ts'
import { defaultOptions, drawGraph } from '@/composables/charts/base/lineChart.ts'
import { Repayment, RepaymentTypeEnum } from '@/types/Debt/DebtRecommendationType'
import PayDebtDownFasterCard from '@/views/Otivo/Dashboard/Debt/Components/PayDebtDownFasterCard.vue'
import type { Liability, LiabilityType } from '@/types/Liability.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import computeTotalInterest from '@/composables/debt/computeTotalInterest.ts'
import { getMinimumTermForLiability } from '@/composables/debt/liabilityHelpers.ts'

const liabilityStore = useLiabilityStore()
const liabilities = computed(() => liabilityStore.liabilities)
const liability = computed<Liability>(() => liabilityStore.liability)
const route = useRoute()

const getMinimumRepayment = (frequencyOverride?: Frequency) => {
  const calculations: Record<LiabilityType | 'default', (params: MinRepaymentParams) => number> = {
    credit_card: calculateMinCreditCardRepayment,
    default: liability.value.interest_only
      ? calculateMinInterestOnlyRepayment
      : calculateMinLoanRepayment,
  }

  const calculation =
    liability.value?.type === 'credit_card' ? calculations['credit_card'] : calculations['default']

  return calculation({
    balance: Number(liability.value.balance),
    interestRate: Number(liability.value.interest_rate),
    frequency: frequencyOverride ?? liability.value.payment_frequency,
    term: Number(liability.value.term),
    offsetBalance: Number(liability.value.offset_balance), // if undefined defaults to 0
  })
}

const handleWorkedOutModal = async () => {
  const component = await import(
    '@/views/Otivo/Dashboard/Debt/Modals/DebtGraphAssumptionsModal.vue'
  )
  useModalStore().openModal(component.default, {
    liabilityType: liability.value.type,
  })
}

const formChartDataParams = (): LoanParams => {
  const minimumTerm = getMinimumTermForLiability(liability.value)

  return {
    principal: Number(liability.value.balance),
    paymentAmount: 0,
    offsetBalance: liability.value.offset_balance ?? 0,
    nominalAnnualRate: Number(liability.value.interest_rate),
    remainingTerm: minimumTerm,
    paymentFrequency: liability.value.payment_frequency,
    compoundingFrequency: liability.value.payment_frequency,
    interestOnly: <boolean>liability.value?.interest_only ?? false,
  }
}

const calculateLoanRepayment = (params: LoanParams): LoanDetails => {
  return useCalculateLoanRepayments(params)
}

const getChartData = (repaymentData: LoanDetails) => {
  const now = new Date()
  const chartLabelData = repaymentData.amortisationSchedule.map((repayment) => {
    const periodsInYears = (repayment.period / frequencyMap[liability.value.payment_frequency]) * 12
    return format(add(now, { months: periodsInYears }), 'yyyy')
  })
  const chartBalanceData = repaymentData.amortisationSchedule.map((repayment) => {
    return repayment.remainingBalance
  })

  return {
    chartLabelData,
    chartBalanceData,
  }
}

/**
 * Repayment Calculations and Graphs
 */

const minimumRepaymentTileData = ref({
  monthly_amount: 0,
  time_to_pay_off: '0 years,  0months',
  total_interest: 0,
})
const minimumRepaymentCalculation = async () => {
  const minimumRepayment = getMinimumRepayment()
  const params: LoanParams = formChartDataParams()
  params.paymentAmount = minimumRepayment
  const repaymentData = calculateLoanRepayment(params)

  minimumRepaymentTileData.value.monthly_amount = minimumRepayment
  minimumRepaymentTileData.value.total_interest = Math.ceil(repaymentData.totalInterestPaid)
  minimumRepaymentTileData.value.time_to_pay_off = getMonthsAndYears(
    repaymentData.amortisationSchedule.length,
  )

  const timeToPayOffInYears = Math.floor(
    (repaymentData.amortisationSchedule.length - 1) /
      frequencyMap[liability.value.payment_frequency],
  )

  const { chartLabelData, chartBalanceData } = getChartData(repaymentData)
  const lineConfig = getLineConfig(chartLabelData, chartBalanceData, 'Minimum Repayment')

  let canvas = document.getElementById('repayment-chart') as HTMLCanvasElement
  if (!canvas) {
    console.log('canvas not ready, retrying...')
    await nextTick()
    canvas = document.getElementById('repayment-chart') as HTMLCanvasElement
    if (!canvas) {
      console.error('Canvas element not found after retry')
      return
    }
  }

  const options = {
    ...defaultOptions,
    scales: {
      y: {
        title: {
          display: true,
          text: `Balance`,
          color: '#0037A1',
          font: {
            family: 'Raleway',
            size: 12,
            weight: 'bold',
          },
        },
        border: { display: false },
        ticks: {
          maxTicksLimit: 6,
          padding: 10,
          callback: function (label: number) {
            const val = label / 1000
            if (val < 1000) return `$${val}K`
            return `$${val / 1000}M`
          },
        },
        grid: {
          color: '#A0E6FA',
        },
      },
      x: {
        ticks: {
          maxTicksLimit: timeToPayOffInYears / 2,
        },
        border: { display: false },
        grid: {
          display: false,
          zeroLineColor: 'transparent',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    responsive: true,
    // aspectRatio: 4.6,
    aspectRatio: 3.85, // exact same aspect ratio as the graph in the design
  }
  await drawGraph(lineConfig, canvas, options)
}

// CURRENT REPAYMENT
const currentRepaymentTileData = ref({
  monthly_amount: liability.value?.repayment_amount ?? 0,
  time_to_pay_off: '0 years,  0 months',
  total_interest: 0,
})

const currentRepaymentCalculation = () => {
  const params: LoanParams = formChartDataParams()
  // set payment amount
  params.paymentAmount = currentRepaymentTileData.value.monthly_amount
  const repaymentData = calculateLoanRepayment(params)

  // if the current repayment is higher than the balance, set the monthly amount to the balance
  currentRepaymentTileData.value = {
    monthly_amount:
      currentRepaymentTileData.value.monthly_amount >= liability.value.balance
        ? liability.value.balance
        : currentRepaymentTileData.value.monthly_amount,
    time_to_pay_off: getMonthsAndYears(repaymentData.amortisationSchedule.length),
    total_interest: Math.ceil(repaymentData.totalInterestPaid),
  }
  if (currentRepaymentTileData.value.monthly_amount === liability.value?.balance) {
    currentRepaymentTileData.value.total_interest = 0
    currentRepaymentTileData.value.time_to_pay_off = getMonthsAndYears(2)
  }

  const { chartLabelData, chartBalanceData } = getChartData(repaymentData)
  const lineConfig = getLineConfig(chartLabelData, chartBalanceData, 'Current Repayment', {
    borderColor: '#982C17',
    borderDash: [5, 5],
  })
  updateGraph(lineConfig)
}

// RECOMMENDED REPAYMENT

const recommendedRepaymentTileData = ref({
  monthly_amount: 0,
  time_to_pay_off: '0 years,  0 months',
  total_interest: 0,
})

const recommendedContributionCalculation = () => {
  const params: LoanParams = formChartDataParams()
  const minRepayment = getMinimumRepayment()
  const recommendedRepayment = recommendation.value?.debt.repayment_amount ?? 0
  if (recommendedRepayment < minRepayment)
    throw new Error('Recommended repayment is less than minimum repayment')

  params.paymentAmount = recommendedRepayment
  const repaymentData = calculateLoanRepayment(params)
  recommendedRepaymentTileData.value.monthly_amount = recommendedRepayment
  recommendedRepaymentTileData.value.total_interest = Math.ceil(repaymentData.totalInterestPaid)

  recommendedRepaymentTileData.value.time_to_pay_off = getMonthsAndYears(
    repaymentData.amortisationSchedule.length,
  )

  const { chartLabelData, chartBalanceData } = getChartData(repaymentData)
  return getLineConfig(chartLabelData, chartBalanceData, 'Recommended Repayment', {
    borderColor: '#ff0000',
    borderDash: [5, 5],
  })
}

const getMonthsAndYears = (periods: number) => {

  const months = Math.floor(((periods - 1) / frequencyMap[liability.value.payment_frequency]) * 12)
  const years = Math.floor(months / 12)
  const remainingMonths = months % 12
  return `${years} years, ${remainingMonths} months`
}

const updateGraph = (lineConfig) => {
  const graph = Object.values(Chart.instances)
    .filter((c) => c.canvas.id == 'repayment-chart')
    .pop() as Chart
  const lineConfigLabel = lineConfig.datasets[0].label
  const existingPlot = graph.data.datasets.find((dataset) => dataset.label === lineConfigLabel)
  if (existingPlot) {
    existingPlot.data = lineConfig.datasets[0].data
  } else {
    graph.data.datasets.push(lineConfig.datasets[0])
  }
  graph.update()
}

/**
 * Make this part of the chart composable
 * @param chartLabelData
 * @param chartBalanceData
 * @param label
 * @param options
 */
const getLineConfig = (
  chartLabelData: string[],
  chartBalanceData: number[],
  label: string,
  options: {
    borderColor?: string
    hoverBackgroundColor?: string
    borderWidth?: number
    pointRadius?: number
    pointBorderColor?: string
    pointBorderWidth?: number
    borderDash?: number[]
    fill?: boolean
  } = {},
) => {
  //defaults
  const {
    borderColor = '#62A3FF',
    hoverBackgroundColor = '#0037A1',
    borderWidth = 3,
    pointRadius = 0,
    pointBorderColor = '#fff',
    pointBorderWidth = 1,
    borderDash,
    fill = false,
  } = options
  // return object
  return {
    labels: chartLabelData,
    datasets: [
      {
        label: label,
        data: chartBalanceData,
        backgroundColor: (context) => {
          const { ctx } = context.chart
          const gradient = ctx.createLinearGradient(
            context.chart.width / 1.1,
            0,
            context.chart.width,
            context.chart.height,
          )

          gradient.addColorStop(0.25, '#a8e0ff')
          gradient.addColorStop(1, 'rgba(215, 245, 255, 0)')
          return gradient
        },
        borderColor,
        hoverBackgroundColor,
        borderWidth,
        pointRadius,
        pointBorderColor,
        pointBorderWidth,
        borderDash,
        fill,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: true,
        pointBackgroundColor: '#fff',
        datalabels: {
          color: 'transparent',
        },
      },
    ],
  }
}

const stopRender = computed(() => {
  return (
    liability.value &&
    (liability.value.completed ||
      liability.value.balance == 0 ||
      liability.value.paid_monthly ||
      liability.value.interest_rate == 0)
  )
})

const maxRepaymentValue = computed(() => {
  const balance = liability.value?.balance ?? 0
  return Math.min(liability.value?.max_payment ?? 0, balance)
})

const interestSaved = computed(() => {
  if (!liability.value) return 0
  const periodRate = calculatePeriodRate(
    calculateEffectiveInterestRate(
      liability.value.interest_rate,
      liability.value.payment_frequency,
    ),
    liability.value.payment_frequency,
  )
  return (
    computeTotalInterest(
      liability.value.repayment_amount ?? liability.value.min_payment,
      periodRate,
      liability.value.balance - liability.value.offset_balance,
    ) - currentRepaymentTileData.value.total_interest
  )
})

const recommendation = computed(() => liabilityStore.recommendation)
const isRecommendedPlan = computed(() => recommendation.value?.debt?.plan_id == +route.params.id)
const repayments = computed((): Array<Repayment> => {
  const repayments = [] as Array<Repayment>
  if (liability.value?.min_payment) {
    repayments.push({
      repayment_type: RepaymentTypeEnum.MINIMUM_REPAYMENT,
      monthly_amount: minimumRepaymentTileData.value.monthly_amount,
      time_to_pay_off: minimumRepaymentTileData.value.time_to_pay_off,
      total_interest: minimumRepaymentTileData.value.total_interest,
    })
  }
  if (liability.value?.repayment_amount) {
    repayments.push({
      repayment_type: RepaymentTypeEnum.CURRENT_REPAYMENT,
      infoCircleMessage:
        'The amount you pay, which meets or exceeds the required minimum repayment.',
      monthly_amount: currentRepaymentTileData.value.monthly_amount,
      time_to_pay_off: currentRepaymentTileData.value.time_to_pay_off,
      total_interest: currentRepaymentTileData.value.total_interest,
    })
  }

  if (isRecommendedPlan.value) {
    repayments.push({
      repayment_type: RepaymentTypeEnum.RECOMMENDED_REPAYMENT,
      monthly_amount: recommendedRepaymentTileData.value.monthly_amount,
      time_to_pay_off: recommendedRepaymentTileData.value.time_to_pay_off,
      total_interest: recommendedRepaymentTileData.value.total_interest,
    })
  }

  return repayments
})

const resetGraphs = async () => {
  const chart = Object.values(Chart.instances)
    .filter((c) => c.canvas.id == 'repayment-chart')
    .pop() as Chart
  chart.destroy()

  await minimumRepaymentCalculation()
  currentRepaymentCalculation()
  if (recommendation.value) {
    const recommendationConfig = recommendedContributionCalculation()
    updateGraph(recommendationConfig)
  }
}

onMounted(async () => {
  if (!recommendation.value && liability?.value?.module_status !== 'infoNeeded')
    await liabilityStore.fetchDebtRecommendation()
  if (liabilities.value.length <= 0) await liabilityStore.fetchLiabilities()
  const currentLiability = liabilities.value.find(
    (liability) => Number(liability.id) === Number(route.params.id),
  )
  if (currentLiability) {
    liabilityStore.setLiability(currentLiability)
    await minimumRepaymentCalculation()
    if (currentLiability.repayment_amount) {
      if (!currentRepaymentTileData.value.monthly_amount) {
        currentRepaymentTileData.value.monthly_amount = currentLiability.repayment_amount
      }
      await currentRepaymentCalculation()
    }
    if (
      recommendation.value &&
      Number(recommendation.value.debt.plan_id) === Number(route.params.id)
    ) {
      const recommendationConfig = recommendedContributionCalculation()
      updateGraph(recommendationConfig)
    }
  }
})

const handleUpdateRepayment = useDebounceFn((value: number) => {
  currentRepaymentTileData.value.monthly_amount = value
  currentRepaymentCalculation()
}, 500)
</script>
