<template>
  <div class="flex flex-col items-center justify-center w-100%">
    <div id="capture-super-balance" class="w-100% max-w-[375px]">
      <h6>Search for your Super Fund product</h6>
      <p class="paragraph-2 text-blue-1 mt-[20px]">
        Otivo has partnered with a variety of super funds to help you be better off. See if yours is
        here 👇
      </p>
      <Autocomplete
        class="mt-[20px]"
        @selected="(fund) => setupSuperFund(fund)"
        :get-items="getSuperFundNames"
        :existing-value="{
          value: activeSuperObject.chant_west_product_id,
          name: activeSuperObject.fund_name,
        }"
        id="autocomplete-select-superfund"
        placeholder="Enter your Super fund"
        type="superfund" />
      <!--      <Dropdown-->
      <!--        class="mt-[20px]"-->
      <!--        v-model="selectedSuperFund"-->
      <!--        :options="superFunds"-->
      <!--        placeholder="Select your super fund" />-->
    </div>
    <p class="otivo-error-message font-bold mt-5" v-if="formError">{{ formError }}</p>
    <OtivoButton class="w-100% max-w-[375px] mt-[60px]" :loading="loading" @click="next">
      Next
    </OtivoButton>
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import { computed, ref } from 'vue'
import Autocomplete from '@/components/BasicInputs/Autocomplete.vue'
import { generateOtivoAccountNumber, getSuperFundNames } from '@/composables/getSuperFundNames.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useToast } from '@/composables/useToast.ts'
import SuperObject from '@/types/api/super/SuperObject.ts'
import { userAuth0Id } from '@/lib/AuthenticatorPlugin.ts'

const superStore = useSuperStore()
//@TODO This is wrong and must be fixed later
superStore.fetchAllSupers()

const { errorToast } = useToast()

const activeSuperObject = computed<SuperObject>(() => superStore.getActiveSuperObject)

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const setupSuperFund = (fund) => {
  if (!fund) {
    errorToast('No super fund found')
    throw new Error('No super fund found')
  }

  activeSuperObject.value.chant_west_product_id = fund.value
  activeSuperObject.value.fund_name = fund.name
}

const loading = ref(false)

const formError = ref()
const next = async () => {
  loading.value = true
  formError.value = null
  try {
    if (!activeSuperObject?.value.chant_west_product_id) {
      errorToast('Please select your super product')
      throw new Error('Please select your super product')
    }
    // add check for existing users with no account number
    if (!activeSuperObject.value.account_number)
      activeSuperObject.value.account_number = generateOtivoAccountNumber()

    const superFundUpdateObject = {
      auth0id: userAuth0Id.value,
      account_number: activeSuperObject.value.account_number ?? generateOtivoAccountNumber(),
      allocation: [],
      balance: 0,
      data_source: 'user',
      chant_west_product_id: activeSuperObject.value.chant_west_product_id,
    }

    await superStore.updateSuperObject(activeSuperObject.value.id, superFundUpdateObject)
    loading.value = false
    emit('next', 'captured super fund')
  } catch (e) {
    formError.value = e.message
    console.error(e)
    loading.value = false
  }
}
</script>

<style scoped></style>
