import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { FlagKey, IAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { toKebabCase } from '@/utilities.ts'
import { IUserStore } from '@/store/pinia/UserStore.ts'
import { computed } from 'vue'
import { ISessionStore } from '@/store/pinia/SessionStore.ts'

export const adviceRouteGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  userStore: IUserStore,
  accountFlagsStore: IAccountFlagsStore,
  sessionStore: ISessionStore,
): Promise<void> => {
  const { isUser } = useCheckUser()
  const userData = computed(() => userStore.getUser)
  const routerName = to.name as string

  // this triggers a spam on every
  await accountFlagsStore.fetchAccountFlags() // need this otherwise it'll default to false - race condition

  const flagKey = routerName.replace('Admin', '') as FlagKey

  const acknowledgeDisclaimer = !!accountFlagsStore.getFlag(flagKey)?.acknowledged_disclaimer

  if (isUser()) {
    if (!acknowledgeDisclaimer) {
      next({ name: 'howTheAdviceWorksPage', params: { adviceTargetName: toKebabCase(routerName) } })
      return
    } else if (
      userData.value.auth0id &&
      !userData.value.has_consented &&
      sessionStore.getWhitelabelData.callcentre_portal
    ) {
      // @TODO check only for guidance team created clients
      next({ name: 'clientDisclaimer', params: { adviceTargetName: toKebabCase(routerName) } })
      return
    }
  }
  next()
}
