<template>
  <div class="animate-pulse" role="status" aria-label="Loading order summary">
    <span class="sr-only">Loading order summary...</span>
    <h5 class="bg-grey-light w-[66%] mb-1" aria-hidden="true">&nbsp;</h5>
    <p class="paragraph-3 bg-grey-light w-[73%] mb-1" aria-hidden="true">&nbsp;</p>
    <p class="paragraph-2 mt-5 bg-grey-light w-[80%]" aria-hidden="true">&nbsp;</p>
    <Divider />
    <div class="flex flex-col gap-5" aria-hidden="true">
      <div v-for="width in ['w-[65%]', 'w-[75%]', 'w-[60%]', 'w-[85%]']" class="flex gap-3 items-center">
        <BasicTick class="flex fill-grey-light h-4 w-4 flex-shrink-0"></BasicTick>
        <p class="paragraph-2 flex flex-grow-1 bg-grey-light h-4" :class="`${width}`"></p>
      </div>
    </div>
    <Divider class="mt-auto" aria-hidden="true" />
    <h5 class="font-bold mt-[60px] flex" aria-hidden="true">
      <div class="mr-1 w-12 bg-grey-light">&nbsp;</div>
      <div class="paragraph-2 bg-grey-light w-20">&nbsp;</div>
    </h5>
  </div>
</template>
<script>
import Divider from '@/components/CreateAccount/Paywall/Partials/Divider.vue'
import BasicTick from '@/components/icons/BasicTick.vue'

export default {
  name: 'OrderSummarySkeleton',
  components: { BasicTick, Divider }
}
</script>
