<template>
  <div class="md:py-20 flex-grow flex justify-center relative bg-blue-6 h-100%">
    <div
      class="text-grey-1 bg-white h-fit p-[40px] min-w-50% rounded-[10px] otivo-drop-shadow max-w-[1184px]">
      <div>
        <h3 class="text-blue-1">Client Reports</h3>
      </div>

      <div class="mt-[40px]">
        <!-- header -->
        <div class="grid grid-cols-3 border-[1px] bg-grey-4 border-grey-3 rounded-t-[5px] button-1">
          <div class="p-4">Date</div>
          <div class="p-4 border-x-[1px] border-grey-3">Consent</div>
          <div class="p-4">Switch</div>
        </div>

        <loader
          v-if="loading"
          class-list="lds-spinner self-center m-auto max-h-48 max-w-48 min-h-10 min-w-10 " />

        <div v-if="reports.length > 0">
          <!-- data -->
          <div
            v-for="(report, index) in reports"
            :key="index"
            class="grid grid-cols-3 border-[1px] border-t-0 border-grey-3 last-of-type:rounded-b-[5px] even:bg-grey-5">
            <div class="p-4">{{ report.date }}</div>
            <div class="p-4 border-x-[1px] border-grey-3 flex justify-between">
              ({{ formatNumber(report.consent.count) }})
              <div
                data-test="consentDownloadBtn"
                class="gap-3 flex items-center cursor-pointer"
                v-if="report.consent.count"
                @click="downloadReport('Consent', report.consent.link)">
                <DownloadIcon />
                <a class="font-bold underline decoration-1 decoration-otivo-blue underline-offset-2"
                  >Download</a
                >
              </div>
            </div>
            <div class="p-4 flex justify-between">
              ({{ formatNumber(report.switch.count) }})
              <div
                class="flex gap-3 items-center cursor-pointer"
                data-test="switchDownloadBtn"
                v-if="report.switch.count"
                @click="downloadReport('Switch', report.switch.link)">
                <DownloadIcon />
                <a class="font-bold underline decoration-1 decoration-otivo-blue underline-offset-2"
                  >Download</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Loader from '@/components/Loader/Loader.vue'
import { downloadFile } from '@/lib/WhiteLabelHelpers'
import DownloadIcon from '@/components/SVGS/DownloadIcon.vue'
import { formatNumber } from '@/lib/helpers'
import moment from 'moment'
import {
  downloadCFSBackendReport,
  fetchCFSBackendReports,
} from '@/store/pinia/adminPortal/Reporting.ts'

const loading = ref(false)

const reports = ref([])

const downloadReport = (type: string, link: string) => {
  return downloadCFSBackendReport(link)
    .then((res) => {
      const fileName = `${type}_${moment().format('YYYYMMDD')}.zip`
      downloadFile('zip', res.data[0], fileName)
    })
    .catch((err) => {
      console.error(err)
    })
}

onMounted(async () => {
  loading.value = true
  reports.value = await fetchCFSBackendReports()
  loading.value = false
})
</script>
