<template>
  <loader v-if="loading" class-name="" />
  <highcharts id="retAssetChart" ref="retAssetChart" v-else :options="chartOptions" />
</template>

<script>
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import { abbrNum, getRootColor } from '@/lib/helpers'
import Loader from '@/components/Loader/Loader.vue'
import { mapGetters } from 'vuex'
import { cloneObject } from '@/utilities.ts'

exportingInit(Highcharts)

export default {
  name: 'RetCalcAssetsChart',

  components: {
    Loader,
    highcharts: Chart,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    years: {
      type: [Number, String],
      default: 92,
    },
    renderLegend: {
      type: Boolean,
      default: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    partnerData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      numLegends: null,
    }
  },

  computed: {
    ...mapGetters('RetirementCalculator', ['enablePartnerForm']),
    computedChartHeight() {
      if (window.innerWidth > 600) {
        return null
      }
      return 'height: ' + (300 + this.numLegends * 50) + 'px'
    },
    getSeries() {
      // restrict for years
      const restrictToYear = Number(this.years)
      const updateAssetsChartData = cloneObject(this.chartData)
      return updateAssetsChartData.map((el) => {
        // restrict for years
        el.data = el.data.filter((dataEl, i) => i <= restrictToYear)
        el.dashStyle = 'shortdash'
        el.visible = true
        if (el.name.toLowerCase() === 'net assets') el.name = 'Total assets'
        el.color = getRootColor('cfs-blue')
        if (el.name.toLowerCase() === 'super') el.color = getRootColor('cfs-lightblue')
        if (el.name.toLowerCase() === 'partner super') el.color = getRootColor('gold-1')
        if (el.type !== undefined && (typeof el.type === 'string' || el.type instanceof String)) {
          if (el.type.toLowerCase() === 'home') el.color = getRootColor('pink-1')
          if (el.type.toLowerCase() === 'debt') el.color = getRootColor('purple-1')
          if (el.type.toLowerCase() === 'shares') el.color = getRootColor('red-1')
          if (el.type.toLowerCase() === 'alternative') {
            el.color = getRootColor('gold-2')
            el.name = 'Other'
          }

          if (el.type.toLowerCase() === 'cashandfixed') {
            el.color = getRootColor('green-1')
            el.name = 'Cash'
          }
          if (el.type.toLowerCase() === 'property') {
            el.color = getRootColor('purple-2')
            el.name = 'Investment property'
          }
          el.type = null
        }
        if (el.name.toLowerCase() !== 'partner super' && el.name.toLowerCase() !== 'super') {
          el.name = this.formatHTML(el.name, el.color)
        }
        if (el.name.toLowerCase() === 'partner super')
          el.name = this.formatHTML("Partner's super", getRootColor('gold-1'))
        if (el.name.toLowerCase() === 'super')
          el.name = this.formatHTML('Your super', getRootColor('cfs-lightblue'))

        return el
      })
    },

    getPlotLines() {
      // plot lines
      // const userYPoint = 0
      // const partnerYPoint = 0
      const plotLines = []
      if (this.userData.age > 0) {
        plotLines.push({
          color: getRootColor('retirement'),
          label: {
            text: 'You retire',
            rotation: 0,
            style: {
              color: '#164A9A',
              fontWeight: 'bold',
            },
            // y: userYPoint
            y: 0,
          },
          width: 2,
          zIndex: 5,
          value: this.getPlotLineValueByRetirementAge(
            this.userData.age,
            this.userData.desired_retirement_age,
          ),
        })
      }

      if (this.partnerData && this.partnerData.age > 0) {
        plotLines.push({
          color: getRootColor('retirement'),
          label: {
            text: 'Partner retires',
            rotation: 0,
            style: {
              color: '#164A9A',
              fontWeight: 'bold',
            },
            // y: partnerYPoint
            y: 20,
          },
          width: 2,
          zIndex: 5,
          value: this.getPlotLineValueByRetirementAge(
            this.partnerData.age,
            this.partnerData.desired_retirement_age,
          ), // Position, you'll have to translate this to the values on your x axis
        })
      }
      return plotLines
    },
    chartOptions() {
      return {
        chart: {
          backgroundColor: 'transparent',
          style: {
            fontFamily: 'Raleway',
          },
        },

        credits: {
          enabled: false,
        },

        exporting: {
          enabled: false,
        },

        title: {
          text: this.title,
          align: 'left',
          style: {
            fontWeight: 500,
          },
        },

        subtitle: {
          text: '',
        },

        yAxis: {
          title: {
            text: 'Balance',
            style: {
              color: '#164A9A',
            },
          },
          labels: {
            formatter: function () {
              const negative = this.value < 0
              let value = this.value
              if (negative) {
                value *= -1
              }
              value = abbrNum(value)
              if (negative) {
                value = `-${value}`
              }
              return value
            },
          },
        },
        xAxis: {
          title: {
            text: 'Your age',
            style: {
              color: '#164A9A',
            },
          },
          type: 'datetime',
          categories: this.getXAxisLabels(),
          plotLines: this.getPlotLines,
          labels: {
            enabled: true,
          },
        },

        legend: {
          enabled: this.renderLegend,
          useHTML: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          className: 'b2',
          symbolPadding: 0,
          symbolWidth: 0,
          symbolHeight: 0,
          squareSymbol: false,
          itemMarginTop: 10,
          itemDistance: 40,
          itemHoverStyle: {
            color: '#164A9A',
          },
          maxHeight: '2000px',
          itemStyle: {
            border: '#164A9A solid',
            backgroundColor: '#E5ECF9',
            borderRadius: '6px',
            boxShadow: '0 0 0 2px #EEEBEB',
            color: '#333',
          },
          itemHiddenStyle: {
            border: '#BEBEBE solid',
            backgroundColor: 'white',
            boxShadow: '0 0 0 2px #FFF',
            color: '#333',
          },
          // itemStyle: { color: '#164A9A', fontWeight: 'bold' }
          // itemHiddenStyle: { color: '#333' }
        },

        tooltip: {
          hideDelay: 1,
          outside: true,
          formatter: function () {
            return this.point.tooltip
          },
        },

        plotOptions: {
          line: {
            events: {
              mouseOut: (e) => {
                const tooltipElement = e.target.chart.tooltip.label?.element
                if (tooltipElement) tooltipElement.classList.add('hidden')
              },
              mouseOver: (e) => {
                if (e.target.chart.tooltip.label) {
                  const tooltipElement = e.target.chart.tooltip.label?.element
                  if (tooltipElement.classList.contains('hidden')) {
                    e.target.chart.tooltip.label.element?.classList.remove('hidden')
                  }
                }
              },
              legendItemClick: (e) => {
                // get style from selected Legend Button
                const style = e.target.legendItem.styles
                const newStyle = { ...style }

                const activeObj = {
                  border: '#164A9A solid',
                  backgroundColor: '#E5ECF9',
                  boxShadow: '0 0 0 2px #EEEBEB',
                  color: '#333',
                }
                const inactiveObj = {
                  border: '#BEBEBE solid',
                  backgroundColor: 'white',
                  boxShadow: '0 0 0 2px #FFF',
                  color: '#333',
                }
                const isActive = style.backgroundColor === '#E5ECF9'
                const updateStyleObject = isActive ? inactiveObj : activeObj
                const compareKeys = Object.keys(updateStyleObject)

                compareKeys.forEach((key) => {
                  newStyle[key] = updateStyleObject[key]
                })
                // set new style to Legend Button
                e.target.legendItem.css(newStyle)
              },
            },
            marker: {
              enabled: false,
            },
          },
        },

        series: this.getSeries,
        responsive: {
          rules: [
            {
              condition: {
                // maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                },
              },
            },
          ],
        },
      }
    },
  },

  methods: {
    getXAxisLabels() {
      const labels = []
      let counter = 0
      while (counter <= this.years) {
        labels.push(this.userData.age + counter)
        ++counter
      }

      return labels
    },

    getPlotLineValueByRetirementAge(age, retirementAge) {
      if (age && retirementAge) {
        const date = new Date()
        date.setFullYear(date.getFullYear() - age)
        date.setFullYear(date.getFullYear() + retirementAge)
        return date.getFullYear() - new Date().getFullYear()
      }
      return null
    },
    formatHTML(title, colour) {
      return `<div style="padding: 12px 20px; text-align: center; min-width: 240px"/> ${this.getSVGTick(
        colour,
      )} <div style="display:inline; margin-left: 4px;">${title}</div></div>`
    },
    getSVGTick(colour) {
      let svg =
        '<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: inline;">'
      svg += `<path d="M12 6.13843C12 9.45092 9.31371 12.1362 6 12.1362C2.68629 12.1362 0 9.45092 0 6.13843C0 2.82593 2.68629 0.140625 6 0.140625C9.31371 0.140625 12 2.82593 12 6.13843Z" fill="${colour}" />`
      svg +=
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 4.11021C9.82322 4.403 9.82322 4.8777 9.53033 5.17049L5.325 9.37428L3.21967 7.26972C2.92678 6.97693 2.92678 6.50223 3.21967 6.20945C3.51256 5.91666 3.98744 5.91666 4.28033 6.20945L5.325 7.25373L8.46967 4.11021C8.76256 3.81743 9.23744 3.81743 9.53033 4.11021Z" fill="white"/>'
      svg += '</svg>'
      return svg
    },
  },
}
</script>

<style scoped></style>
