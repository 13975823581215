import { RouteRecordRaw } from 'vue-router'
import CallcentrePortalOverview from '@/components/CallcentrePortal/CallcentrePortalOverview.vue'
import CallcentrePortalEngagement from '@/components/CallcentrePortal/CallcentrePortalEngagement.vue'
import { ISessionStore } from '@/store/pinia/SessionStore.ts'
import { IUserStore } from '@/store/pinia/UserStore.ts'
import { IAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import UserInfoForm from '@/components/AdminPortal/UserInfoForm.vue'
import AdminPortalLayout from '@/app/layouts/AdminPortalLayout.vue'
import SearchForm from '@/components/AdminPortal/SearchForm.vue'
import { adviceRouteGuard } from '@/composables/adviceRouteGuard.ts'
import AdminUserView from '@/features/admin-portal/views/AdminUserView.vue'
import ReportingView from '@/features/admin-portal/views/ReportingView.vue'
import { otivoAdminGuard } from '@/router/clients/cfs/cfsRouteGuards.ts'
import {
  ExclusionRules,
  useWhitelabelExclusionRules,
} from '@/composables/useWhitelabelExclusionRules.ts'
import CallcentrePortalWealthHealthReport from '@/components/CallcentrePortal/CallcentrePortalWealthHealthReport.vue'

export default (
  sessionStore: ISessionStore,
  userStore: IUserStore,
  accountFlagsStore: IAccountFlagsStore,
): Array<RouteRecordRaw> => {
  const { hasExclusionRule } = useWhitelabelExclusionRules()
  const enableDebtRoutes = !hasExclusionRule(ExclusionRules.DEBT_MODULE)

  const baseRoutes: Array<RouteRecordRaw> = [
    {
      path: '/admin-portal',
      redirect: '/admin-portal/search-users',
      component: AdminPortalLayout,
      beforeEnter: (to, from, next) => otivoAdminGuard(to, from, next, userStore, sessionStore),
      meta: {
        isAdminPortal: true,
      },
      children: [
        {
          path: 'home',
          name: 'cfsHome',
          component: AdminUserView,
        },
        {
          path: 'search-users',
          name: 'SearchForm',
          component: SearchForm,
        },
        {
          path: 'reporting',
          name: 'portalReporting',
          redirect: 'reporting/overview',
          component: () => ReportingView,
          children: [
            {
              path: 'overview',
              name: 'portalReportingOverview',
              component: () => CallcentrePortalOverview,
              meta: {
                title: 'Reporting overview',
              },
            },
            {
              path: 'engagement',
              name: 'portalReportingEngagement',
              component: () => CallcentrePortalEngagement,
              meta: {
                title: 'Member engagement',
              },
            },
            {
              path: 'wealth-health-check',
              name: 'portalReportingWealthHealthCheck',
              component: () => CallcentrePortalWealthHealthReport,
              meta: {
                title: 'Wealth health check',
              },
            },
          ],
        },
        // Manage User Routes
        {
          path: 'manage-user',
          name: 'manageUser',
          component: UserInfoForm,
        },
        {
          path: 'account-status',
          name: 'AccountStatus',
          // component: AccountStatus,
          components: {
            default: () => import('@/components/AdminPortal/AccountStatus.vue'),
            sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
          },
        },
        {
          path: 'details',
          name: 'AdminClientDetails',
          components: {
            default: () => import('@/components/CFS/Guidance/UserProfile.vue'),
            sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
          },
        },
        {
          path: 'financial-summary',
          name: 'AdminClientFinancialSummary',
          components: {
            default: () => import('@/components/FinancialSummary/FinancialSummary.vue'),
            sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
          },
        },
        // End Managed User Routes
        // Start Advice Routes
        {
          path: 'super-investments',
          name: 'AdminSuperInvestments',
          components: {
            default: () => import('@/components/CFS/IntrafundInvestment/SuperInvestments.vue'),
            sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
          },
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'salary-sacrifice-contributions',
          name: 'AdminSalarySacrificeContributions',
          components: {
            default: () =>
              import('@/components/CFS/SalarySacrifice/SalarySacrificeContribution.vue'),
            sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
          },
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'lump-sum-concessional-contributions',
          name: 'AdminLumpSumConcessionalContributions',
          components: {
            default: () => import('@/components/CFS/LumpSumContributions.vue'),
            sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
          },
          props: {
            default: { type: 'concessional' },
          },
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'lump-sum-non-concessional-contributions',
          name: 'AdminLumpSumNonConcessionalContributions',
          components: {
            default: () => import('@/components/CFS/LumpSumContributions.vue'),
            sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
          },
          props: {
            default: { type: 'non_concessional' },
          },
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        {
          path: 'personal-insurance',
          name: 'AdminPersonalInsurance',
          components: {
            default: () => import('@/views/Otivo/Dashboard/Insurance/InsuranceView.vue'),
            sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
          },
          beforeEnter: (to, from, next) =>
            adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
        },
        // End Advice Routes
      ],
    },
  ]

  const debtRoutes: Array<RouteRecordRaw> = [
    {
      path: 'debt-plan',
      name: 'AdminDebtOverview',
      components: {
        default: () => import('@/views/Otivo/Dashboard/Debt/DebtPlan.vue'),
        sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
      },
      beforeEnter: (to, from, next) =>
        adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
    },
    {
      path: 'debt',
      name: 'AdminDebtManagement',
      components: {
        default: () => import('@/views/Otivo/Dashboard/Debt/DebtManagementView.vue'),
        sidebar: () => import('@/features/admin-portal/components/UserDetailsSidenav.vue'),
      },
      beforeEnter: (to, from, next) =>
        adviceRouteGuard(to, from, next, userStore, accountFlagsStore, sessionStore),
      children: [
        {
          path: ':type/:id',
          name: 'AdminDebtDetails',
          component: () => import('@/views/Otivo/Dashboard/Debt/Pages/DebtDetails.vue'),
        },
        {
          path: 'overview/:type/:id',
          name: 'AdminDebtDetailsOverview',
          component: () => import('@/views/Otivo/Dashboard/Debt/Pages/DebtDetailsOverview.vue'),
        },
      ],
    },
  ]

  const routes: Array<RouteRecordRaw> = [...baseRoutes]

  if (enableDebtRoutes) {
    const adminPortalRoute = routes.find((route) => route.path === '/admin-portal')
    if (adminPortalRoute?.children) {
      adminPortalRoute.children.push(...debtRoutes)
    }
  }

  return routes
}
