<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 16.0715H16V8.16827C16.0003 7.9591 15.9573 7.75212 15.8738 7.5603C15.7904 7.36848 15.6682 7.19595 15.515 7.05352L9.015 0.472471C8.73983 0.214868 8.37697 0.0715332 8 0.0715332C7.62303 0.0715332 7.26017 0.214868 6.985 0.472471L0.485 7.05602C0.332081 7.19817 0.210096 7.37029 0.126651 7.56165C0.0432071 7.75302 9.37581e-05 7.95952 0 8.16827V16.059H5L11 16.0715Z"
      fill="#0064FF" />
    <path d="M11 16.0715H9.5H8H6.5H5V9.07153H11V16.0715Z" fill="#FF0000" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.1001 9.07151C4.1001 8.57445 4.50304 8.17151 5.0001 8.17151H11.0001C11.4972 8.17151 11.9001 8.57445 11.9001 9.07151V16.0715H10.1001V9.97151H5.9001V16.0715H4.1001V9.07151Z"
      fill="white" />
  </svg>
</template>

<script setup lang="ts">
interface Props {
  stroke?: string
}

withDefaults(defineProps<Props>(), {
  stroke: 'grey-3'
})
</script>
