<template>
  <Modal @close="closeModal" id="fitness-test" ref="fitnessTestModal">
    <template #body>
      <FitnessTestIntro v-if="showIntro" @reveal-questions="revealQuestions" />
      <FitnessTestQuestions v-if="showQuestions" @reveal-results="revealResults" />
    </template>
    <template #footer>
      <ShowResult
        v-if="showResults"
        :result-response="fitnessTest.resultResponse"
        @close="closeModal" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import FitnessTestQuestions from '@/components/FitnessTest/FitnessTestQuestions.vue'
import FitnessTestIntro from '@/components/FitnessTest/FitnessTestIntro.vue'
import ShowResult from '@/components/FitnessTest/ShowResult/ShowResult.vue'
import Modal from '@/components/Modals/Modal.vue'
import { useStore } from 'vuex'

const emit = defineEmits(['closeModal'])
const store = useStore()

const showIntro = ref(true)
const showQuestions = ref(false)
const showResults = ref(false)
const fitnessTest = computed(() => store.state.fitnessTest)
const fitnessTestModal = ref<HTMLElement | null>(null)

const revealQuestions = () => {
  showIntro.value = false
  showQuestions.value = true
}

const revealResults = () => {
  showQuestions.value = false
  showResults.value = true
  fitnessTestModal.value.scrollTop = 0
}

const closeModal = () => {
  emit('closeModal')
}
</script>
