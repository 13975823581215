<template>
  <Collapse
    v-if="cashInvestments.length"
    border-class="border border-blue-4"
    class="shadow-none !p-10"
    :class="{
      'bg-blue-6': !disabled,
      'bg-grey-5': disabled,
    }"
    :default-open="!!totalSavingAmount"
    arrow-fill="otivo-blue">
    <template #heading>
      <h5 class="text-blue-1 !font-bold">Looking for another way to pay down your debt faster?</h5>
    </template>
    <template #body>
      <p class="paragraph-1">
        <template v-if="actionedPayment">
          You now have a total of
          <span class="button-1 text-blue-1">{{ formatAsCurrency(totalSavingAmount) }}</span> in
          savings. You have paid {{ formatAsCurrency(totalPayDownAmount) }} off
          {{ liability?.name }}. You now have {{ formatAsCurrency(liability?.balance || 0) }} of
          your loan left to pay. Consider paying more off with your savings.
        </template>
        <template v-else>
          With
          <span class="button-1 text-blue-1">{{ formatAsCurrency(totalSavingAmount) }}</span> in
          savings, you might also consider using this to pay down your {{ liability?.name }}.
        </template>
      </p>

      <section class="mt-10">
        <table class="w-full mb-10 table-auto">
          <thead>
            <tr class="text-left">
              <th class="breadcrumb uppercase md:normal-case border-b border-b-blue-4 py-2">
                Account name
              </th>
              <th class="breadcrumb uppercase md:normal-case border-b border-b-blue-4 py-2">
                Balance
              </th>
              <th
                class="breadcrumb uppercase md:normal-case border-b border-b-blue-4 py-2 w-28 md:w-[160px]">
                Pay down amount
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(investment, index) in cashInvestments" :key="index">
              <tr>
                <td class="paragraph-1 pt-2.5">
                  {{ investment.name }}
                </td>
                <td class="button-1 text-blue-1 pt-2.5">
                  {{ formatAsCurrency(investment.value) }}
                </td>
                <td class="pt-2.5">
                  <BaseInput
                    @update:value="handlePaydownInput($event?.toString(), investment.id)"
                    name="paydowns"
                    :disabled="disabled"
                    placeholder="0"
                    type="currency"
                    value=""
                    :soft-error="error">
                    <template #append v-if="actionedPayment">
                      <CheckIcon class="w-4" fill1="green-3" />
                    </template>
                  </BaseInput>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <OtivoButton
          :loading="loading"
          :disabled="disabled"
          class="w-full md:w-fit h-12.5"
          colour="white"
          @click="handlePaymentActioned">
          <span class="button-1 text-otivo-blue">{{
            actionedPayment ? 'Pay more down' : 'I have made this payment'
          }}</span>
        </OtivoButton>

        <p class="button-2 mt-5 text-green-3" v-if="actionedPayment">
          <CircleCheck
            class="inline mb-1 fill-green-3 mr-1 w-6"
            ring="transparent"
            background="green-3"
            tick="white" />
          Your amounts have been updated
        </p>

        <p class="mt-5 paragraph-3 text-grey-3">
          Please note that this payment can only be actioned manually from your bank account. Once
          you have made this payment, enter the amount and let us know when the payment has been
          made by clicking ‘I have made this payment’ and your loan details will update.
        </p>
      </section>
    </template>
  </Collapse>
</template>

<script setup lang="ts">
import { computed, ComputedRef, defineAsyncComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import Collapse from '@/components/SaveAndCollapse/Collapse.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import formatAsCurrency from '@/composables/formatAsCurrency.ts'
import CircleCheck from '@/components/icons/CircleCheck.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import { useInvestmentStore } from '@/store/pinia/InvestmentStore.ts'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { Liability } from '@/types/Liability.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import { useConfetti } from '@/composables/useConfetti.ts'
import { convertToKebabCase } from '@/utilities.ts'
import LiabilitiesService from '@/services/v3/LiabilitiesService.ts'

type PayDownAmount = {
  investmentId: number
  amount: number
}

type Props = {
  disabled: boolean
}
withDefaults(defineProps<Props>(), {
  disabled: false,
})

const route = useRoute()
const router = useRouter()
const liabilityStore = useLiabilityStore()
const investmentStore = useInvestmentStore()
const modalStore = useModalStore()
const confetti = useConfetti()

const payDownAmounts = ref<Array<PayDownAmount>>([])
const actionedPayment = ref(false)
const loading = ref(false)
const error = ref('')
onMounted(
  async () => await investmentStore.fetchInvestments({ search: 'investment_type:cashAndFixed' }),
)

const liability = computed(() => liabilityStore.liability) as ComputedRef<Liability>
const cashInvestments = computed(() => investmentStore.investments)
const totalSavingAmount = computed(() =>
  cashInvestments.value.reduce((acc, investment) => acc + investment.value, 0),
)
const totalPayDownAmount = computed(() =>
  payDownAmounts.value.reduce((acc, payDown) => acc + payDown.amount, 0),
)

const handlePaydownInput = (value: string, investmentId: number) => {
  actionedPayment.value = false
  const foundIndex = payDownAmounts.value.findIndex(
    (payDown) => payDown.investmentId == investmentId,
  )
  if (foundIndex == -1) {
    payDownAmounts.value.push({ investmentId, amount: +value })
    return
  }
  payDownAmounts.value[foundIndex].amount = +value
}

const handlePaymentActioned = () => {
  if (!validate()) return

  if (totalPayDownAmount.value == liability.value?.balance) {
    modalStore.openModal(
      defineAsyncComponent(() => import('@/views/Otivo/Dashboard/Debt/Modals/HaveYouPaidOff.vue')),
      {
        liability: liability.value,
        confirmPayingOff: () => {
          updateInvestments().then(() => {
            confetti.randomise()
            router.push({
              name: route.meta.isAdminPortal ? 'AdminDebtDetails' : 'DebtDetails',
              params: { id: liability.value?.id, type: convertToKebabCase(liability.value?.type) },
            })
            liabilityStore.fetchDebtRecommendation()

            /** Congratulations on paying off debt */
            modalStore.openModal(
              defineAsyncComponent(
                () => import('@/views/Otivo/Dashboard/Debt/Modals/CongratulationsOnPayingOff.vue'),
              ),
              {
                liability: liability.value,
                actionRemove: () => {
                  liabilityStore.removeLiability(liability.value.id).then(() => {
                    liabilityStore.fetchDebtRecommendation()
                    modalStore.closeModal()
                    router.replace({ name: 'debtPlan' })
                  })
                },
                close: () => {
                  modalStore.closeModal()
                },
                loading /** position of loading is VERY important */,
              },
            )
          })
        },
        loading /** position of loading is VERY important */,
      },
    )
    return
  }
  updateInvestments()
}

const validate = () => {
  error.value = ''
  if (!payDownAmounts.value.length) {
    error.value = 'Please enter a pay down amount.'
    return false
  }

  if (payDownAmounts.value.some((payDown) => payDown.amount <= 0)) {
    error.value = 'Please enter a valid pay down amount.'
    return false
  }

  if (payDownAmounts.value.some((payDown) => payDown.amount > totalSavingAmount.value)) {
    error.value = 'You cannot pay more than your savings.'
    return false
  }

  if (liability.value?.balance && totalPayDownAmount.value > liability.value.balance) {
    error.value = 'You cannot pay more than your loan balance.'
    return false
  }
  return true
}

const updateInvestments = async () => {
  const currentInvestmentsStates = []
  const newInvestmentsStates = []

  for (const index in payDownAmounts.value) {
    const found = cashInvestments.value.find(
      (investment) => investment.id == payDownAmounts.value[index].investmentId,
    )

    if (found) {
      currentInvestmentsStates.push({
        investment_id: payDownAmounts.value[index].investmentId,
        balance: found.value,
      })

      const remainingBalance = found.value
        ? found.value - Math.abs(payDownAmounts.value[index].amount)
        : 0
      newInvestmentsStates.push({
        investment_id: payDownAmounts.value[index].investmentId,
        balance: remainingBalance,
      })
    }
  }

  try {
    if (!liability.value?.id) {
      return
    }
    loading.value = true
    await LiabilitiesService.setActionPayDownFaster(liability.value.id, {
      previous: currentInvestmentsStates,
      new: newInvestmentsStates,
    })

    liabilityStore
      .updateLiability({
        id: liability.value.id,
        name: liability.value.name,
        type: liability.value.type,
        balance:
          liability.value.balance !== null
            ? liability.value.balance - totalPayDownAmount.value
            : null,
        interest_rate: liability.value.interest_rate,
        payment_frequency: liability.value.payment_frequency,
        repayment_amount: liability.value.repayment_amount,
        min_payment: liability.value.min_payment,
        term: liability.value.term,
      })
      .then(async () => {
        await Promise.all([
          liabilityStore.fetchLiability(liability.value.id),
          liabilityStore.fetchDebtRecommendation(),
        ])
        emits('updated')
      })
    await investmentStore.fetchInvestments({ search: 'investment_type:cashAndFixed' })
    actionedPayment.value = true
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}
const emits = defineEmits(['updated'])
</script>

<style scoped></style>
