// doing this via log to match backend computation - the loanRepayment compute function does this as well
/**
 * Compute the total interest paid on a loan
 * @param payment
 * @param periodRate - effective interest rate per period frequency
 * @param balance
 */
export default (payment: number, periodRate: number, balance: number): number => {
  const term = Math.ceil(-Math.log(1 - periodRate * (balance / payment)) / Math.log(1 + periodRate))
  const totalPaid = term * payment
  if (payment >= balance) return 0
  return totalPaid - balance
}
