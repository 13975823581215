<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_800_657145)">
      <path
        d="M8 16.0715C0 16.0715 0 14.6315 0 14.019C0 12.8615 1.3175 12.334 4.8575 12.074C4.9887 12.0642 5.12056 12.0803 5.24552 12.1214C5.37048 12.1626 5.48609 12.228 5.58572 12.314C5.68535 12.3999 5.76705 12.5046 5.82613 12.6222C5.88521 12.7397 5.9205 12.8678 5.93 12.999C5.9625 13.434 10.04 13.434 10.07 12.999C10.0792 12.8676 10.1143 12.7393 10.1732 12.6214C10.2321 12.5036 10.3138 12.3985 10.4134 12.3123C10.5131 12.2261 10.6288 12.1605 10.7539 12.1192C10.8791 12.0779 11.0111 12.0617 11.1425 12.0715C14.6825 12.334 16 12.8615 16 14.019C16 14.6315 16 16.0715 8 16.0715Z"
        fill="#FF0000" />
      <path
        d="M13.9375 6.02903H13.5V5.07153C13.5 4.80632 13.3946 4.55196 13.2071 4.36443C13.0196 4.17689 12.7652 4.07153 12.5 4.07153H3.5C3.23478 4.07153 2.98043 4.17689 2.79289 4.36443C2.60536 4.55196 2.5 4.80632 2.5 5.07153V9.88653C2.5 9.93153 2.5625 14.3215 8.0325 14.3215C9.7975 14.3215 11.1875 13.8215 12.1625 12.8215C12.7491 12.2155 13.1611 11.4623 13.355 10.6415H13.9375C14.4467 10.6402 14.9347 10.4372 15.2946 10.0768C15.6544 9.71651 15.8568 9.22828 15.8575 8.71903V7.95153C15.8568 7.44229 15.6544 6.95406 15.2946 6.59373C14.9347 6.23341 14.4467 6.03036 13.9375 6.02903ZM11.5 9.59403C11.5 10.049 11.3275 12.3215 8.0325 12.3215C4.7375 12.3215 4.5 10.284 4.5 9.88653V6.07153H11.5V9.59403ZM13.8575 8.64653H13.5V8.02903H13.8575V8.64653Z"
        fill="#0064FF" />
      <path
        d="M11.5 9.59403C11.5 10.049 11.3275 12.3215 8.0325 12.3215C4.7375 12.3215 4.5 10.284 4.5 9.88653V6.07153H11.5V9.59403Z"
        fill="#0064FF" />
      <path
        d="M4.93262 3.57153C4.6674 3.57153 4.41305 3.46618 4.22551 3.27864C4.03797 3.0911 3.93262 2.83675 3.93262 2.57153V1.07153C3.93262 0.806317 4.03797 0.551963 4.22551 0.364427C4.41305 0.17689 4.6674 0.0715332 4.93262 0.0715332C5.19783 0.0715332 5.45219 0.17689 5.63972 0.364427C5.82726 0.551963 5.93262 0.806317 5.93262 1.07153V2.57153C5.93262 2.83675 5.82726 3.0911 5.63972 3.27864C5.45219 3.46618 5.19783 3.57153 4.93262 3.57153Z"
        fill="#FF0000" />
      <path
        d="M7.90479 3.57153C7.63957 3.57153 7.38521 3.46618 7.19768 3.27864C7.01014 3.0911 6.90479 2.83675 6.90479 2.57153V1.07153C6.90479 0.806317 7.01014 0.551963 7.19768 0.364427C7.38521 0.17689 7.63957 0.0715332 7.90479 0.0715332C8.17 0.0715332 8.42436 0.17689 8.61189 0.364427C8.79943 0.551963 8.90479 0.806317 8.90479 1.07153V2.57153C8.90479 2.83675 8.79943 3.0911 8.61189 3.27864C8.42436 3.46618 8.17 3.57153 7.90479 3.57153Z"
        fill="#FF0000" />
      <path
        d="M11.0674 3.57153C10.8022 3.57153 10.5478 3.46618 10.3603 3.27864C10.1727 3.0911 10.0674 2.83675 10.0674 2.57153V1.07153C10.0674 0.806317 10.1727 0.551963 10.3603 0.364427C10.5478 0.17689 10.8022 0.0715332 11.0674 0.0715332C11.3326 0.0715332 11.587 0.17689 11.7745 0.364427C11.962 0.551963 12.0674 0.806317 12.0674 1.07153V2.57153C12.0674 2.70286 12.0415 2.83289 11.9913 2.95422C11.941 3.07554 11.8673 3.18578 11.7745 3.27864C11.6816 3.3715 11.5714 3.44516 11.4501 3.49541C11.3287 3.54567 11.1987 3.57153 11.0674 3.57153Z"
        fill="#FF0000" />
    </g>
    <defs>
      <clipPath id="clip0_800_657145">
        <rect width="16" height="16" fill="white" transform="translate(0 0.0715332)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
