<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="8.07153" width="12" height="4" fill="#0064FF" />
    <path
      d="M10.5824 11.8466H5.29736C5.03215 11.8466 4.77779 11.9519 4.59026 12.1395C4.40272 12.327 4.29736 12.5813 4.29736 12.8466C4.29736 13.1118 4.40272 13.3661 4.59026 13.5537C4.77779 13.7412 5.03215 13.8466 5.29736 13.8466H10.5824C10.8476 13.8466 11.1019 13.7412 11.2895 13.5537C11.477 13.3661 11.5824 13.1118 11.5824 12.8466C11.5824 12.5813 11.477 12.327 11.2895 12.1395C11.1019 11.9519 10.8476 11.8466 10.5824 11.8466Z"
      fill="#0064FF" />
    <path
      d="M15.9998 7.80403C16.0021 7.76824 16.0021 7.73233 15.9998 7.69653C15.9953 7.66528 15.9886 7.63437 15.9798 7.60403C15.9798 7.57403 15.9623 7.54153 15.9523 7.51153C15.9423 7.48153 15.9248 7.44903 15.9123 7.41903L15.8673 7.33903C15.8473 7.30653 15.8273 7.27903 15.8048 7.24903C15.7823 7.21903 15.7848 7.21903 15.7723 7.20403L11.3398 2.11403C11.2466 2.00645 11.1314 1.92002 11.0021 1.86053C10.8727 1.80103 10.7322 1.76984 10.5898 1.76903H2.99982C2.56482 1.76903 2.22982 1.68403 2.10232 1.54153C2.03488 1.44731 2.00381 1.33188 2.01482 1.21653V1.24153H1.99982C2.00503 1.18499 2.00503 1.12808 1.99982 1.07153C1.99982 0.806317 1.89446 0.551963 1.70693 0.364427C1.51939 0.17689 1.26504 0.0715332 0.999821 0.0715332C0.734605 0.0715332 0.480251 0.17689 0.292715 0.364427C0.105178 0.551963 -0.000178784 0.806317 -0.000178784 1.07153C-0.000178784 1.08903 -0.000178784 1.10403 -0.000178784 1.11903C-0.0396115 1.64468 0.0969441 2.1685 0.387978 2.60799C0.679011 3.04748 1.10798 3.37767 1.60732 3.54653L0.0798211 7.49903C0.0720102 7.52118 0.0653349 7.54371 0.0598212 7.56653C0.0598212 7.60403 0.0373214 7.63903 0.0298214 7.67653C0.0223214 7.71403 0.0298214 7.75153 0.0298214 7.78903C0.0298214 7.82653 0.0298214 7.83653 0.0298214 7.85903V13.0565C0.0298214 13.3218 0.135178 13.5761 0.322714 13.7636C0.510251 13.9512 0.764605 14.0565 1.02982 14.0565C1.29504 14.0565 1.54939 13.9512 1.73693 13.7636C1.92446 13.5761 2.02982 13.3218 2.02982 13.0565V8.85903H13.9998V12.8415C13.9998 13.1068 14.1052 13.3611 14.2927 13.5486C14.4803 13.7362 14.7346 13.8415 14.9998 13.8415C15.265 13.8415 15.5194 13.7362 15.7069 13.5486C15.8945 13.3611 15.9998 13.1068 15.9998 12.8415V7.85903C15.9998 7.84153 15.9998 7.82153 15.9998 7.80403ZM12.8098 6.85903H8.59732V3.76903H10.1273L12.8098 6.85903ZM6.59732 3.76903V6.85903H2.47232L3.67232 3.76903H6.59732Z"
      fill="#0064FF" />
    <path
      d="M3 16.0715C2.40666 16.0715 1.82664 15.8956 1.33329 15.5659C0.839943 15.2363 0.455426 14.7678 0.228363 14.2196C0.00129986 13.6714 -0.0581102 13.0682 0.0576455 12.4863C0.173401 11.9043 0.459123 11.3698 0.878681 10.9502C1.29824 10.5307 1.83279 10.2449 2.41473 10.1292C2.99667 10.0134 3.59987 10.0728 4.14805 10.2999C4.69623 10.527 5.16477 10.9115 5.49441 11.4048C5.82405 11.8982 6 12.4782 6 13.0715C6 13.8672 5.68393 14.6302 5.12132 15.1929C4.55871 15.7555 3.79565 16.0715 3 16.0715ZM3 12.0715C2.80222 12.0715 2.60888 12.1302 2.44443 12.2401C2.27998 12.3499 2.15181 12.5061 2.07612 12.6889C2.00043 12.8716 1.98063 13.0726 2.01922 13.2666C2.0578 13.4606 2.15304 13.6388 2.29289 13.7786C2.43275 13.9185 2.61093 14.0137 2.80491 14.0523C2.99889 14.0909 3.19996 14.0711 3.38268 13.9954C3.56541 13.9197 3.72159 13.7916 3.83147 13.6271C3.94135 13.4627 4 13.2693 4 13.0715C4 12.8063 3.89464 12.552 3.70711 12.3644C3.51957 12.1769 3.26522 12.0715 3 12.0715Z"
      fill="#FF0000" />
    <path
      d="M13 16.0715C12.4067 16.0715 11.8266 15.8956 11.3333 15.5659C10.8399 15.2363 10.4554 14.7678 10.2284 14.2196C10.0013 13.6714 9.94189 13.0682 10.0576 12.4863C10.1734 11.9043 10.4591 11.3698 10.8787 10.9502C11.2982 10.5307 11.8328 10.2449 12.4147 10.1292C12.9967 10.0134 13.5999 10.0728 14.1481 10.2999C14.6962 10.527 15.1648 10.9115 15.4944 11.4048C15.8241 11.8982 16 12.4782 16 13.0715C16 13.8672 15.6839 14.6302 15.1213 15.1929C14.5587 15.7555 13.7957 16.0715 13 16.0715ZM13 12.0715C12.8022 12.0715 12.6089 12.1302 12.4444 12.2401C12.28 12.3499 12.1518 12.5061 12.0761 12.6889C12.0004 12.8716 11.9806 13.0726 12.0192 13.2666C12.0578 13.4606 12.153 13.6388 12.2929 13.7786C12.4327 13.9185 12.6109 14.0137 12.8049 14.0523C12.9989 14.0909 13.2 14.0711 13.3827 13.9954C13.5654 13.9197 13.7216 13.7916 13.8315 13.6271C13.9414 13.4627 14 13.2693 14 13.0715C14 12.8063 13.8946 12.552 13.7071 12.3644C13.5196 12.1769 13.2652 12.0715 13 12.0715Z"
      fill="#FF0000" />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
