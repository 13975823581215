import { Liability } from '@/types/Liability.ts'

export default function (debtType): Liability {
  return {
    id: null,
    name: null,
    owner: 'single',
    type: debtType,
    is_investment_property: debtType === 'investment',
    balance: 0,
    offset_balance: null,
    paid_monthly: 0,
    interest_rate: null,
    min_payment: null,
    term: null,
    payment_frequency: 'monthly',
    fixed_term_period: null,
    commit: 0,
    repayment_amount: null,
    interest_only: false,
    variable_rate: true,
    completed: false,
    source: 'user',
    data_source: 'user',
  }
}
