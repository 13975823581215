import getApiInstance from '@/services/Api'
import { Liability } from '@/types/Liability'
import { DebtRecommendation } from '@/types/Debt/DebtRecommendationType.ts'

const api = getApiInstance()
export default {
  getAllLiabilities() {
    return api.Get<Array<Liability>>('/liability')
  },
  getLiability(id: number) {
    return api.Get<Liability>(`/liability/${id}`)
  },
  deleteLiability(id: number) {
    return api.Delete(`/liability/${id}`)
  },
  updateLiability(liability: Partial<Liability>) {
    return api.Put(`/liability/${liability.id}`, liability)
  },
  createLiability(liability: Partial<Liability>) {
    return api.Post('/liability', liability)
  },
  getDebtRecommendation() {
    return api.Get(`/recommendation/debt`)
  },
  setActionRecommendation(
    debtId: number,
    delta: Pick<
      Liability,
      'repayment_amount' | 'min_payment' | 'commit' | 'balance' | 'type' | 'term'
    > & { recommendation: DebtRecommendation },
  ) {
    return api.Post(`/v3/liability/${debtId}/recommendations/action`, delta)
  },
  resetActionRecommendation(debtId: number) {
    return api.Delete(`/v3/liability/${debtId}/recommendations/action`)
  },
  setActionPayDownFaster(
    debtId: number,
    payload: {
      previous: Array<{ investment_id: number; balance: number }>
      new: Array<{ investment_id: number; balance: number }>
    },
  ) {
    return api.Post(`/v3/liability/${debtId}/pay-down-faster/action`, payload)
  },
}
