<template>
  <div>
    <div v-if="IsRecommendedPlan && recommendation">
      <CurrentDebtRecommendationHeader
        class="bg-white border-0 !p-0"
        :debt-recommendation="recommendation" />
    </div>
    <div v-else>
      <h5 class="text-blue-1 bold">Manage repayments</h5>
    </div>
    <RepaymentsFrequencyCard :repayments="repayments" />
    <!-- slider -->
    <div class="flex flex-row items-center mr-2" v-if="!liability.interest_only">
      <div class="flex flex-col w-full gap-y-[6px] mr-[20px]">
        <BaseRangeInput
          v-if="props.maxRepayment > 0"
          :disabled="disableSlider"
          :min="props.minRepayment"
          :max="props.maxRepayment"
          :recommended-value="recommendedRepayment ?? undefined"
          v-model:value="currentRepaymentInput"
          @update:value="$emit('update:repayment', $event)" />
        <p class="menu-1 text-blue-1 z-20">Min repayment</p>
        <span class="button-1 text-blue-1 z-20">{{ formatAsCurrency(minRepayment) }}</span>
      </div>
      <BaseInput
        class="w-[200px]"
        input-classes="paragraph-2 h-[40px]"
        placeholder="200"
        :disabled="disableSlider"
        type="currency"
        name="current_repayment"
        :max="props.maxRepayment"
        :enforce-min-max="true"
        v-model:value="currentRepaymentInput"
        @update:value="$emit('update:repayment', $event)" />
    </div>

    <div class="flex flex-col gap-y-5 mt-5 mb-10" v-if="!liability.interest_only">
      <p class="paragraph-1" v-if="saveInInterest > 0">
        Your current repayment amount will save you {{ formatAsCurrency(saveInInterest) }} in
        interest
      </p>

      <p class="paragraph-1">
        Just a heads up, clicking the button below will update your repayment to match the number in
        the field next to the slider.
      </p>
    </div>
    <div class="flex flex-col gap-y-5 mt-5 mb-10" v-else>
      <p class="paragraph-1">
        This is an interest only loan. Please update any details in the loan details section.
      </p>
    </div>
    <OtivoButton
      v-if="liability.module_status === 'actioned'"
      class="w-full md:w-fit"
      :loading="loading"
      @click="handleReset"
      data-test="resetAdviceBtn">
      <span>Reset advice</span>
    </OtivoButton>
    <OtivoButton
      v-else-if="!liability.interest_only"
      class="w-full md:w-fit"
      :loading="loading"
      @click="handleRepayment"
      data-test="actionThisAdviceBtn">
      <span v-if="IsRecommendedPlan">Action this advice</span>
      <span v-else>Update repayment amount</span>
    </OtivoButton>
  </div>
</template>
<script setup lang="ts">
import { computed, defineAsyncComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseRangeInput from '@/components/Inputs/BaseRangeInput.vue'
import CurrentDebtRecommendationHeader from '@/views/Otivo/Dashboard/Debt/Components/CurrentDebtRecommendationHeader.vue'
import RepaymentsFrequencyCard from './RepaymentsFrequencyCard.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import formatAsCurrency from '@/composables/formatAsCurrency.ts'
import { DebtRecommendation, Repayment } from '@/types/Debt/DebtRecommendationType.ts'
import liabilitiesService from '@/services/v3/LiabilitiesService.ts'
import { useDebtFormLogic } from '@/composables/useDebtFormLogic.ts'
import { useCloned } from '@vueuse/core'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'

const liabilityStore = useLiabilityStore()
const modalStore = useModalStore()
const moduleStatusesStore = useModuleStatusesStore()
const route = useRoute()
const router = useRouter()

type Props = {
  saveInInterest: number
  minRepayment: number
  maxRepayment: number
  currentRepayment: number
  recommendedRepayment?: number
  repayments: Array<Repayment>
}

const props = withDefaults(defineProps<Props>(), {
  saveInInterest: 0,
  minRepayment: 0,
  maxRepayment: 100,
  currentRepayment: 0,
  recommendedRepayment: undefined,
})

defineEmits<{
  (e: 'update:repayment', value: number): void
}>()

const currentRepaymentInput = ref(props.currentRepayment)
const loading = ref(false)
const recommendation = computed(() => liabilityStore.recommendation)
const liability = computed(() => liabilityStore.liability)
const IsRecommendedPlan = computed(
  () => recommendation.value?.debt?.plan_id?.toString() == route.params.id,
)
const disableSlider = computed(() => liability.value.module_status === 'actioned')

const handleRepayment = () => {
  if (!recommendation.value?.debt?.plan_id) {
    modalStore.openModal(
      defineAsyncComponent(
        () => import('@/views/Otivo/Dashboard/Debt/Modals/HowToUpdateRepayment.vue'),
      ),
      {
        onUpdate: () => handleUpdateRepayment(),
        loading: loading,
      },
    )
    return
  }

  if (!IsRecommendedPlan.value) {
    modalStore.openModal(
      defineAsyncComponent(
        () => import('@/views/Otivo/Dashboard/Debt/Modals/BeforeYouUpdateCurrentRepayment.vue'),
      ),
      {
        recommendation: recommendation.value,
        onContinue: () => {
          modalStore.openModal(
            defineAsyncComponent(
              () => import('@/views/Otivo/Dashboard/Debt/Modals/HowToUpdateRepayment.vue'),
            ),
            {
              recommendation: recommendation.value,
              onUpdate: () => handleUpdateRepayment(),
              loading: loading,
            },
          )
        },
        onSeeRecommendation: () => {
          const recommendedLiability = liabilityStore.liabilities.find(
            (liability) => liability.id === recommendation.value?.debt?.plan_id,
          )
          if (recommendedLiability) {
            modalStore.closeModal()
            router.push({
              name: route.meta.isAdminPortal ? 'AdminDebtDetails' : 'DebtDetails',
              params: {
                id: recommendedLiability?.id,
                type: recommendedLiability?.type,
              },
            })
          }
        },
      },
    )
    return
  }

  modalStore.openModal(
    defineAsyncComponent(() => import('@/views/Otivo/Dashboard/Debt/Modals/HowToActionAdvice.vue')),
    {
      timeframe: props.repayments?.[1]?.time_to_pay_off,
      repaymentAmount: props.repayments?.[1]?.monthly_amount,
      onActioned: () => handleActioned(props.repayments?.[1]?.monthly_amount),
      loading: loading,
    },
  )
}

const { handleSave } = useDebtFormLogic(liabilityStore)
const handleActioned = (repaymentAmount: number) => {
  loading.value = true
  const { cloned } = useCloned(liability.value)
  liability.value.repayment_amount = repaymentAmount
  handleSave(liability.value).then(() => {
    const liabilityId = liability.value.id
    liabilitiesService
      .setActionRecommendation(liabilityId, {
        repayment_amount: cloned.value.repayment_amount,
        min_payment: cloned.value.min_payment,
        commit: cloned.value.commit,
        balance: cloned.value.balance,
        term: cloned.value.term,
        type: cloned.value.type,
        recommendation: recommendation.value as DebtRecommendation,
      })
      .then(() => {
        liabilityStore.fetchLiability(liabilityId)
        liabilityStore.fetchDebtRecommendation()
        moduleStatusesStore.fetchModuleStatuses()
        useModalStore().closeModal()
      })
      .finally(() => {
        loading.value = false
      })
  })
}

const handleUpdateRepayment = () => {
  loading.value = true
  liability.value.repayment_amount = currentRepaymentInput.value
  handleSave(liability.value)
    .then(() => {
      const liabilityId = liability.value.id
      liabilityStore.fetchLiability(liabilityId)
      liabilityStore.fetchDebtRecommendation()
      modalStore.closeModal()
    })
    .finally(() => {
      loading.value = false
    })
}

const handleReset = () => {
  loading.value = true
  liabilitiesService
    .resetActionRecommendation(liability.value.id)
    .then(() => {
      liabilityStore.fetchLiability(liability.value.id)
      liabilityStore.fetchDebtRecommendation()
      moduleStatusesStore.fetchModuleStatuses()
    })
    .finally(() => {
      loading.value = false
    })
}
</script>
