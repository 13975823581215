<template>
  <CFSView type="member" class="bg-blue-6">
    <div
      class="flex flex-col items-center justify-center min-h-100% w-full justify-self-center py-10 md:py-20">
      <form
        @submit.prevent="confirmDetails()"
        class="max-w-[375px] w-100% text-left p-[40px] bg-white rounded-lg border-[2px] border-blue-5 otivo-drop-shadow">
        <h3 class="text-blue-1 text-22px">Please sign in to access your advice</h3>
        <InputText
          :stack-label="true"
          label="Member ID"
          name="confirmation_member_id"
          :value="memberId"
          @input="
            (val) => {
              memberId = val
            }
          "
          place-holder="Member ID"
          class="w-100% m-auto mt-[26px] cfs-guidance-client-input"
          :allow-digits="true"
          :autofocus="true"
          :error="errors.memberId" />
        <InputText
          :stack-label="true"
          label="Email"
          name="confirmation_email"
          :value="email"
          @input="
            (val) => {
              email = val
            }
          "
          place-holder="name@email.com"
          class="w-100% cfs-guidance-client-input mt-[6px]"
          :allow-digits="true"
          :error="errors['email']" />

        <InputError :error="errors['generic']" class="mt-[20px] text-center" />

        <OtivoButton
          type="submit"
          size="large"
          colour="blue"
          class="rounded-circle mt-[30px] w-100% md:w-fit"
          data-test="signInBtn"
          :large-text="true"
          :loading="loading">
          Sign in
        </OtivoButton>
      </form>
    </div>
  </CFSView>
</template>

<script lang="ts" setup>
import InputText from '@/components/BasicInputs/InputText.vue'
import { ref } from 'vue'
import InputError from '@/components/BasicInputs/InputError.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import CFSView from '@/views/Clients/CFS/CFSView.vue'
import { useRouter } from 'vue-router'
import { useGuidanceClientStore } from '@/store/pinia/GuidanceClientStore'
import { useToast } from '@/composables/useToast.ts'
import { useAuth0 } from '@/lib/AuthenticatorPlugin.ts'
import { useLDFlag, FeatureList } from '@/lib/ldClient.ts'

const auth0 = useAuth0()
const { successToast } = useToast()
const router = useRouter()
const clientStore = useGuidanceClientStore()

const enableDTD = useLDFlag(FeatureList.ENABLE_DTD)

const email = ref('')
const memberId = ref('')
const errors = ref({ memberId: '', email: '', generic: '' })

const loading = ref(false)

const confirmDetails = () => {
  if (loading.value) {
    return
  }
  loading.value = true

  for (const field in errors.value) {
    errors.value[field] = ''
  }

  clientStore
    .confirmCFSMemberDetails({
      email: email.value.replace(/\s/g, ''),
      member_id: memberId.value.replace(/\s/g, ''),
    })
    .then((res) => {
      if (res) {
        successToast('We have sent you an SMS, please check your phone to proceed')
        clientStore.updateGuidanceClientField({
          field: 'email',
          value: email.value.replace(/\s/g, ''),
        })
        clientStore.updateGuidanceClientField({
          field: 'memberId',
          value: memberId.value.replace(/\s/g, ''),
        })

        const nextRouteName = enableDTD.value ? 'clientSetPassword' : 'clientSMS'
        router.push({ name: nextRouteName })
      }
    })
    .catch((err) => {
      if (err.response.status === 308) auth0.login()
      if (err.response.status !== 422 && err?.response?.data?.message) {
        errors.value.generic = err.response.data.message
      } else {
        const responseErrors = err?.response?.data?.errors
        for (const field in responseErrors) {
          if (Array.isArray(responseErrors[field])) {
            responseErrors[field] = responseErrors[field].join(' ')
          }
          errors.value[field] = responseErrors[field]
        }
      }
    })
    .finally(() => {
      loading.value = false
    })
}
</script>
