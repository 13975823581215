import { type Component, computed, defineAsyncComponent, markRaw, ref } from 'vue'
import { defineStore } from 'pinia'
import { useAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import { GenericObject } from '@/types/GlobalTypes.ts'
import getNavigationObject from '@/composables/navigationRouteObjects.ts'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import {
  ExclusionRules,
  useWhitelabelExclusionRules,
} from '@/composables/useWhitelabelExclusionRules.ts'

export const useNavBarStore = defineStore('NavBarStore', () => {
  const accountFlagsStore = useAccountFlagsStore()
  // const routes = ref<Array<SideNavAccordionsType>>([])
  const showAncillaryLinks = ref(true)
  const { isUser, isAdmin } = useCheckUser()
  const { hasExclusionRule } = useWhitelabelExclusionRules()

  const ldFlags = ref<GenericObject<boolean>>({})
  const navsOpenState = ref<GenericObject<boolean>>({})

  const routerComponents = ref<string[]>([])

  const overrideNavRoutes = (routeKey: string, showAncillaries = true): Promise<void> => {
    routes.value = getNavigationObject(routeKey)
    showAncillaryLinks.value = showAncillaries
    return new Promise((resolve) => {
      resolve()
    })
  }
  //
  // const fetchNavRoutes = () => {
  //   console.log('fetchNavRoutes')
  //   // if (userRole.value !== 'user') {
  //   //   // Both guidance members and clients
  //   //   routes.value = getGuidanceRoutes.value
  //   // }
  // }

  //Mod 2.1
  const disableSalarySacrificeContributions = computed(
    () =>
      (!accountFlagsStore.getFlag('salarySacrificeContributions', 'questions_completed') &&
        isAdmin()) ||
      ldFlags.value.disabledModule2,
  )
  //Mod 2.2
  const disableLumpSumConcessionalContributions = computed(
    () =>
      (!accountFlagsStore.getFlag('lumpSumConcessionalContributions', 'questions_completed') &&
        isAdmin()) ||
      ldFlags.value.disabledModule2,
  )
  //Mod 2.3
  const disableLumpSumNonConcessionalContributions = computed(
    () =>
      (!accountFlagsStore.getFlag('lumpSumNonConcessionalContributions', 'questions_completed') &&
        isAdmin()) ||
      ldFlags.value.disabledModule2 ||
      ldFlags.value.disabledModuleNonConcessionalContributions,
  )
  //Mod Insurance
  const disablePersonalInsurance = computed(
    () =>
      (!accountFlagsStore.getFlag('personalInsurance', 'questions_completed') && isAdmin()) ||
      ldFlags.value.disabledInsurance,
  )
  const disablePersonalInsuranceExpenses = computed(
    () =>
      !accountFlagsStore.getFlag('personalInsurance', 'questions_completed') ||
      isAdmin() ||
      ldFlags.value.disabledInsurance,
  )

  const setNavOpenState = (navHeading: string, value: boolean) => {
    navsOpenState.value[navHeading] = value
  }
  const setLDFlags = (payload: GenericObject<boolean>) => {
    ldFlags.value = payload
  }

  const setRouterComponents = (payload: string[]) => {
    routerComponents.value = payload
  }

  const clientHasNotCompletedOnboarding = computed(() => {
    return !accountFlagsStore.getFlag('genericQuestions')?.questions_completed
  })
  const disableTabsWhenClientHasNotCompletedOnboarding = computed(() => {
    return clientHasNotCompletedOnboarding.value || ldFlags.value.disabledModule2
  })

  const routes = computed(() => {
    if (routerComponents.value.includes('reporting')) {
      showAncillaryLinks.value = false
      return getNavigationObject('reporting')
    } else {
      return [
        {
          heading: 'Dashboard',
          icon: markRaw(
            defineAsyncComponent(() => import('@/components/icons/LineGraph.vue')),
          ) as Component,
          routerName: 'clientDashboard',
          hide: isAdmin(),
        },
        {
          heading: 'Debt',
          icon: markRaw(
            defineAsyncComponent(() => import('@/components/icons/BombIcon.vue')),
          ) as Component,
          open: false,
          primaryStatus: ['debtPlan'],
          content: [
            {
              label: 'Debt plan',
              routerName: 'debtPlan',
            },
          ],
          hide: hasExclusionRule(ExclusionRules.DEBT_MODULE),
        },
        {
          heading: 'Super',
          icon: markRaw(
            defineAsyncComponent(() => import('@/components/SVGS/SuperSun.vue')),
          ) as Component,
          open: false,
          primaryStatus: [
            'superInvestments',
            'salarySacrificeContributions',
            'lumpSumConcessionalContributions',
            'lumpSumNonConcessionalContributions',
          ],
          content: [
            {
              label: 'Investment options inside super',
              routerName: 'superInvestments',
            },
            {
              label: 'Contributions from your salary',
              routerName: 'salarySacrificeContributions',
              disabled: disableSalarySacrificeContributions.value,
            },
            {
              label: 'Tax deductible contributions from savings',
              routerName: 'lumpSumConcessionalContributions',
              disabled: disableLumpSumConcessionalContributions.value,
            },
            {
              label: 'Non-deductible contributions from savings',
              routerName: 'lumpSumNonConcessionalContributions',
              disabled: disableLumpSumNonConcessionalContributions.value,
            },
          ],
        },
        {
          heading: 'Insurance',
          icon: markRaw(
            defineAsyncComponent(() => import('@/components/icons/InsuranceColouredIcon.vue')),
          ) as Component,
          open: false,
          disabled: ldFlags.value.disabledInsurance,
          primaryStatus: 'personalInsurance',
          content: [
            {
              label: 'Personal insurance',
              routerName: 'personalInsurance',
              disabled: disablePersonalInsurance.value,
            },
            {
              label: 'My expenses',
              routerName: 'insuranceExpenses',
              disabled: disablePersonalInsuranceExpenses.value,
            },
          ],
        },
        {
          heading: 'Profile',
          icon: markRaw(
            defineAsyncComponent(() => import('@/components/icons/ProfileIcon.vue')),
          ) as Component,
          open: false,
          content: [
            {
              label: 'Account details',
              routerName: 'clientDetails',
            },
            {
              label: 'Account status',
              routerName: 'guidanceMemberSummary',
              hide: isUser(),
            },
            {
              label: 'Financial summary',
              routerName: 'clientFinancialSummary',
              hide: disableTabsWhenClientHasNotCompletedOnboarding.value,
            },
          ],
        },
        // Hiding it, not supposed to show yet.
        {
          heading: 'Support',
          icon: markRaw(
            defineAsyncComponent(() => import('@/components/icons/QuestionBoxIcon.vue')),
          ) as Component,
          open: false,
          hide: true || userRole.value === 'superadmin' || userRole.value === 'callcentre',
          content: [
            {
              label: 'Contact us',
              routerName: 'clientContactUs',
            },
          ],
        },
      ]
    }
  })

  return {
    showAncillaryLinks: computed(() => showAncillaryLinks.value),
    getNavRoutes: computed(() => routes.value),
    getNavsOpenState: computed(() => navsOpenState.value),
    setLDFlags,
    setNavOpenState,
    overrideNavRoutes,
    setRouterComponents,
  }
})
