import { defineStore } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'
import type { DateRange } from '@/components/PeriodDatePicker.vue'
import PeriodHelpers from '@/composables/PeriodHelpers.ts'
import CallcentreReportingService, {
  PeriodMetrics,
  ReportingPostParams,
} from '@/services/CallcentreReportingService.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { ModuleOption } from '@/components/CallcentrePortal/CallcentrePortalEngagement.vue'
import { useDebounceFn } from '@vueuse/core'

export type ReportingChartData = {
  data: number[]
  labels: string[]
}

type EngagementDoughnutEndpointData = {
  all: {
    activeMembers: number
    deactivatedMembers: number
    adviceIssued: number
  }
  superInvestments: {
    activeMembers: number
    adviceIssued: number
  }
  salarySacrifice: {
    activeMembers: number
    adviceIssued: number
  }
  deductibleContributions: {
    activeMembers: number
    adviceIssued: number
  }
  insurance: {
    activeMembers: number
    adviceIssued: number
  }
}

type OverviewMetrics = {
  totalMembers: number
  newMembers: number
  totalLogins: number
  uniqueMemberLogins: number
  membersNotConsented: number
  deactivatedMembers: number
}

type allModuleEngagementMetrics = {
  'super-investments': number[]
  'salary-sacrifice': number[]
  'deductible-contributions': number[]
  insurance: number[]
  debt: number[]
}

type moduleTypeEngagementMetrics = {
  labels: string[]
  datasets: {
    issued: number[]
    actioned: number[]
  }
}

type accountVerificationMetrics = {
  inception: number
  unverified: number
  invalid: number
  valid: number
}

export const useCallcentreReportingStore = defineStore('CallCentrePortalReportingStore', () => {
  // State
  const overviewMetrics = ref<OverviewMetrics>({
    totalMembers: 0,
    newMembers: 0,
    totalLogins: 0,
    uniqueMemberLogins: 0,
    membersNotConsented: 0,
    deactivatedMembers: 0,
  })

  const activeTab = ref<ModuleOption>({} as ModuleOption)
  const chartFrequency = ref<'daily' | 'weekly' | 'monthly'>('daily')

  const engagementMetrics = ref<{
    allModuleEngagement: allModuleEngagementMetrics
    moduleEngagement: moduleTypeEngagementMetrics
    memberEngagement: EngagementDoughnutEndpointData
  }>({
    allModuleEngagement: {} as allModuleEngagementMetrics,
    moduleEngagement: {} as moduleTypeEngagementMetrics,
    memberEngagement: {} as EngagementDoughnutEndpointData,
  })

  const memberGrowthByPeriod = ref<ReportingChartData>({
    data: [],
    labels: [],
  })

  const accumulativeMemberGrowth = ref<ReportingChartData>({
    data: [],
    labels: [],
  })

  const accountVerificationMetrics = ref<accountVerificationMetrics>({
    inception: 0,
    unverified: 0,
    invalid: 0,
    valid: 0,
  })

  const searchRange = reactive({
    start: `${PeriodHelpers.today().formatted}`,
    end: `${PeriodHelpers.today().formatted}`,
  })

  const currentWhitelabelId = ref<number | null>(null)
  const whitelabelOptions = ref([])

  // Getters
  const whitelabel_id = computed(
    () => currentWhitelabelId.value ?? useSessionStore().getWhitelabelData.id,
  )

  const start_date = computed(() => PeriodHelpers.parseDateForBackend(searchRange.start))
  const end_date = computed(() => PeriodHelpers.parseDateForBackend(searchRange.end))
  const frequency = computed(() => chartFrequency.value)

  // Watchers
  watch([whitelabel_id, start_date, end_date, frequency], () => {
    useDebounceFn(() => updateReports(), 500)()
  })

  // Actions
  const setActiveTab = (tab: ModuleOption) => {
    CallcentreReportingService.getEngagementGraphMetricsByPeriodAndType(tab.key, {
      ...getPostParams(),
      frequency: chartFrequency.value,
    })
    activeTab.value = tab
  }

  const setSearchRange = (range: DateRange) => {
    searchRange.start = range.start
    searchRange.end = range.end
    chartFrequency.value = range.frequency
    getAllReportingMetrics()
    updateModuleEngagementData(activeTab.value.key, {
      ...getPostParams(),
      frequency: range.frequency,
    })
  }

  const updateReports = () => {
    getAllReportingMetrics()
  }

  const getPostParams = (): ReportingPostParams => {
    return {
      whitelabel_id: whitelabel_id.value,
      start_date: start_date.value,
      end_date: end_date.value,
    }
  }

  const getOverviewMetrics = async () => {
    try {
      const res = await CallcentreReportingService.getOverviewMetrics(getPostParams())
      return res?.data
    } catch (e) {
      console.error('error getting overview metrics', e)
    }
  }

  const getMemberEngagement = async () => {
    try {
      const res = await CallcentreReportingService.getMemberEngagement(getPostParams())
      return res?.data
    } catch (e) {
      console.error('error getting engagement metrics', e)
    }
  }

  const getAllReportingMetrics = async () => {
    const promises = []
    const module = activeTab.value.key || 'all'
    promises.push(getOverviewMetrics())
    promises.push(getMemberGrowthByPeriod())
    promises.push(getMemberEngagement())
    promises.push(
      CallcentreReportingService.getEngagementGraphMetricsByPeriodAndType(module, {
        ...getPostParams(),
        frequency: chartFrequency.value,
      }),
    )
    try {
      const values = await Promise.all(promises)
      overviewMetrics.value = values[0]
      engagementMetrics.value.memberEngagement = values[2]
      engagementMetrics.value.allModuleEngagement = values[3].data
    } catch (e) {
      console.error('error getting all reporting metrics', e)
    }
  }

  const getMemberGrowthByPeriod = async () => {
    try {
      const growthData = await CallcentreReportingService.getMemberGrowthByPeriod({
        ...getPostParams(),
        frequency: chartFrequency.value,
      })
      memberGrowthByPeriod.value = {
        data: growthData?.data.members.new,
        labels: growthData?.data.labels,
      } as ReportingChartData

      accumulativeMemberGrowth.value = {
        data: growthData?.data.members.accumulative,
        labels: growthData?.data.labels,
      } as ReportingChartData
    } catch (e) {
      console.error('error getting member growth data', e)
    }
  }

  const getAccountVerificationMetrics = async () => {
    try {
      const sessionStore = useSessionStore()
      const params = {
        whitelabel_id: sessionStore.getWhitelabelData.id,
      }
      const verificationMetrics = await CallcentreReportingService.getVerificationMetrics(params)
      accountVerificationMetrics.value = verificationMetrics?.data
    } catch (e) {
      console.error('error getting verification metrics', e)
    }
  }

  const getWhitelabelOptions = async () => {
    try {
      const results = await CallcentreReportingService.getWhitelabelOptions()
      whitelabelOptions.value = results?.data ?? []
    } catch (e) {
      console.error('error getting whitelabel options', e)
    }
  }

  const setWhitelabelId = (id: number) => {
    currentWhitelabelId.value = id
  }

  const updateModuleEngagementData = async (
    type = 'all',
    params?: PeriodMetrics,
  ): Promise<moduleTypeEngagementMetrics> => {
    try {
      const engagementData =
        await CallcentreReportingService.getEngagementGraphMetricsByPeriodAndType(type, params)
      if (type === 'all') {
        engagementMetrics.value.allModuleEngagement =
          engagementData?.data as allModuleEngagementMetrics
      } else {
        engagementMetrics.value.moduleEngagement =
          engagementData?.data as moduleTypeEngagementMetrics
      }
      return engagementData?.data as moduleTypeEngagementMetrics
    } catch (e) {
      console.error('error getting module engagement data', e)
    }
    return {} as moduleTypeEngagementMetrics
  }

  return {
    setSearchRange,
    getReportingMetrics: getAllReportingMetrics,
    getAccountVerificationMetrics,
    getPostParams,
    updateModuleEngagementData,
    setActiveTab,
    getWhitelabelOptions,
    setWhitelabelId,
    getWhitelabelId: computed(() => currentWhitelabelId.value),
    getChartFrequency: computed(() => chartFrequency.value),
    activeTab: computed(() => activeTab.value),
    getOverviewMetrics: computed(() => overviewMetrics.value),
    getEngagementMetrics: computed(() => engagementMetrics.value),
    getVerificationMetrics: computed(() => accountVerificationMetrics.value),
    searchRange: computed(() => searchRange),
    getMemberGrowthData: computed(() => memberGrowthByPeriod.value),
    getAccumulativeGrowthData: computed(() => accumulativeMemberGrowth.value),
    whitelabelOptions: computed(() => whitelabelOptions.value),
  }
})
