import { defineStore } from 'pinia'
import { ref } from 'vue'
import { AxiosError, AxiosResponse } from 'axios'
import api from '@/services/Api.ts'

export type Product = {
  code: string
  description: string
  duration: number
  name: string
  stripe_price_id: string
  stripe_product_id: string
  subscription: 0 | 1
  monthly_price: number
  yearly_price: number
}

export const useProductStore = defineStore('product', () => {
  const product = ref<Product>()
  const loading = ref(false)
  const error = ref('')
  const productList = ref<Product[]>([])

  const fetchProductDetails = async () => {
    loading.value = true
    error.value = ''
    try {
      const response: AxiosResponse = await api().Get('/products')
      productList.value = response.data.data
      product.value = productList.value[0]
    } catch (err) {
      if (err instanceof AxiosError) {
        error.value = err.response?.data?.error || 'Failed to fetch product details'
      } else {
        error.value = 'An unexpected error occurred'
      }
    } finally {
      loading.value = false
    }
  }

  return {
    product,
    productList,
    loading,
    error,
    fetchProductDetails,
  }
})
