<template>
  <div class="hidden md:grid grid-cols-4 pl-1 mx-7 mb-2 text-menu font-bold mt-10">
    <!-- Empty column for alignment -->
    <div></div>
    <div class="uppercase">{{ liability.payment_frequency }} Amount</div>
    <div class="uppercase">Time to pay off</div>
    <div class="uppercase">Total interest</div>
  </div>
  <div
    v-for="repayment in repayments"
    :key="repayment.repayment_type"
    class="grid grid-cols-3 md:grid-cols-4 bg-white p-5 my-2 md:mx-2 rounded-sm border border-l-1 border-grey-4 items-center"
    :class="borderColor(repayment.repayment_type)">
    <div
      class="flex flex-row items-center font-bold col-span-3 md:col-span-1 pt-1 pb-5 md:p-1 space-x-[6px]">
      <div>{{ repayment.repayment_type }}</div>
      <InfoCircle
        class="self-center"
        v-if="repayment?.infoCircleMessage"
        :message="repayment?.infoCircleMessage" />
    </div>
    <div>
      <div class="uppercase md:hidden mp4">Monthly Amount</div>
      <div class="paragraph-1">{{ formatAsCurrency(repayment.monthly_amount) }}</div>
    </div>
    <div>
      <div class="uppercase md:hidden mp4">Time to pay off</div>
      <div class="paragraph-1 md:hidden">{{ shortenTimePeriod(repayment.time_to_pay_off) }}</div>
      <div class="paragraph-1 hidden md:block">{{ repayment.time_to_pay_off }}</div>
    </div>
    <div>
      <div class="uppercase md:hidden mp4">Total interest</div>
      <div class="paragraph-1">{{ formatAsCurrency(repayment.total_interest) }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import formatAsCurrency from '@/composables/formatAsCurrency.ts'
import { shortenTimePeriod } from '@/utilities.ts'
import { Repayment, RepaymentType } from '@/types/Debt/DebtRecommendationType.ts'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { computed } from 'vue'

defineProps<{
  repayments: Array<Repayment>
}>()

const liability = computed(() => useLiabilityStore().liability)

const borderColor = (repaymentType: RepaymentType) => {
  const borderClassMap = {
    'Minimum repayment': 'border-l-blue-2',
    'Current repayment': 'border-l-red-1',
    'Recommended repayment': 'border-l-otivo-red',
  }
  return borderClassMap[repaymentType]
}
</script>
