<template>
  <div id="ancillary-links">
    <div
      v-for="(heading, headingIndex) in responsiveItems as Array<ResponsiveNavType>"
      class="text-blue-1"
      :key="'heading' + headingIndex">
      <div class="flex items-center gap-[5px] menu-2 mt-[40px] p-[10px] border-b border-blue-5">
        <Component :is="heading.icon" fill1="grey-1" fill2="blue-1" height="12px" width="12px" />
        {{ heading.label }}
      </div>
      <div v-for="(item, contentIndex) in heading.content" :key="'content' + contentIndex">
        <DownloadStatementOfAdvice v-if="item.type === 'downloadSOA'" @click="emit('click')" />
        <div
          v-else-if="(item.hide === undefined || !item.hide) && item.routerName"
          class="paragraph-2 p-[10px] text-grey-2 hover:text-blue-1 border-b border-blue-5 w-full text-left cursor-pointer"
          @click="routerPush(item.routerName)">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Component } from 'vue'
import { computed, ref } from 'vue'
import DownloadIcon from '@/components/SVGS/DownloadIcon.vue'
import { useRoute, useRouter } from 'vue-router'
import DownloadStatementOfAdvice from '@/components/CFS/CFSClientConfirmationSteps/DownloadStatementOfAdvice.vue'
import NewspaperIcon from '@/components/icons/NewspaperIcon.vue'
import { ShowDownloadPDFButtonOptionsType } from '@/types/CallcentrePortalTypes.ts'

const showDownloadPDFButton = ref<ShowDownloadPDFButtonOptionsType>('inactive')
const router = useRouter()

type ResponsiveNavType = {
  icon: Component
  label: string
  hide: boolean
  content: Array<{
    label: string
    routerName?: string
    type: string
    hide?: boolean
  }>
}

const route = useRoute()
const navRoutePath = computed(() => route.path)
const emit = defineEmits(['click'])

const responsiveItems = computed(() => {
  return [
    {
      icon: DownloadIcon,
      label: 'DOWNLOAD',
      hide: showDownloadPDFButton.value !== 'inactive',
      content: [
        {
          label: 'Statement of Advice',
          type: 'downloadSOA',
        },
      ],
    },
    {
      icon: NewspaperIcon,
      label: 'RELATED READING',
      content: [
        {
          label: 'Concessional contributions guide',
          routerName: 'guidanceConcessionalContributionsGuide',
          type: 'route',
          hide: navRoutePath.value !== '/dashboard/lump-sum-concessional-contributions',
        },
        {
          label: 'How the advice works',
          routerName: 'HowAdviceWorks',
          type: 'route',
        },
      ],
    },
  ]
})

const routerPush = (routerName: string) => {
  emit('click')
  router.push({ name: routerName })
}
</script>

<style scoped></style>
