import User from '@/types/api/account/User.ts'
import { addYears } from 'date-fns'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'


export default (user: User) => {
  const whitelabel = useSessionStore().getWhitelabelData

  if (whitelabel.identifier === 'COLONIAL_FIRST_STATE') {
    const createdDate = new Date(user.created_at)
    // changed on NDs specifications, no longer requires consent to start subscription
    // const expiryDate = new Date(parseInt(user.fee_consent_expires_at))
    const expiryDate = addYears(createdDate, 1)
    return {
      accountActiveSince: createdDate,
      price: 88,
      endsAt: expiryDate
    }
  }
  if (user.subscriptions?.length > 0) {
    return user.subscriptions[0].pricePlanDetails
  }

  return {
    accountActiveSince: '',
    price: 0,
    endsAt: ''
  }
}
