<template>
  <div class="col-span-2 md:col-span-1">
    <div class="button-2 text-grey-1">
      Payment frequency <img :src="AsteriskIcon" class="inline mb-2" />
    </div>
    <div class="relative p-0">
      <TabButtonRow
        class="grid grid-cols-3 !mt-[9px] !px-0"
        :existing-value="liability.payment_frequency"
        @toggle="(e) => setPaymentFrequency(e)"
        data-test="debtPaymentFrequency"
        :tab-button-data="[
          { label: 'Weekly', value: 'weekly' },
          { label: 'Fortnightly', value: 'fortnightly' },
          { label: 'Monthly', value: 'monthly' },
        ]" />
      <p v-if="liabilityErrors?.payment_frequency" class="otivo-error-message absolute right-0">
        {{ liabilityErrors?.payment_frequency?.[0] }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import TabButtonRow from '@/components/Onboarding/TabButtonRow.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { computed } from 'vue'
import AsteriskIcon from '@/components/SVGS/AsteriskIcon.svg?url'

const liabilityStore = useLiabilityStore()
const liability = computed(() => liabilityStore.liability)
const liabilityErrors = computed(() => liabilityStore.liabilityErrors)

const emit = defineEmits<(event: 'change', message: string) => void>()

const setPaymentFrequency = (e) => {
  liability.value.payment_frequency = e.value
  liabilityErrors.value.payment_frequency = []
  emit('change', 'Payment frequency updated')
}
</script>

<style scoped></style>
