<template>
  <modal
    v-if="timeoutModalActive"
    :can-dismiss="false"
    container-styles="p-5 md:p-10 rounded-[20px] otivo-drop-shadow relative">
    <template #header>
      <h3 class="mh3 md:h3 my-4">Hello? Is anyone there?</h3>
    </template>
    <template #body>
      <p class="intro1 my-4">
        We noticed you haven't been active for a while, so we've just paused your session. To keep
        on going, please log into your account again.
      </p>
    </template>
    <template #footer>
      <div class="flex flex-col md:flex-row gap-4 md:gap-8 my-4">
        <custom-button colour="blue" class="w-full md:w-48" @click="triggerSignIn"> Sign in </custom-button>
        <custom-button colour="white" class="w-full md:w-48" @click="triggerLogout">
          Return to homepage
        </custom-button>
      </div>
    </template>
  </modal>
</template>

<script setup lang="ts">
import CustomButton from '../CustomButton/CustomButton.vue'

import Modal from '@/components/Modals/Modal.vue'
import { useSessionStore } from '@/store/pinia/SessionStore'
import { computed, onBeforeUnmount } from 'vue'
import { userRole } from '@/lib/AuthenticatorPlugin'

const sessionStore = useSessionStore()
const timeoutModalActive = computed(() => sessionStore.timeoutModalActive)
const callCentre = computed(() => sessionStore.getWhitelabelData.callcentre_portal)
const cfsAdmin = computed(
  () =>
    userRole.value === 'callcentre' ||
    userRole.value === 'superadmin' ||
    userRole.value === 'backoffice',
)
const triggerSignIn = () => {
  let url = window.location.origin
  if (callCentre.value && cfsAdmin.value) {
    url += '/cfsadmin'
  } else {
    url += '/login'
  }
  sessionStore.logout(url)
}
const triggerLogout = () => {
  let url = ''
  if (callCentre.value && cfsAdmin.value) {
    url = window.location.origin + '/cfsadmin'
  }
  sessionStore.logout(url)
}
</script>
