<template>
  <div
    class="w-full flex flex-col"
    :class="[
      renderTopBorder ? 'border-y-[1px] border-y-blue-5' : 'border-b-[1px] border-b-blue-5',
    ]">
    <div class="w-full flex items-center cursor-pointer p-[10px]" @click="open = !open">
      <component :is="icon" v-if="icon" class="w-6 h-6 p-px mr-[10px]" />
      <div class="flex w-full pr-[10px] text-blue-1 font-semibold grow whitespace-nowrap text-base">
        {{ heading }}
      </div>
      <div class="ml-auto grow-0">
        <ChevronDown
          class="transition-transform duration-350 ease-in-out"
          :class="[open ? ' rotate-180' : ' rotate-0']" />
      </div>
    </div>
    <Transition name="slide-fade">
      <div v-if="open" class="flex flex-col w-full border-t-[1px] border-t-blue-5 py-1">
        <RouterLink
          v-for="(item, index) in links"
          :key="index"
          :to="item.to"
          class="flex flex-col justify-start rounded-sm py-[10px] pl-[35px] pr-[10px] w-full font-medium text-base text-grey-1 hover:text-otivo-blue hover:bg-blue-5"
          exact-active-class="bg-blue-5 text-otivo-blue"
        >
          {{ item.label }}
          <component :is="item.extra" v-if="item.extra" class="block" />
        </RouterLink>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import ChevronDown from '@/components/icons/ChevronDown.vue'
import type { Component, VNode } from 'vue'
import { ref } from 'vue'

type ExtraType =
  | string
  | VNode // For HTML or component
  | Component // In case you want to accept a component type directly
  | (() => VNode | string); //

const props = withDefaults(
  defineProps<{
    heading: string,
    icon?: ExtraType | null,
    links: {
      to: {
        name: string
      }
      label: string,
      icon: ExtraType | null,
      extra: ExtraType | null
    }[]
    renderTopBorder: boolean
  }>(),
  {
    heading: 'Menu Heading',
    links: () => [],
    renderTopBorder: true,
    icon: null
  }
)

const open = ref(true)
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  max-height: 500px; /* Adjust as needed */
  opacity: 1;
}
</style>
