<template>
  <ul class="">
    <li v-for="(item, index) in links" class="mh6 md:text-button1" :key="index">
      <SideNavLink :to="item.to" :label="item.label" :active="item.active" />
    </li>
    <li>
      <SideNavSubMenu heading="Reporting" :links="reportingLinks" />
    </li>
    <li>
      <SideNavSubMenu heading="Settings" :links="settingLinks" :render-top-border="false" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import SideNavLink from '@/app/components/SideNavLink.vue'
import SideNavSubMenu from '@/app/components/SideNavSubMenu.vue'
import { isCFSWhitelabel } from '@/composables/isCFSWhitelabel.ts'

const links = [{ to: { name: 'SearchForm' }, label: 'Client search' }]
const reportingLinks = ref([
  { to: { name: 'portalReportingOverview' }, label: 'Overview' },
  { to: { name: 'portalReportingEngagement' }, label: 'Engagement' },
  // { to: '#client-filter', label: 'Client filter' }
])
const settingLinks = [{ to: { name: 'cfsHome' }, label: 'Admin users' }]
onMounted(() => {
  if (!isCFSWhitelabel()) {
    reportingLinks.value.push({
      to: { name: 'portalReportingWealthHealthCheck' },
      label: 'Wealth Health',
    })
  }
})
</script>

<style scoped></style>
