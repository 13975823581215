<template>
  <div id="gorgias-contact-us-form" class="md:px-6 text-left" data-test="contactUsForm">
    <div>
      <component
        is="script"
        defer
        src="https://contact.gorgias.help/api/contact-forms/loader.js?v=3"
        data-gorgias-loader-contact-form
        data-gorgias-contact-form-uid="2cma20r5"
        async>
      </component>
    </div>
  </div>
</template>
