<template>
  <BaseView>
    <div class="flex flex-col items-center justify-center min-h-100% max-w-[375px] mx-auto">
      <h5 class="bold mt-[40px]">We're sorry, but your access to Otivo has come to an end.</h5>
      <p class="mt-6 text-blue-1">Would you like to keep using Otivo for another year for FREE?</p>
      <OtivoButton class="mt-[40px] w-full" @click="consent">Yes, please</OtivoButton>
      <div
        class="my-[40px] button-1 underline text-otivo-blue hover:text-blue-1 cursor-pointer"
        @click="() => logout()">
        No thanks
      </div>
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import BaseView from '@/views/BaseView.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { logout } from '@/lib/AuthenticatorPlugin.ts'
import { useRouter } from 'vue-router'

const router = useRouter()

const consent = () => {
  router.push({ name: 'clientRenewDisclaimer' })
}
</script>

<style scoped></style>
