import convertToNumber from '@/composables/convertToNumber'

export default (value: string | number): string => {
  if (typeof value === 'number') {
    // default case
    return value.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  } else {
    // string case
    try {
      const valueAsNumber = convertToNumber(value)
      return valueAsNumber.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    } catch {
      // Return empty string for invalid number formats
      return ''
    }
  }
}
