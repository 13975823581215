<template>
  <div class="w-full bg-blue-1 text-white py-10 px-[20px]">
    <div class="max-w-[860px] mx-auto space-y-10 md:pb-10 bg-blue-1 md:text-center">
      <h3>Otivo's board</h3>
      <p class="intro-1">
        Otivo’s seasoned team brings over 200 years of combined experience to the boardroom.
      </p>
    </div>
    <div
      class="md:grid grid-cols-3 m-auto justify-center text-center gap-[30px] py-10 flex-wrap md:w-[780px]">
      <ProfileCard
        class="w-[240px] m-auto mt-[30px] md:mt-0"
        v-for="person in theBoard"
        :profile="person"
        :hover-classes="'bg-[#000032] border-otivo-blue text-white'"
        :key="person.name" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Ian from '@/components/BrandComponents/ExtraContent/Profiles/IanKnox.webp?url'
import Ross from '@/components/BrandComponents/ExtraContent/Profiles/RossBarnwell.webp?url'
import Bernie from '@/components/BrandComponents/ExtraContent/Profiles/BernieRipoll.webp?url'
import Mark from '@/components/BrandComponents/ExtraContent/Profiles/MarkStanton.webp?url'
import Kathryn from '@/components/BrandComponents/ExtraContent/Profiles/KathrynGiudes.webp?url'
import Michael from '@/components/BrandComponents/ExtraContent/Profiles/MichaelSwinsburg.webp?url'
import ProfileCard from '@/components/BrandComponents/ExtraContent/ProfileCard.vue'

const theBoard = [
  {
    image: Ian,
    name: 'Ian Knox',
    position: 'Chair + Director',
    blurb:
      'Ian has extensive board experience with Lonsec Holdings, Ford Scott Financial Planning and as founder and ex-chair of Paragem. He’s also held senior positions at Lend Lease Investment Services, Westpac Investment Management, and Sealcorp Holdings.',
  },
  {
    image: Ross,
    name: 'Ross Barnwell',
    position: 'Executive Director',
    blurb:
      'With over 25 years in executive leadership, Ross has worked with big names like MLC, NAB, ING, St George Bank, and BT Financial Group. His wealth management experience is a great asset to Otivo.',
  },
  {
    image: Bernie,
    name: 'Hon. Bernie Ripoll',
    position: 'Director',
    blurb:
      "Bernie, a former Federal MP for Oxley, chaired the FoFA legislation inquiry and was the Parliamentary Secretary to the Treasurer. He's also held several executive and board positions, bringing a wealth of experience to our team.",
  },
  {
    image: Mark,
    name: 'Mark Stanton',
    position: 'Director',
    blurb:
      'Mark brings 30 years of experience in compliance and business recovery. He’s worked as a Chartered Accountant, Director at Alliance Airlines Ltd, President of the Royal Queensland Golf Club, and a Director of Queensland Rugby Union.',
  },
  {
    image: Kathryn,
    name: 'Kathryn Giudes',
    position: 'Director',
    blurb:
      'Kathryn is Managing Director at MacroDATA Digital Solutions and chairs United H2 Limited. Her experience in business strategy, SaaS, telecom, and financial services helps guide Otivo’s strategic direction.',
  },
  {
    image: Michael,
    name: 'Michael Swinsburg',
    position: 'Director',
    blurb:
      'Michael is a well-respected executive search consultant and leadership advisor with 30 years of experience in financial services. As Managing Partner at Alexander Hughes Australia, he helps businesses find great leaders and build strong teams.',
  },
]
</script>
