<template>
  <Transition name="fade">
    <div
      v-if="showModal"
      id="modalMask"
      class="fixed top-0 left-0 size-full bg-[#00000080] z-100" />
  </Transition>
  <Transition :name="transitionName">
    <div
      v-if="showModal"
      class="fixed inset-0 flex items-center justify-center z-100 size-full"
      @click="handleCancel">
      <component
        v-if="override"
        @click.stop=""
        :is="modalStore.component"
        v-bind="modalStore.props" />
      <dialog
        v-else
        id="modalContainer"
        ref="modalContainer"
        :style="styleOverride"
        :class="size"
        data-test="popModalContainer"
        class="md:relative rounded bg-grey-5 overflow-auto overscroll-contain otivo-drop-shadow w-full h-auto max-h-85vh inset-x-0 bottom-0 md:top-0"
        open
        @click.stop>
        <button
          data-test="closeModalBtn"
          v-if="modalStore.isCloseable"
          aria-label="Close"
          class="absolute top-[12px] md:top-[16px] right-[12px] md:right-[16px] z-100 cursor-pointer"
          type="button"
          id="closeModalBtn"
          @click.prevent="handleCancel">
          <CloseXIcon :style="white ? 'color: white;' : 'color: #a0a0a0;'" />
        </button>
        <component :is="modalStore.component" v-bind="modalStore.props" />
      </dialog>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { onKeyDown } from '@vueuse/core'
import { useModalStore } from '@/store/pinia/ModalStore'
import CloseXIcon from '@/components/icons/CloseXIcon.vue'

const modalStore = useModalStore()
const showModal = computed(() => modalStore.isOpen)
const size = computed(() => modalStore.size)
const override = computed(() => modalStore.override)
const styleOverride = computed(() => modalStore.styleOverride)
const white = computed(() => modalStore.white)

const body = ref<HTMLElement | null>()
const modalContainer = ref<HTMLElement | null>(null)
const firstFocusableElement = ref<HTMLElement | null>(null)
const lastFocusableElement = ref<HTMLElement | null>(null)

onMounted(() => {
  body.value = document.getElementById('body')
})

onKeyDown('Escape', () => {
  if (modalStore.isOpen) {
    close()
  }
})

watch(
  () => modalStore.isOpen,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (!body.value) return
      if (newValue) {
        // The modal is now visible, prevent background from scrolling
        body.value.style.overflowY = 'hidden'

        // Focus the LAST focusable element inside the modal
        nextTick(() => {
          if (modalContainer.value) {
            const focusableElements = modalContainer.value.querySelectorAll(
              'a[href], button, textarea, input, select',
            ) as NodeListOf<HTMLElement>

            if (focusableElements.length > 0) {
              firstFocusableElement.value = focusableElements[0]
              lastFocusableElement.value = focusableElements[focusableElements.length - 1]
              firstFocusableElement.value.focus()
            }
          }
        })

        window.addEventListener('keydown', trapFocus)
      } else {
        // The modal is now hidden, allow background scrolling again
        body.value.style.overflowY = 'auto'
        window.removeEventListener('keydown', trapFocus)
      }
    }
  },
)

const handleCancel = () => {
  /** order is important */
  cancel()
  close()
}

const close = () => {
  if (modalStore.isCloseable) {
    modalStore.closeModal()
  }
}

const cancel = () => {
  if (modalStore.isCloseable && typeof modalStore?.cancelModal === 'function') {
    modalStore.cancelModal()
  }
}

const trapFocus = (event: KeyboardEvent) => {
  if (
    event.key === 'Tab' &&
    modalContainer.value &&
    firstFocusableElement.value &&
    lastFocusableElement.value
  ) {
    if (event.shiftKey) {
      // If the Shift key is pressed along with Tab, focus the last focusable element inside the modal
      if (document.activeElement === firstFocusableElement.value) {
        event.preventDefault()
        ;(lastFocusableElement.value as HTMLElement).focus()
      }
    } else {
      // If only Tab is pressed, focus the first focusable element inside the modal
      if (document.activeElement === lastFocusableElement.value) {
        event.preventDefault()
        ;(firstFocusableElement.value as HTMLElement).focus()
      }
    }
  }
}

// Reactive mobile detection and dynamic transition name
const isMobile = ref(window.innerWidth < 768)
const handleResize = () => {
  isMobile.value = window.innerWidth < 768
}
window.addEventListener('resize', handleResize)
onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
const transitionName = computed(() => (isMobile.value ? 'slide-up' : 'bounce'))
</script>

<style scoped lang="scss">
.small {
  max-width: 335px;
  padding: 32px 16px 16px;
  border-radius: 10px;
}

.medium {
  max-width: 600px;
  padding: 36px 40px 40px;
  border-radius: 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }
}

.large {
  max-width: 800px;
  padding: 36px 40px 40px;
  border-radius: 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }
}

.extra-large {
  max-width: 1000px;
  padding: 36px 40px 40px;
  border-radius: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#modalContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#modalContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Style the modal container on mobile */
@media (max-width: 768px) {
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-up-enter-from,
  .slide-up-leave-to {
    transform: translateY(100%);
  }
  .slide-up-enter-to,
  .slide-up-leave-from {
    transform: translateY(0);
  }
}

/* Desktop bounce transition */
.bounce-enter-active,
.bounce-leave-active {
  transition: all 0.3s ease;
}
.bounce-enter-from,
.bounce-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.bounce-enter-to,
.bounce-leave-from {
  opacity: 1;
  transform: scale(1);
}

/* Fade transition for the modal mask */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
