<template>
  <div class="py-20" data-test="testimonialSlider">
    <InfiniteSliderHorizontal :items :speed="60" hover-pause />
  </div>
</template>

<script setup lang="ts">
import { h, ref } from 'vue'
import TestimonialSlide from '@/components/BrandComponents/Otivo/TestimonialSlide.vue'
import InfiniteSliderHorizontal from '@/components/InfiniteSliderHorizontal.vue'
import { TestimonialType } from '@/types/globalComponents/TestimonialType.ts'

const testimonials = ref([
  {
    heading: 'Otivo is right up my alley, I\'ve been looking for something like this for a while.',
    subHeading: 'Having this platform will help me get started being more financially savy',
    footer: 'David'
  },
  // {
  //   heading: 'I like that kind of interactivity.',
  //   footer: 'CFS member'
  // },
  {
    heading: 'I was blown away by how smoothly everything just worked!',
    subHeading: 'It has been a genuine pleasure working with you and your help has been outstanding.',
    footer: 'Callum, Rio Tinto'

  },
  // {
  //   heading: 'Before using Otivo, I had no idea where my super should be sitting or the benefits of making voluntary contributions.',
  //   subHeading: 'The cost-of-living pressures made traditional financial advice inaccessible, leaving me in a state of uncertainty. Otivo changed all of that by providing clear, affordable guidance tailored to my situation. Now, I understand my super options and how to maximise my retirement savings. Without Otivo, I\'d still be in the dark about my financial future.',
  //   footer: 'Maiya'
  // },
  {
    heading: 'Otivo opened my eyes to the different super investment options and helped me pick one that suited my needs.',
    subHeading: 'It’s a really good market right now for Otivo, I would definitely suggest it to my friends and family who are having difficulty with their financial state.',
    footer: 'Jess'
  },
  {
    heading: 'Otivo has provided my husband and I with a more useful snapshot of our current financial position.',
    subHeading: 'Using this information, we have been able to make sound financial decisions, which in turn has helped us steadily increase our wealth.',
    footer: 'Rosie'
  },

  // {
  //   heading: 'When I started using Otivo it gave me a quick snapshot of where I am, where I can make improvements. Looking at dashboards we could be better off by $280k which is really motivating and helpful for me. We’re looking to get our next settlement for our house and Otivo is helping us with that.',
  //   footer: 'Lawrence'
  // },
  // {
  //   heading: 'Being able to have one system and put all the information in there to track has been really useful. It has been a bit of an audit of where I’m at financially. Collating into one central space. Otivo has made me more aware of my own finances and how I can better position myself financially.',
  //   footer: 'Callum'
  // },
  // {
  //   heading: 'I really like that it’s a big summary of all my finances. It’s great to see banking, finance and super. I really struggled with this before as I had manual spreadsheets which were complicated and didn’t allow me to see the bigger picture.',
  //   footer: 'Lizzie'
  // },
  {
    heading: 'I contacted CFS regarding my investments and was extremely happy with the service.',
    subHeading: 'It was easy to deal with, explained well, and I achieved what I wanted.',
    footer: 'CFS member'
  },
  {
    heading: 'We have utilised Otivo\'s suggestions to boost our retirement funds through voluntary contributions.',
    footer: 'Rosie'
  },
  {
    heading: 'Wow 14 seconds for an SOA! Really!',
    footer: 'Anonymous'
  },
  {
    heading: 'It looks like it’s all in plain English, not in legalese... it’s very personalised, very easy to read.',
    footer: 'CFS member'
  },
  {
    heading: 'How good is this ? It’s so quick and easy!',
    footer: 'Anonymous'
  },
  {
    heading: 'It has been pretty good.',
    subHeading: 'One stop shop where I can upload all my bank accounts and summarise my expenses and savings. Before I was using a pocket book before it closed down, Otivo has replaced that for me.',
    footer: 'Anna'
  },
  // {
  //   heading: 'I like the percentage of fees and annual return.',
  //   footer: 'CFS member'
  // },
  {
    heading:
      'I love how easy and intuitive Otivo is, I can finally have all my financial information in one place.',
    subHeading: 'I was so happy that Otivo helped me optimise my home loan',
    footer: 'Libby'
  },
  {
    heading: 'Good on the Otivo team for bringing this together.',
    subHeading:
      'Having this app is such a good idea and I\'m excited to track my financial wellbeing.',
    footer: 'Carol'
  },
  {
    heading: 'The advice has been a lot of help for me.',
    footer: 'CFS member'
  }
])

const items = testimonials.value.map((testimonial) => {
  const data: TestimonialType = {
    heading: testimonial.heading,
    subHeading: testimonial.subHeading,
    footer: testimonial.footer
  }
  return h(TestimonialSlide, { data: data })
}).sort(() => Math.random() - 0.5)
</script>

<style scoped>

</style>
