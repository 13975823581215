<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_800_657276)">
      <path
        d="M15.6873 1.17909C15.6861 1.13126 15.6811 1.08361 15.6723 1.03659C15.6736 1.01077 15.6736 0.984906 15.6723 0.95909C15.6723 0.92159 15.6498 0.886591 15.6373 0.851591C15.6248 0.81659 15.6198 0.78409 15.6073 0.75159C15.5948 0.71909 15.5898 0.72159 15.5823 0.70409C15.5569 0.658604 15.5294 0.614384 15.4998 0.57159L15.4598 0.52159C15.4273 0.48159 15.3948 0.43909 15.3598 0.40159C15.3415 0.383656 15.3214 0.367731 15.2998 0.35409C15.2665 0.323661 15.2315 0.295276 15.1948 0.269091L15.1023 0.21909L15.0023 0.16909C14.9673 0.15409 14.9298 0.14659 14.8923 0.13409L14.7948 0.10659C14.7481 0.104118 14.7014 0.104118 14.6548 0.10659H14.5848H10.1073C9.95623 0.0994406 9.80532 0.123015 9.66367 0.175886C9.52201 0.228757 9.39256 0.309825 9.28314 0.414182C9.17373 0.518539 9.08663 0.644014 9.02712 0.783012C8.96761 0.92201 8.93692 1.07164 8.93692 1.22284C8.93692 1.37404 8.96761 1.52367 9.02712 1.66267C9.08663 1.80167 9.17373 1.92714 9.28314 2.0315C9.39256 2.13586 9.52201 2.21692 9.66367 2.26979C9.80532 2.32267 9.95623 2.34624 10.1073 2.33909H11.9373L8.56977 5.78159L6.38227 3.63659C6.27551 3.53284 6.14906 3.45151 6.01038 3.39741C5.87169 3.34331 5.72358 3.31753 5.57477 3.32159C5.42533 3.32435 5.27803 3.35747 5.14179 3.41892C5.00555 3.48038 4.88323 3.5689 4.78227 3.67909L0.297266 8.51909C0.112095 8.73875 0.0186361 9.02129 0.0362941 9.30804C0.0539522 9.59479 0.18137 9.86373 0.392093 10.059C0.602816 10.2543 0.880654 10.3609 1.16792 10.3567C1.45518 10.3526 1.72981 10.2379 1.93477 10.0366L5.63977 6.03659L7.80977 8.16659C8.02257 8.37305 8.30828 8.48716 8.60477 8.48409C8.75209 8.48291 8.89768 8.45221 9.03294 8.39382C9.1682 8.33542 9.29038 8.2505 9.39227 8.14409L13.4548 3.94659V5.65409C13.4683 5.941 13.5919 6.21166 13.7997 6.40991C14.0076 6.60815 14.2838 6.71875 14.571 6.71875C14.8582 6.71875 15.1344 6.60815 15.3423 6.40991C15.5501 6.21166 15.6737 5.941 15.6873 5.65409V1.17909Z"
        fill="#0064FF" />
      <path
        d="M15.07 14.2116H14.25V9.41155C14.2565 9.2852 14.2373 9.15884 14.1935 9.04015C14.1496 8.92147 14.0821 8.81295 13.995 8.7212C13.9079 8.62945 13.803 8.55639 13.6867 8.50646C13.5705 8.45653 13.4453 8.43079 13.3188 8.43079C13.1922 8.43079 13.067 8.45653 12.9508 8.50646C12.8345 8.55639 12.7296 8.62945 12.6425 8.7212C12.5554 8.81295 12.4879 8.92147 12.444 9.04015C12.4002 9.15884 12.381 9.2852 12.3875 9.41155V14.2116H10.7125V11.6541C10.7125 11.4074 10.6145 11.1709 10.4401 10.9964C10.2657 10.822 10.0292 10.7241 9.7825 10.7241C9.53585 10.7241 9.2993 10.822 9.12489 10.9964C8.95048 11.1709 8.8525 11.4074 8.8525 11.6541V14.2116H7.25V10.4066C7.25 10.2844 7.22594 10.1635 7.17921 10.0507C7.13247 9.93783 7.06397 9.8353 6.97761 9.74894C6.89125 9.66259 6.78873 9.59408 6.6759 9.54735C6.56306 9.50061 6.44213 9.47655 6.32 9.47655C6.19787 9.47655 6.07694 9.50061 5.9641 9.54735C5.85127 9.59408 5.74875 9.66259 5.66239 9.74894C5.57603 9.8353 5.50753 9.93783 5.46079 10.0507C5.41405 10.1635 5.39 10.2844 5.39 10.4066V14.2116H3.8075V11.7116C3.8075 11.4649 3.70952 11.2284 3.53511 11.0539C3.3607 10.8795 3.12415 10.7816 2.8775 10.7816C2.63085 10.7816 2.3943 10.8795 2.21989 11.0539C2.04548 11.2284 1.9475 11.4649 1.9475 11.7116V14.2116H1.1175C0.870848 14.2116 0.6343 14.3095 0.459891 14.4839C0.285482 14.6584 0.1875 14.8949 0.1875 15.1416C0.1875 15.3882 0.285482 15.6248 0.459891 15.7992C0.6343 15.9736 0.870848 16.0716 1.1175 16.0716H15.07C15.3167 16.0716 15.5532 15.9736 15.7276 15.7992C15.902 15.6248 16 15.3882 16 15.1416C16 14.8949 15.902 14.6584 15.7276 14.4839C15.5532 14.3095 15.3167 14.2116 15.07 14.2116Z"
        fill="#FF0000" />
    </g>
    <defs>
      <clipPath id="clip0_800_657276">
        <rect width="16" height="16" fill="white" transform="translate(0 0.0715332)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
