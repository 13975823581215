<template>
  <div class="flex flex-col rounded-lg sm:bg-blue-5 py-5 px-10 max-w-[455px]">
    <div class="my-5 flex flex-col h-full">
      <div
        v-if="showTrial"
        class="w-fit bg-otivo-red flex flex-row justify-center items-center gap-1 rounded-t-[4px] py-[10px] px-5 text-white">
        <svg class="h-3 w-3" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.875 4.72181H4.76666L6 0L0 7.27819H3.10834L1.875 12L7.875 4.72181Z"
            fill="white" />
        </svg>
        <p class="button-2">Free 7-day trial</p>
      </div>
      <div
        data-test="orderSummary"
        class="flex flex-col grow h-full bg-white border-1px border-grey-field p-5"
        :class="showTrial ? 'rounded-r-lg rounded-bl-lg' : 'rounded-lg'">
        <OrderSummarySkeleton v-if="loading" />
        <div v-else>
          <h5 class="font-bold">Order summary</h5>
          <p class="paragraph-3 text-grey-3">12-month Otivo access</p>
          <p class="paragraph-2 pt-5">Easy unlimited access for 12 months covering...</p>
          <Divider />
          <div class="flex flex-col gap-5">
            <!-- <div class="flex gap-3 items-center">-->
            <!--   <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>-->
            <!--   <p class="paragraph-2 flex flex-grow-1">24-7 access to our online resource centre</p>-->
            <!-- </div>-->
            <div class="flex gap-3 items-center">
              <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
              <p class="paragraph-2 flex flex-grow-1">Super investment advice</p>
            </div>
            <div class="flex gap-3 items-center">
              <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
              <p class="paragraph-2 flex flex-grow-1">Salary sacrifice advice</p>
            </div>
            <div class="flex gap-3 items-center">
              <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
              <p class="paragraph-2 flex flex-grow-1">Lump sum contributions advice</p>
            </div>
            <div class="flex gap-3 items-center">
              <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
              <p class="paragraph-2 flex flex-grow-1">Personal insurance advice</p>
            </div>
            <div class="flex gap-3 items-center" v-if="showDebt">
              <BasicTick class="flex fill-otivo-blue h-4 w-4 flex-shrink-0"></BasicTick>
              <p class="paragraph-2 flex flex-grow-1">Debt management</p>
            </div>
          </div>
          <Divider class="mt-auto" />
          <h5 class="font-bold mt-[60px]">
            <span v-if="showCouponPrice">
              <span class="line-through decoration-otivo-red decoration-[3px] pr-2"
                >${{ product?.yearly_price }}</span
              ><span class="text-otivo-red">$0</span
              ><span class="paragraph-2 text-grey-3"> / year</span>
            </span>
            <span v-else-if="showTrial">
              <span class="line-through decoration-otivo-red decoration-[3px] pr-2"
                >${{ product?.yearly_price }}<span class="text-grey-3"> / year</span></span
              >
              <span class="text-otivo">$0</span
              ><span class="paragraph-2 text-grey-3"> for 7 days</span>
            </span>
            <span v-else>
              ${{ product?.yearly_price }}<span class="paragraph-2 text-grey-3"> / year</span>
            </span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BasicTick from '@/components/icons/BasicTick.vue'
import Divider from '@/components/CreateAccount/Paywall/Partials/Divider.vue'
import { Product } from '@/types/ProductInterface.ts'
import {
  ExclusionRules,
  useWhitelabelExclusionRules,
} from '@/composables/useWhitelabelExclusionRules.ts'
import { computed } from 'vue'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useProductStore } from '@/store/pinia/ProductStore.ts'
import OrderSummarySkeleton from '@/components/CreateAccount/Paywall/Partials/OrderSummarySkeleton.vue'

const props = defineProps<{ payment: 'credit' | 'coupon' }>()

const user = computed(() => useUserStore().getUser)
const existingSubscriptions = computed(() => user.value?.subscriptions)
const product = computed(() => useProductStore().product as Product)
const loading = computed(() => !product.value || !user.value.auth0id)

const { hasExclusionRule } = useWhitelabelExclusionRules()
const showDebt = !hasExclusionRule(ExclusionRules.DEBT_MODULE)
const showCouponPrice = computed(() => props.payment == 'coupon')
const showTrial = computed(() => !showCouponPrice.value && existingSubscriptions.value?.length == 0)
</script>
