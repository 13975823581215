import type { ChartOptions } from 'chart.js'

import { Chart } from 'chart.js/auto'
import { useDebounceFn } from '@vueuse/core'
import htmlLegendsPlugin from '@/composables/charts/plugins/htmlLegendsPlugin.ts'

export const defaultOptions = {
  scales: {
    y: {
      border: { display: false },
      ticks: {
        maxTicksLimit: 5,
      },
      grid: {
        color: '#A0E6FA',
      },
    },
    x: {
      border: { display: false },
      grid: {
        display: false,
        zeroLineColor: 'transparent',
      },
    },
  },
  plugins: {
    htmlLegend: {
      containerID: 'legend',
    },
    legend: {
      display: false,
    },
  },
}

export const drawGraph = useDebounceFn(
  (data, canvas: HTMLCanvasElement, options?, plugins = [htmlLegendsPlugin()]) => {
    const config = {
      type: 'line',
      options: options ?? defaultOptions,
      data: data,
      plugins: plugins,
    }

    return new Chart(canvas, config)
  },
  500,
)
