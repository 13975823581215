<template>
  <BaseDebtForm />
  <DebtPaymentFrequency @change="emitChange" />
  <DebtRemainingTerm />
  <DebtOwner v-if="user.has_partner" @change="emitChange" />
  <DebtRepaymentAmount />
</template>

<script setup lang="ts">
import BaseDebtForm from '@/views/Otivo/Dashboard/Debt/Forms/BaseDebtForm.vue'
import DebtPaymentFrequency from '@/views/Otivo/Dashboard/Debt/Forms/DebtPaymentFrequency.vue'
import { computed } from 'vue'
import DebtOwner from '@/views/Otivo/Dashboard/Debt/Forms/DebtOwner.vue'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import DebtRemainingTerm from '@/views/Otivo/Dashboard/Debt/Forms/DebtRemainingTerm.vue'
import DebtRepaymentAmount from '@/views/Otivo/Dashboard/Debt/Forms/DebtRepaymentAmount.vue'

const userStore = useUserStore()
const user = computed(() => userStore.getUser)

const emit = defineEmits<(event: 'change', message: string) => void>()
const emitChange = () => emit('change', 'Car loan form updated')
</script>
