<template>
  <div class="w-full">
    <nav class="bg-white block md:hidden p-5">
      <RouterLink :to="{ name: 'debtPlan' }" class="flex align-baseline">
        <span class="text-otivo-blue pr-3">
          <!-- SVG left pointing caret -->
          <svg
            class="h-4 w-4 inline"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3419_172175)">
              <path
                d="M11.3749 2.74934C11.3756 2.90538 11.3218 3.05788 11.2206 3.18638L7.41497 8.00138L11.2206 12.8183C11.3484 12.9806 11.3997 13.1799 11.3638 13.3745C11.3278 13.5692 11.2075 13.7442 11.0278 13.863C10.9396 13.9193 10.8394 13.9596 10.7331 13.9813C10.6269 14.003 10.5167 14.0059 10.4091 13.9896C10.3016 13.9733 10.1989 13.9383 10.1071 13.8866C10.0153 13.8349 9.93639 13.7675 9.87493 13.6886L5.375 8.00138L9.87493 2.31417C9.9364 2.23441 10.0157 2.16637 10.108 2.11415C10.2003 2.06193 10.3037 2.0266 10.412 2.0103C10.5203 1.994 10.6313 1.99707 10.7382 2.01931C10.8452 2.04155 10.9458 2.08252 11.0342 2.13973C11.1417 2.21076 11.2286 2.30297 11.2878 2.40888C11.347 2.5148 11.3768 2.63144 11.3749 2.74934Z"
                fill="#0064FF" />
            </g>
          </svg>
        </span>
        <span class="text-grey-2 font-normal underline underline-offset-4">Back to debt plan</span>
      </RouterLink>
    </nav>
    <section class="w-full">
      <div class="w-full flex flex-col md:flex-row justify-between items-center px-5 pt-5 md:px-0">
        <div class="w-full pb-5">
          <h4 class="text-blue-1">{{ headerText }}</h4>
          <div class="md:mt-5">
            <span class="paragraph-3 text-grey-2">Balance: </span>
            <span data-test="debtBalance" class="button-1 text-blue-1">{{
              formatCurrency(liability?.balance ?? 0)
            }}</span>
            <span class="paragraph-3 text-grey-2 ml-[5px]"> Interest rate: </span>
            <span data-test="debtInterestRate" class="button-1 text-blue-1"
              >{{ liability?.interest_rate ?? 0 }}%</span
            >
          </div>
        </div>
        <div class="bg-blue-5 mt-5 md:mt-10 p-2 rounded w-full grid grid-cols-2 gap-2">
          <button
            v-for="(option, index) in tabs"
            :disabled="isNewLiability || liabilityOverviewUnavailable"
            :key="index"
            class="px-[20px] py-[13px] button-2 border-[2px] border-solid rounded-[5px] transition-colors duration-[469ms] ease-in-out hover:border-otivo-blue focus:border-otivo-blue focus:outline-none"
            :data-test="option.value"
            :class="[
              option.value == tabOption
                ? 'bg-white border-otivo-blue shadow'
                : 'bg-blue-5 border-none text-blue-2',
              isNewLiability || liabilityOverviewUnavailable
                ? 'cursor-not-allowed '
                : 'cursor-pointer',
            ]"
            @click.prevent="handleClick(option)">
            {{ option.label }}
          </button>
        </div>
      </div>
    </section>
    <RouterView class="w-full" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { formatCurrency } from '@/lib/helpers'
import { convertToKebabCase } from '@/utilities.ts'

type Option = {
  label: string
  value: string
}

const LIABILITY_TYPE_HEADERS = {
  'credit-card': 'Credit card',
  mortgage: 'Home loan',
  'car-loan': 'Car loan',
  'personal-loan': 'Personal loan',
  investment: 'Investment loan',
} as const
type LiabilityType = keyof typeof LIABILITY_TYPE_HEADERS

const liabilityStore = useLiabilityStore()
const route = useRoute()
const router = useRouter()

const tabOption = ref('DebtDetails')
const isAdminPortal = computed(() => route.meta.isAdminPortal)

const liability = computed(() => liabilityStore.liability)
const isNewLiability = computed(() => route.params.id === 'new')
const liabilityOverviewUnavailable = computed(() => {
  return (
    liability.value?.completed ||
    liability.value?.balance == 0 ||
    liability.value?.paid_monthly ||
    liability.value?.interest_rate == 0
  )
})

const headerText = computed(() => {
  // if we've got the name, return
  if (liability?.value?.name) return liability.value.name

  // get type
  const type = route.params.type as LiabilityType

  function isValidLoanType(type: unknown): type is LiabilityType {
    return typeof type === 'string' && type in LIABILITY_TYPE_HEADERS
  }

  // check type is valid
  if (!isValidLoanType(type)) {
    throw new TypeError(`Invalid loan type: ${String(type)}`)
  }
  // return default header for liability type
  return LIABILITY_TYPE_HEADERS[type]
})

onMounted(async () => {
  if (!liability.value && !isNewLiability.value) {
    await liabilityStore.fetchLiability(+route.params.id)
  }

  if (route.params.type === 'credit-card') {
    liability.value.payment_frequency = 'monthly'
  }

  // if the overview is unavailable, redirect to the details page
  if (liabilityOverviewUnavailable.value && router.currentRoute.value.name !== 'DebtDetails') {
    await router.replace({
      name: route.meta.isAdminPortal ? 'AdminDebtDetails' : 'DebtDetails',
      params: { id: liability.value?.id, type: convertToKebabCase(liability.value?.type) },
    })
  }
})

const handleClick = (val: Option) => {
  const liabilityId = route.params?.id
  const liabilityType = route.params?.type
  if (liabilityId && liabilityType) {
    router.push({ name: val.value, params: { id: liabilityId, type: liabilityType } })
  }
}

watchEffect(() => (tabOption.value = route.name as string))

const tabs: Option[] = [
  {
    label: 'Overview',
    value: isAdminPortal.value ? 'AdminDebtDetailsOverview' : 'DebtDetailsOverview', //route name
  },
  {
    label: 'Details',
    value: isAdminPortal.value ? 'AdminDebtDetails' : 'DebtDetails', //route name
  },
]
</script>
