<template>
  <div class="flex flex-col">
    <div class="-m-1.5 overflow-x-auto">
      <div class="p-1.5 min-w-full inline-block align-middle">
        <div class="flex flex-col gap-y-10 overflow-hidden">
          <TileComponent :loading="loading" :tiles="tiles" />
          <GraphComponent :loading="loading" :dataset="dataset" />
          <TableComponent
            :users="users"
            :total="totalUsers"
            :loading="loading"
            @on-filter-year="(year) => handleFetchUsers('year', year)"
            @on-paginate="(page) => handleFetchUsers('page', +page)"
            @on-search="(search) => handleFetchUsers('search', search)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import TileComponent from './WealthHealthCheckReport/TileComponent.vue'
import GraphComponent from './WealthHealthCheckReport/GraphComponent.vue'
import TableComponent from './WealthHealthCheckReport/TableComponent.vue'
import CallcentreReportingService from '@/services/CallcentreReportingService.ts'
import { useCallcentreReportingStore } from '@/store/pinia/CallcentrePortalReportingStore.ts'

const reportingStore = useCallcentreReportingStore()
const tiles = ref<
  Array<{
    label: string
    value: string
  }>
>([])

const users = ref([])
const totalUsers = ref(0)
const userFilter = ref({
  page: 1,
  search: '',
  year: new Date().getFullYear(),
})

const dataset = ref<
  Array<{
    label: string
    data: Record<string, number>
    participation: number
  }>
>([])
const loading = ref(false)

onMounted(() => {
  fetchStats()
  fetchUsers()
})

const fetchStats = async () => {
  loading.value = true
  try {
    const response = await CallcentreReportingService.getWealthHealthOverview(
      reportingStore.getPostParams(),
    )
    tiles.value = response.data?.scores || []
    dataset.value = response.data?.graph_datasets || []
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const fetchUsers = async () => {
  loading.value = true
  try {
    const response = await CallcentreReportingService.getWealthHealthMemberEngagement({
      ...reportingStore.getPostParams(),
      ...userFilter.value,
    })
    users.value =
      response?.data?.data.map((item: Record<string, string>) => ({
        fullName: item.name,
        clientId: item.client_id,
        latestStatus: item.status,
        q1: item.q1,
        q2: item.q2,
        q3: item.q3,
        q4: item.q4,
      })) || []
    totalUsers.value = response?.data?.total || 0
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const handleFetchUsers = (key: string, value: number | string) => {
  Object.assign(userFilter.value, { [key]: value })
  fetchUsers()
}

const unsubscribe = reportingStore.$onAction(({ name, after }) => {
  if (name === 'setSearchRange' || name === 'setWhitelabelId') {
    after(() => {
      fetchStats()
      fetchUsers()
    })
  }
})

onBeforeUnmount(() => {
  unsubscribe()
})
</script>

<style scoped></style>
