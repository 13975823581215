<template>
  <div id="otivo-table">
    <div
      v-if="explainerText !== ''"
      v-html="explainerText"
      class="mb-[20px] text-blue-1 paragraph-2" />
    <div
      v-if="data.tableData.length > 0"
      class="border border-blue-3 rounded-[10px] overflow-hidden md:grid"
      :style="'grid-template-columns: repeat(' + numberOfColumns + ', minmax(0, 1fr));'">
      <!-- Grid template columns style used as tailwind didn't want to generate   -->
      <div
        v-for="(tableContent, index) in data.tableData"
        :key="index"
        class="flex justify-between items-center p-[20px] border-r border-blue-5 h-[80px] md:border-t"
        :class="{
          'bg-blue-5': tableContent.fieldType === 'heading',
          'border-t': (index - 1) % numberOfColumns === 0
        }">
        <p
          v-if="tableContent.fieldType === 'heading'"
          class="button-1 text-blue-1 md:border-blue-3 flex items-center">
          {{ tableContent.component }}
        </p>
        <div
          v-else-if="tableContent.fieldType === 'html'"
          class="button-1 text-grey-1"
          v-html="tableContent.component" />
        <component
          v-else-if="tableContent"
          :is="tableContent.component"
          :value="tableContent.value"
          :max="tableContent.max"
          :enforce-min-max="tableContent.enforceMinMax"
          :name="tableContent.name"
          :type="tableContent.fieldType"
          :class="tableContent.class"
          :disabled="tableContent.disabled"
          @update:value="(val: number) => inputHandle(val, tableContent.name)" />
      </div>
    </div>
    <div v-else>
      <BeatLoader class="mt-[40px] text-center" color="var(--otivo-blue)" size="32px" />
    </div>
  </div>
</template>

<script setup lang="ts">
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { TableDataType } from '@/types/CallcentrePortalTypes.ts'
import { onMounted, ref } from 'vue'

const emits = defineEmits(['input', 'update:value', 'update'])
const props = defineProps<{
  id: number
  accountId: number
  isConcessional: boolean
  getData: (
    accountId: number,
    isConcessional: boolean
  ) => Promise<{
    tableData: Array<TableDataType>
    tableInputValue: Array<unknown>
  }>
  numberOfColumns: number
  explainerText?: string
}>()

const data = ref<{
  tableData: Array<TableDataType>
  tableInputValue: Array<unknown>
}>()

const inputHandle = (value: number, contentName: number) => {
  if (data.value && contentName) {
    data.value.tableInputValue[contentName] = value
    emits('update:value', data.value.tableInputValue)
  }
}
onMounted(async () => {
  await props
    .getData(props.accountId, props.isConcessional)
    .then((res) => {
      data.value = res
      emits('update:value', data.value.tableInputValue)
    })
    .catch((err) => console.error(err))
})
</script>

<style scoped></style>
