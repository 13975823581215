<template>
  <div class="w-100% flex flex-col gap-3">
    <div class="flex flex-col md:flex-row gap-3 md:gap-[20px]">
      <BaseInput
        class="w-100% md:w-50%"
        name="salary"
        type="currency"
        :value="salary as number"
        @update:value="
          (val) => {
            salary = val as number
            emit()
          }
        " />
      <Dropdown
        class="w-100% md:w-50%"
        type="super-included-dropdown"
        @selected="
          (val) => {
            superIncluded = val.value as number
            emit()
          }
        "
        :existing-item="dropdownItems.find((item) => item.value === superIncluded)"
        :items="dropdownItems" />
    </div>
    <p v-if="errorMessage" class="otivo-error-message">
      {{ errorMessage }}
    </p>
    <p v-if="softError" class="otivo-error-message">
      {{ softError }}
    </p>
  </div>
</template>

<script setup lang="ts">
import BaseInput from '@/components/Inputs/BaseInput.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { onMounted, ref } from 'vue'
import { InputItem } from '@/types/InputItem.ts'

type SalaryInputProps = {
  existingValue?: { amount: number; super: 0 | 1 }
  errorMessage?: string
  softError?: string
}
const props = withDefaults(defineProps<SalaryInputProps>(), {
  existingValue: () => ({ amount: 0, super: 0 }),
  errorMessage: '',
  softError: ''
})

const dropdownItems: InputItem[] = [
  {
    label: 'Including super',
    value: 1
  },
  {
    label: 'Excluding super',
    value: 0
  }
]

onMounted(() => {
  salary.value = props.existingValue.amount ?? 0
  superIncluded.value = props.existingValue.super ?? 0
})

const salary = ref<number>()
const superIncluded = ref<number>()
type SalaryEmits = {
  value: number
  superIncluded: number
}
const emits = defineEmits<{
  (e: 'update:value', val: SalaryEmits): SalaryEmits
}>()
const emit = () => {
  if (salary.value) {
    emits('update:value', {
      value: salary.value,
      superIncluded: superIncluded.value as number
    })
  }
}
</script>

<style scoped></style>
