<template>
  <NavCollapse v-if="collapsable || !lgScreen" class="rounded-b-none rounded-t-none">
    <template #heading>
      <div class="mh6 md:b1" :class="headerColour" v-if="formattedTitle">
        {{ formattedTitle }}
      </div>
    </template>
    <template #body>
      <div :class="`text-${textColour}`" v-for="(link, index) in links" :key="index" :data-test="link.id">
        <RouterLink
          v-if="link.target === 'self' && link.link"
          :to="link.link"
          class="no-underline button-2 hover:text-otivo-blue"
          :class="`text-${textColour}`"
          @click="scrollToTop">
          <div class="my-[20px] md:my-[10px]" @click="scrollToTop" :data-test="link.name + '-link'">
            {{ link.name }}
          </div>
        </RouterLink>
        <a
          v-else-if="link.link"
          :href="link.link"
          target="_blank"
          class="no-underline button-2 hover:text-otivo-blue"
          :class="`text-${textColour}`"
          @click="scrollToTop">
          <div class="my-[20px] md:my-[10px]" :data-test="link.name + '-link'">{{ link.name }}</div>
        </a>
      </div>
    </template>
  </NavCollapse>
  <div v-else>
    <div class="font-bold leading-8" :class="headerColour" v-if="formattedTitle">
      {{ formattedTitle }}
    </div>
    <div v-for="(link, index) in links" :key="index">
      <RouterLink
        v-if="link.target === 'self' && link.link"
        :to="link.link"
        class="no-underline hover:underline hover:text-otivo-blue transition duration-300 ease-in"
        :class="`text-${textColour}`">
        <div class="my-[20px] md:my-[10px]" @click="scrollToTop" :data-test="link.name + '-link'">
          {{ link.name }}
        </div>
      </RouterLink>
      <a
        v-else-if="link.link"
        :href="link.link"
        target="_blank"
        class="no-underline hover:underline hover:text-otivo-blue transition duration-300 ease-in"
        :class="`text-${textColour}`">
        <div class="my-[20px] md:my-[10px]" @click="scrollToTop" :data-test="link.name + '-link'">{{ link.name }}</div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import NavCollapse from '@/components/SaveAndCollapse/NavCollapse.vue'
import { useMediaQuery } from '@vueuse/core'
import { useStore } from 'vuex'

const props = defineProps({
  links: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  headerColour: {
    type: String,
    required: true,
  },
  textColour: {
    type: String,
    required: true,
  },
  collapsable: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()

const lgScreen = useMediaQuery('(min-width: 1024px)')
const formattedTitle = computed(() => props.title.replace('_', ' '))

const emit = defineEmits(['scroll'])
const scrollToTop = () => {
  // Assuming closeSideBar action is now handled elsewhere or not needed
  // Emitting 'scroll' event to parent
  store.dispatch('closeSideBar')
  emit('scroll')
}
</script>

<style scoped></style>
