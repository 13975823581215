<template>
  <div id="landing-page-module" class="flex flex-col" :class="`bg-${props.backgroundColor}`">
    <div class="px-6 md:mx-0 w-full md:max-w-[1280px] flex flex-col items-center mb-16">
      <h1
        class="w-100% text-center mt-[80px]"
        v-html="landingPageContent.heading"
        v-if="landingPageContent.heading" 
        data-test="landingPageHeading"/>
      <h1 class="w-[80%] md:w-full text-center mt-[60px]" v-else>
        Financial advice for <span class="svg-underline">all</span>
      </h1>
      <h5 class="mt-[40px] text-center" v-html="landingPageContent.subHeading" data-test="landingPageSubHeading" />
      <p
        v-if="landingPageContent.body"
        class="w-90% md:w-80% md:max-w-[954px] mt-[40px] text-center"
        v-html="landingPageContent.body" />

      <div class="mt-10 transform transition-all duration-1000 hover:scale-105">
        <slot name="svgComponent" />
      </div>

      <div class="flex gap-2 md:gap-6 mt-[40px] w-full sm:w-auto">
        <OtivoButton class="w-full" @click="$emit('ButtonClicked')" v-if="showButton" size="medium" data-test="landingPageButton">
          {{ landingPageContent.buttonText }}
        </OtivoButton>
      </div>

      <MediaLogos v-if="showLogos" class="hidden md:block md:mt-12" />
      <p v-if="showDisclaimerText" class="text-center paragraph-4 mt-12">
        *Otivo Super + Advice Report 2024
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import MediaLogos from '@/components/SVGS/MediaLogos.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { store } from '../../../main/application/src/store'

type LandingPageContentType = {
  heading: string
  subHeading: string
  body?: string
  buttonText: string
}

interface Props {
  showButton?: boolean
  buttonText?: string
  showLogos?: boolean
  showDisclaimerText?: boolean
  backgroundColor?: string
  landingPageContent: LandingPageContentType
}

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: 'white',
  showLogos: true,
  showDisclaimerText: false,
  showButton: false,
  buttonText: 'Get started',
})

defineEmits(['ButtonClicked'])
</script>

<style scoped lang="scss"></style>
