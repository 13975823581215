<template>
  <div
    v-if="navBar.opened"
    class="w-full md:w-[375px] z-60 shadow overflow-auto relative bg-white rounded-bl md:rounded-br h-full md:h-auto">
    <!-- Profile Button -->
    <div @click="toggleSideBar()" class="text-left cursor-pointer" data-test="closeSideBarBtn">
      <MultiplyIcon class="w-4 h-4 m-4" />
    </div>
    <div v-if="adviserEmailContact || isAmpDemo" class="flex justify-center mb-4">
      <OtivoButton colour="red" class="mx-4 w-full" @click="showAdviserContactModal">
        Contact your adviser
      </OtivoButton>
    </div>
    <div v-if="isStanfordBrown" class="flex justify-center mb-4">
      <a href="https://stanfordbrown.com.au/" target="_blank">
        <OtivoButton colour="red" class="mx-4 w-full"> Contact Stanford Brown</OtivoButton>
      </a>
    </div>
    <!-- Admin Report -->
    <div
      v-if="profile.superAdmin || profile.whiteLabelAdmin"
      @click="toggleSideBar()"
      :class="getClassList"
      class="pb-4 mx-4">
      <RouterLink to="/report" class="w-full px-2 leading-none font-medium no-underline text-black">
        <div class="my-3 text-grey-2">Reports</div>
      </RouterLink>
    </div>
    <div :key="title" v-for="(links, title) in getWhiteLabelsLinks" class="px-4">
      <LinkTemplate
        :links="links"
        :title="title as string"
        :title-classes="'border-b-grey-4'"
        :data-test="title + 'SideBar'"
        text-colour="grey-2"
        header-colour="text-grey-1"
        :collapsable="true"
        :default-open="false" />
    </div>
    <div @click="toggleSideBar()" :class="getClassList" class="flex text-grey-2 items-center">
      <RouterLink
        to="/profile"
        data-test="profileBtn"
        class="flex justify-center p-4 rounded-xl bg-otivo-blue hover:bg-blue-1 max-w-75% w-full">
        <span class="button-1 text-white font-bold">Profile</span>
      </RouterLink>
      <!--            <profile-nav-bar-link-->
      <!--              class="RouterLink intro-1"-->
      <!--            />-->
    </div>
    <div class="flex flex-col gap-6 py-6 px-4">
      <OtivoButton v-if="!authenticated" colour="red" @click="triggerSignIn" id="signInBtn" data-test="signInBtn">
        Log in
      </OtivoButton>
      <OtivoButton v-if="!authenticated" @click="triggerSignUp" id="getStartedBtn">
        {{ isOtivo ? 'Start free trial' : 'Get started' }}
      </OtivoButton>
      <OtivoButton v-if="authenticated" @click="triggerLogOut"> Log out</OtivoButton>
      <SunCorpStaffBenefitsButton @click="toggleSideBar()" />
      <SunCorpContactStaffFinancialServices @click="toggleSideBar()" />
      <SunSuperGetAdviceButton @click="toggleSideBar()" />
      <EyStaffBenefits @click="toggleSideBar()" />
    </div>
    <DownloadSoaButton class="my-3 text-grey-2 intro-1" @click="toggleSideBar()" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin.ts'
import { useStore } from 'vuex'
import { useSessionStore } from '@/store/pinia/SessionStore'
import DownloadSoaButton from '@/components/DownloadSoa/DownloadSoaButton.vue'
import EyStaffBenefits from '@/components/EyStaffBenefits/EyStaffBenefits.vue'
import LinkTemplate from '@/components/Footer/LinkTemplate.vue'
import MultiplyIcon from '@/components/icons/MultiplyIcon.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import SunCorpContactStaffFinancialServices from '@/components/SunCorp/SunCorpContactStaffFinancialServices.vue'
import SunCorpStaffBenefitsButton from '@/components/SunCorp/SunCorpStaffBenefitsButton.vue'
import SunSuperGetAdviceButton from '@/components/SunSuper/SunSuperGetAdviceButton.vue'
import { getLinksForWhitelabel } from '@/composables/getLinksForWhitelabel.ts'

const auth0 = useAuth0()
const store = useStore()
const sessionStore = useSessionStore()

const authenticated = computed(() => auth0.isAuthenticated.value)
const getWhiteLabelsLinks = computed(() => getLinksForWhitelabel())

const profile = computed(() => store.getters.profile)
const getCurrentWhitelabelObject = computed(() => store.getters.getCurrentWhitelabelObject)
const navBar = computed(() => store.state.navBar)
const adviserEmailContact = computed(() => {
  const adviserEmail = getCurrentWhitelabelObject.value?.adviser_contact_email
  return adviserEmail && adviserEmail.trim() !== ''
})
const getClassList = computed(() => ({
  enabled: authenticated.value,
  hidden: !authenticated.value,
}))

const whitelabel = computed(() => sessionStore.getWhitelabelData)
const isOtivo = computed(() => whitelabel.value?.identifier === 'OTIVO')
const isStanfordBrown = computed(() => whitelabel.value?.identifier === 'STANFORD_BROWN')
const isAmpDemo = computed(() => whitelabel.value?.identifier === 'AMP_DEMO')

const showAdviserContactModal = () => {
  store.dispatch('toggleAdviserContactModal', true)
}

const toggleSideBar = () => {
  store.dispatch('toggleSideBar')
}

const triggerSignUp = () => {
  auth0.createAccount()
}

const triggerSignIn = () => {
  auth0.login()
  store.dispatch('closeSideBar')
}

const triggerLogOut = () => {
  store.dispatch('closeSideBar')
  sessionStore.logout()
}
</script>

<style scoped>
.enabled a {
  @apply text-black;
}
</style>
