<template>
  <div>
    <div class="w-100%">
      <div
        v-for="(sideNavAccordion, index) in sideNavComponents"
        :key="sideNavAccordion.heading + index"
        class="overflow-hidden">
        <!--    start of new component    -->
        <div
          v-if="!sideNavAccordion.hide"
          :class="[
            styles.base,
            {
              'cursor-pointer': isCollapsable || sideNavAccordion.routerName,
              'bg-blue-5 rounded-sm':
                isPageActive(sideNavAccordion.heading) && !sideNavAccordion.content,
              'hover:bg-[#d7f5ff30] rounded-sm':
                !isPageActive(sideNavAccordion.heading) || sideNavAccordion.content,
              disabled: sideNavAccordion.disabled,
            },
          ]"
          class="p-[20px] md:p-[10px] border-b-[1px] border-blue-5 group"
          :data-test="sideNavAccordion.heading + '-accordion'"
          @click="
            sideNavAccordion.content
              ? toggleAccordion(sideNavAccordion.heading)
              : redirectToTab(sideNavAccordion.routerName ?? '')
          ">
          <div class="flex justify-between">
            <div class="flex gap-[10px] items-center relative w-100%">
              <!-- <ClipLoader-->
              <!-- v-if="isPageActive(sideNavAccordion.heading)"-->
              <!-- class="bottom-0 right-0 absolute"-->
              <!-- :color="`var(&#45;&#45;otivo-blue)`"-->
              <!-- size="15px" />-->
              <component
                :is="sideNavAccordion.icon"
                class="w-[14px] h-[14px]"
                :fill1="
                  isPageActive(sideNavAccordion.heading) && !sideNavAccordion.content
                    ? 'otivo-blue'
                    : 'blue-1'
                "
                :fill2="
                  isPageActive(sideNavAccordion.heading) && !sideNavAccordion.content
                    ? 'otivo-blue'
                    : 'blue-1'
                "
                hover1="otivo-blue"
                hover2="otivo-blue" />
              <div
                :class="[
                  styles.heading,
                  isPageActive(sideNavAccordion.heading) && !sideNavAccordion.content
                    ? 'text-otivo-blue '
                    : 'group-hover:text-otivo-blue !font-[500]',
                ]">
                {{ sideNavAccordion.heading }}
              </div>
            </div>
            <ArrowWide
              v-if="isCollapsable && sideNavAccordion.content"
              :class="
                navsOpen[sideNavAccordion.heading]
                  ? 'transition transform rotate-180'
                  : 'transition transform rotate-90'
              "
              :data-test="navsOpen[sideNavAccordion.heading]
                  ? `${sideNavAccordion.heading}-arrow-180`
                  : `${sideNavAccordion.heading}-arrow-90`"
              :fill="chevronColour"
              class="self-center w-[14px]" />
          </div>
          <Transition name="fast-slide">
            <Status
              v-if="
                sideNavAccordion.primaryStatus &&
                moduleOptions &&
                !navsOpen[sideNavAccordion.heading]
              "
              :state="transformHeaderStatus(sideNavAccordion.primaryStatus)"
              status-style="outline"
              class="p-[0px] ml-[20px]" />
          </Transition>
        </div>
        <!--    ./ end of new component    -->
        <Transition name="slide">
          <div
            v-show="navsOpen[sideNavAccordion.heading]"
            :class="{ 'open border-b-[1px]': navsOpen[sideNavAccordion.heading] }"
            class="border-blue-5">
            <div
              v-for="sideNavAccordionContent in sideNavAccordion.content"
              :key="sideNavAccordionContent.label + index">
              <div
                v-if="!sideNavAccordionContent.hide"
                :class="[
                  navName === sideNavAccordionContent.routerName ? 'bg-blue-5 text-otivo-blue' : '',
                  sideNavAccordionContent.disabled
                    ? 'disabled pointer-events-none text-grey-3'
                    : 'hover:bg-[#d7f5ff50]',
                ]"
                class="cursor-pointer items-start ml-[20px] pl-[15px] p-[10px] rounded-l-sm"
                @click="
                  redirectToTab(
                    sideNavAccordionContent.routerName,
                    sideNavAccordionContent?.routerParams || {},
                  )
                ">
                <div class="flex gap-[10px]">
                  <component
                    :is="sideNavAccordionContent.icon"
                    :fill1="styles.contentIconFill"
                    :fill2="styles.contentIconFill"
                    class="min-w-[16px] max-w-[16px] h-[16px]" />
                  <div
                    :class="[
                      styles.content,
                      { '!font-[500]': navName !== sideNavAccordionContent.routerName },
                    ]"
                    :data-test="sideNavAccordionContent.routerName">
                    {{ sideNavAccordionContent.label }}
                  </div>
                </div>
                <Status
                  v-if="moduleOptions && sideNavAccordionContent?.routerName"
                  :state="transformModuleStatus(sideNavAccordionContent.routerName)"
                  status-style="outline"
                  :data-test="sideNavAccordionContent.routerName + '-status'"
                  class="p-0" />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import ArrowWide from '@/components/icons/ArrowWide.vue'
import { computed, onMounted, ref } from 'vue'
import { SideNavAccordionContentType, SideNavAccordionsType } from '@/types/GlobalTypes.ts'
import { useNavBarStore } from '@/store/pinia/NavBarStore.ts'
import Status from '@/components/Status.vue'
import { ModuleStatusesType, useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import { useDebounceFn } from '@vueuse/core'

const router = useRouter()

// NOTE: this component will be deprecated in the future and refactored into NavigationTabs

type AccordionStyle = {
  heading: string
  content: string
  contentIconFill: string
  base: string
}

interface Props {
  id: string
  sideNavAccordions: Array<SideNavAccordionsType>
  chevronColour?: string
  styles?: AccordionStyle
  isCollapsable?: boolean
}

interface SideNavOption {
  label: string
  value: string
}

const props = withDefaults(defineProps<Props>(), {
  borderColour: 'grey-2',
  chevronColour: '#888',
  isCollapsable: true,
  styles: () => {
    return {
      heading: 'text-16px',
      content: '',
      contentIconFill: 'otivo-blue',
      base: '',
    }
  },
})

const route = useRoute()
const navBarStore = useNavBarStore()
const moduleStatus = useModuleStatusesStore()

const sideNavComponents = computed(() => props.sideNavAccordions)
const sideNavMobileSelectRef = ref<SideNavOption[]>([])
const navsOpen = computed(() => navBarStore.getNavsOpenState)

const moduleOptions = computed<ModuleStatusesType>(() => moduleStatus.getStatuses)

const navName = computed(() => route.name)
const emit = defineEmits(['click'])

onMounted(() => {
  sideNavComponents.value.forEach((sideNavAccordion) => {
    sideNavAccordion.content?.forEach((sideNavAccordionContent: SideNavAccordionContentType) => {
      sideNavMobileSelectRef.value.push({
        label: sideNavAccordionContent.label,
        value: sideNavAccordionContent.routerName,
      })
    })
    // hack to fix the issue of the accordion not opening when the page is refreshed
    if (isPageActive(sideNavAccordion.heading)) {
      navBarStore.setNavOpenState('Reporting', true)
    }
  })
})

const transformHeaderStatus = (primaryStatus: string | Array<string>) => {
  return moduleStatus.getHighestPriorityState(primaryStatus)
}
const transformModuleStatus = (routerName: string) => {
  if (routerName === 'personalInsurance') {
    return moduleStatus.getHighestPriorityState(routerName) ?? ''
  }
  return moduleOptions?.value[routerName] ?? ''
}

const redirectToTab = useDebounceFn((routerName: string, params = {}) => {
  try {
    emit('click')
    router.push({ name: routerName, params })
  } catch (e) {
    console.error(e)
  }
}, 50)

const toggleAccordion = (accordionHeading: string) => {
  if (props.isCollapsable) {
    navBarStore.setNavOpenState(accordionHeading, !navsOpen.value[accordionHeading])
  }
}

const navLinks = computed(() => {
  const urls: Record<string, Array<string>> = {}
  sideNavComponents.value.forEach((sideNavAccordion) => {
    urls[sideNavAccordion.heading] = []
    if (sideNavAccordion.routerName) {
      urls[sideNavAccordion.heading].push(sideNavAccordion.routerName)
    }
    sideNavAccordion.content?.forEach((sideNavAccordionContent) => {
      urls[sideNavAccordion.heading].push(sideNavAccordionContent.routerName ?? '')
    })
  })
  return urls
})

const isPageActive = (heading: string) => {
  return navLinks.value[heading]?.includes(navName.value)
}
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.9s ease-in-out;
  max-height: 100vh;
}

.slide-leave-active {
  transition: all 0.9s ease-in-out;
  max-height: 100vh;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
}

.fast-slide-enter-active {
  transition: all 0.3s ease-out;
  max-height: 20vh;
}

.fast-slide-leave-active {
  transition: all 0.3s ease-out;
  transition-delay: 0.2s;
  max-height: 20vh;
}

.fast-slide-enter-from,
.fast-slide-leave-to {
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
}

:deep(ul) {
  list-style: disc !important;
  margin-left: 10px !important;
}
</style>
