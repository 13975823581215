<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 15.3209H14.5C14.8978 15.3209 15.2794 15.1629 15.5607 14.8816C15.842 14.6003 16 14.2187 16 13.8209V9.88098H0V13.8209C0 14.2187 0.158035 14.6003 0.43934 14.8816C0.720644 15.1629 1.10218 15.3209 1.5 15.3209Z"
      fill="#0064FF" />
    <path
      d="M0.43934 2.76026C0.158035 3.04157 0 3.4231 0 3.82092V4.88098H16V3.82092C16 3.4231 15.842 3.04157 15.5607 2.76026C15.2794 2.47896 14.8978 2.32092 14.5 2.32092H1.5C1.10218 2.32092 0.720644 2.47896 0.43934 2.76026Z"
      fill="#0064FF" />
    <path d="M16 6.32092H0V8.32092H16V6.32092Z" fill="#FF0000" />
    <path d="M5.5 10.881H3V12.881H5.5V10.881Z" fill="#FF0000" />
  </svg>
</template>

<script setup lang="ts">
interface Props {
  fill1?: string
}
withDefaults(defineProps<Props>(), {
  fill1: 'otivo-blue'
})
</script>
