import getApiInstance from '@/services/Api'
import { InputItem } from '@/types/InputItem.ts'
const api = getApiInstance()

export const searchInvestmentOptions = async (id: number, string = '') => {
  const investmentOptions = await api
    .Get(`/chant-west/investments?id=${id}`)
    .catch((err) => console.error(err))

  if (investmentOptions) {
    const options: InputItem<string>[] = []

    Object.keys(investmentOptions.data).forEach((option) => {
      options.push({
        value: investmentOptions.data[option].id,
        label: investmentOptions.data[option].name
      })
    })
    return options.filter((option) => option.label.toLowerCase().includes(string.toLowerCase()))
  }
  return []
}
