<template>
  <div class="col-span-2 md:col-span-1">
    <span class="button-2">Payment Type</span>
    <div class="relative">
      <Dropdown
        class="w-full mt-[5px]"
        name="home-loan-interest_only"
        place-holder="name"
        type="responsible"
        data-test="debtPaymentType"
        :existing-item="mapPaymentType(liability.interest_only)"
        :items="paymentTypeOptions"
        @selected="(selected) => updateSelected(selected)" />
      <p v-if="liabilityErrors?.interest_only" class="otivo-error-message absolute right-0">
        {{ liabilityErrors?.interest_only?.[0] }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { computed } from 'vue'
import { useDebtFormLogic } from '@/composables/useDebtFormLogic.ts'

const liabilityStore = useLiabilityStore()
const liability = computed(() => liabilityStore.liability)
const liabilityErrors = computed(() => liabilityStore.liabilityErrors)

const { mapPaymentType, paymentTypeOptions } = useDebtFormLogic(liabilityStore)

const emit = defineEmits<(event: 'change', message: string) => void>()

const updateSelected = (selected) => {
  liability.value.interest_only = selected.value
  emit('change', 'Payment type updated')
}
</script>

<style scoped></style>
