import * as LDClient from 'launchdarkly-js-client-sdk'
import { computed, reactive, ref } from 'vue'

export const ldClient = ref<LDClient.LDClient | null>(null)
const flags = reactive<Record<string, unknown>>({})
const isLDReady = ref(false)

export function initLDClient(
  clientSideID: string,
  context: LDClient.LDContext,
  options?: LDClient.LDOptions,
) {
  try {
    const client = LDClient.initialize(clientSideID, context, options)
    ldClient.value = client
    client.on('ready', () => {
      isLDReady.value = true
      try {
        const allFlags = client.allFlags()
        Object.keys(allFlags).forEach((key) => {
          flags[key] = allFlags[key]
        })
      } catch (e) {
        console.error('Error while fetching flags', e)
      }
    })

    client.on('change', (flag: LDClient.LDFlagChangeset) => {
      try {
        const key = Object.keys(flag)[0]
        flags[key] = flag[key].current
        console.info(`[LD] Flag ${key} updated to ${flag[key].current}`)
      } catch (e) {
        console.error('Error while updating flags', e)
      }
    })
  } catch (e) {
    cleanupLDClient()
    throw new Error('Error starting LaunchDarkly client')
  }
}

export function useLDFlag<T = boolean>(flagKey: string, defaultValue?: T) {
  return computed<T>(() => {
    // If client is ready and we have a known value, use it
    if (isLDReady.value && flags[flagKey] !== undefined) {
      return flags[flagKey] as T
    }
    // Otherwise, do a live variation call if client is loaded but flags array not updated
    if (ldClient.value) {
      return ldClient.value.variation(flagKey, defaultValue)
    }
    // Or fallback to your default if not ready
    return defaultValue
  })
}

export function cleanupLDClient() {
  if (ldClient.value) {
    ldClient.value.close()
    ldClient.value = null
    isLDReady.value = false
    Object.keys(flags).forEach((key) => delete flags[key])
  }
}

export const FeatureList = {
  ENABLE_DTD: 'enable-dtd',
  ENABLE_CFS_MOD_2: 'enable-cfs-module-2',
  ENABLE_WEALTH_HEALTH_CHECK: 'enable-wealth-health-check',
  MAINTENANCE_MODE: 'force-frontend-maintenance-mode',
  ENABLE_CFS_MOD_2_3: 'enable-cfs-module-2.3',
  ENABLE_INSURANCE_MODULE: 'enable-insurance-module',
  HIDE_REPORTING: 'hide-reporting',
  LIFE_PLAN_DASHBOARD: 'life-plan-dashboard',
  ENABLE_CHAT: 'enable-chat',
} as const

export type Feature = (typeof FeatureList)[keyof typeof FeatureList]
