import {
  calculateMinCreditCardRepayment,
  calculateMinInterestOnlyRepayment,
  calculateMinLoanRepayment,
  Frequency,
  LoanParams as MinRepaymentParams,
} from '@/composables/debt/computeMinRepayments.ts'
import { Liability, LiabilityType } from '@/types/Liability.ts'
import { frequencyMap } from '@/composables/debt/computeLoanRepayments.ts'

export const getMinimumRepayment = (liability: Liability, frequencyOverride?: Frequency) => {
  const calculations: Record<LiabilityType | 'default', (params: MinRepaymentParams) => number> = {
    credit_card: calculateMinCreditCardRepayment,
    default: liability.interest_only
      ? calculateMinInterestOnlyRepayment
      : calculateMinLoanRepayment,
  }

  const calculation =
    liability?.type === 'credit_card' ? calculations['credit_card'] : calculations['default']

  return calculation({
    balance: Number(liability.balance),
    interestRate: Number(liability.interest_rate),
    frequency: frequencyOverride ?? liability.payment_frequency,
    term: Number(liability.term),
    offsetBalance: Number(liability.offset_balance), // if undefined defaults to 0
  })
}

export const getMinimumTermForLiability = (liability: Liability, frequencyOverride?: Frequency) => {
  return liability.term
    ? Number(liability.term)
    : -Math.log(
      1 -
      (liability.interest_rate / 100 / frequencyMap[liability.payment_frequency]) *
      (liability.balance / getMinimumRepayment(liability)),
    ) /
    Math.log(
      1 + liability.interest_rate / 100 / frequencyMap[liability.payment_frequency],
    )
}
