<template>
  <div v-if="pageContent" id="otivo-home" class="min-h-75vh bg-blue-6">
    <div class="flex flex-col bg-white">
      <div
        class="px-6 md:px-0 max-w-[1280px] h-100% w-100% flex flex-col items-center mx-auto my-[80px]">
        <h1 class="w-100% justify-center text-center flex">Otivo and Colonial First State</h1>
        <p class="intro-1 mx-auto max-w-[800px] mt-[40px] text-center">
          Like us, CFS is on a mission to empower their members to take control of their financial
          future. To give them a hand, they've turned to us to provide licensed financial advice to
          their members to help them be better off.
        </p>
        <div class="flex flex-row gap-6 mt-[40px]">
          <OtivoButton @click="auth0.createAccount">Get started</OtivoButton>
          <OtivoButton @click="auth0.login" class="w-[175px]" colour="white">Log in</OtivoButton>
        </div>
        <img :src="MsBluePants" alt="Blue Pants" class="mt-[40px]" />
        <MediaLogos class="md:mt-6" />
      </div>
      <RegularCurve fill="blue-6" class="bg-white h-6 w-full" />
      <GettingStartedTiles
        v-if="whitelabelData?.superfund_config?.super_products?.length"
        class="py-[60px] bg-blue-6" />
      <HomeThreeTileDisplay
        class="!bg-midnight"
        text-colour="white"
        top-curve-fill="blue-6"
        bottom-curve-fill="blue-6"
        :icon-boxes="pageContent.homepageThreeTileDisplay.iterableItems" />
      <Testimonials :testimonial-page-data="pageContent.cfsTestimonials" class="bg-blue-6" />
      <Awards
        class="bg-grey-1 text-white py-[50px] md:py-[70px]"
        :color="{
          textColour: 'white',
          svgLineFill: 'white',
          svgImageBackgroundFill: 'grey-1',
        }" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import MsBluePants from '@/components/SVGS/MsBluePants.svg?url'
import MediaLogos from '@/components/SVGS/MediaLogos.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import GettingStartedTiles from '@/components/Dashboard/GettingStartedTiles.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import HomeThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import Testimonials from '@/components/BrandComponents/Otivo/Testimonials.vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin.ts'
import Awards from '@/components/Footer/Awards.vue'
import { useRouter } from 'vue-router'
import { useLDFlag, FeatureList } from '@/lib/ldClient.ts'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'

const store = useStore()
const auth0 = useAuth0()
const whitelabelData = computed(() => useSessionStore().getWhitelabelData)
const pageContent = computed(() => store.getters['ContentStore/pageContent'])

// don't change this, it's extremely temperamental
// defaults undefined otherwise it breaks
const router = useRouter()
const enableDTD = useLDFlag(FeatureList.ENABLE_DTD)

watch(
  () => enableDTD.value,
  (newVal) => {
    if (newVal === false) {
      router.push('/client/signin')
    }
  },
  {
    immediate: true,
  },
)

onBeforeMount(() => {
  store.dispatch('ContentStore/getContent', 'cfsHomepage')
})
</script>

<style scoped></style>
