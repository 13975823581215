<template>
  <form autocomplete="off" id="super-report-form">
    <input-text
      class="w-full"
      label-classes="pt-0"
      input-classes="paragraph-2 text-left rounded-sm max-h-[40px] border-grey-4"
      place-holder="First name"
      label="First name"
      :stack-label="true"
      name="super-report-first-name"
      :value="firstName"
      @input="updateFirstName"
      v-model="firstName"
      :error="errors.firstName" />
    <input-text
      class="w-full pt-[20px]"
      label-classes="pt-0"
      input-classes="paragraph-2 text-left rounded-sm max-h-[40px] border-grey-4"
      place-holder="Last name"
      label="Last name"
      :stack-label="true"
      name="super-report-last-name"
      :value="lastName"
      @input="updateLastName" />
    <input-text
      class="w-full pt-[20px]"
      label-classes="pt-0"
      label="Job Title"
      :stack-label="true"
      input-classes="paragraph-2 text-left rounded-sm max-h-[40px] border-grey-4"
      place-holder="Job Title"
      name="super-report-job-title"
      :value="jobTitle"
      @input="updatejobTitle" />
    <input-text
      class="w-full pt-[20px]"
      label-classes="pt-0"
      input-classes="paragraph-2 text-left rounded-sm max-h-[40px] border-grey-4"
      place-holder="Company"
      name="business"
      label="Company"
      :stack-label="true"
      :value="business"
      @input="updateBusiness" />
    <input-text
      type="email"
      class="w-full pt-[20px]"
      label-classes="pt-0"
      input-classes="paragraph-2 text-left rounded-sm max-h-[40px] border-grey-4"
      label="Email address"
      :stack-label="true"
      :error="errors.email"
      place-holder="Email address"
      name="super-report-email"
      :value="email"
      @input="updateEmail"
      :allow-digits="true" />
    <input-check-box
      class="w-full pt-[20px]"
      label="I agree to receive information from Otivo"
      name="super-report-consent"
      :checked="consent"
      @click="updateMarketingConsent" />
  </form>
</template>

<script lang="ts" setup>
import MiscellaneousService from '@/services/MiscellaneousService'
import { ref } from 'vue'
import { validateEmailAddress } from '@/lib/ValidationHelper'
import { useStore } from 'vuex'
import InputText from '@/components/BasicInputs/InputText.vue'
import InputCheckBox from '@/components/BasicInputs/InputCheckBox.vue'
import { useToast } from '@/composables/useToast.ts'

const store = useStore()
const { errorToast, successToast } = useToast()

const firstName = ref('')
const lastName = ref('')
const email = ref('')
const consent = ref(false)
const jobTitle = ref('')
const business = ref('')
const loading = ref(false)
const errors = ref({
  firstName: '',
  email: ''
})

const updateFirstName = (value: string) => {
  firstName.value = value
}
const updateLastName = (value: string) => {
  lastName.value = value
}
const updateEmail = (value: string) => {
  email.value = value
}
const updateBusiness = (value: string) => {
  business.value = value
}
const updatejobTitle = (value: string) => {
  jobTitle.value = value
}
const updateMarketingConsent = (value: boolean) => {
  consent.value = value
}

const submitOtivoSuperReportForm = () => {
  if (validateOtivoSuperReportForm()) {
    loading.value = true
    const payload = {
      type: 'super-report-download',
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      business: business.value,
      jobTitle: jobTitle.value,
      marketingConsent: consent.value,
      whitelabel_id: store.getters.getCurrentWhitelabelObject.id
    }
    MiscellaneousService.submitOtivoSuperReportForm(payload)
      .then(() => {
        loading.value = false
        successToast('Check your email.')
        firstName.value = ''
        lastName.value = ''
        jobTitle.value = ''
        business.value = ''
        email.value = ''
      })
      .catch((err) => {
        loading.value = false
        errorToast('Error occurred, please try again.')
        throw err
      })
  }
}

const validateOtivoSuperReportForm = () => {
  errors.value = {
    firstName: '',
    email: ''
  }
  let validated = true
  if (firstName.value === '') {
    validated = false
    errors.value.firstName = 'Please enter your first name'
  }

  if (!validateEmailAddress(email.value)) {
    validated = false
    errors.value.email = 'Please enter a valid email address'
  }

  return validated
}

defineExpose({
  submitOtivoSuperReportForm
})
</script>
