// configs WITH TEST DATA

const commonDatasetConfig = {
  borderRadius: Number.MAX_VALUE,
  borderWidth: 1,
  borderSkipped: false,
  minBarThickness: 20,
  maxBarThickness: 25,
  categoryPercentage: 1,
  datalabels: {
    color: 'transparent',
  },
}

const datalabelObject = {
  color: '#fff',
  anchor: 'start',
  align: 'top',
  clipping: true,
  font: {
    weight: 'bold',
  },
}

const allModulesConfig = {
  labels: ['ADVICE ISSUED', 'ADVICE ACTIONED'],
  datasets: [
    {
      ...commonDatasetConfig,
      categoryPercentage: 0.45,
      backgroundColor: '#62A3FF',
      label: 'Investment options',
      data: [0, 0],
      datalabels: datalabelObject,
    },
    {
      ...commonDatasetConfig,
      categoryPercentage: 0.45,
      backgroundColor: '#BB01CF',
      label: 'Salary sacrifice',
      data: [0, 0],
      datalabels: datalabelObject,
    },
    {
      ...commonDatasetConfig,
      categoryPercentage: 0.45,
      backgroundColor: '#FD8900',
      label: 'Deductible contributions',
      data: [0, 0],
      datalabels: datalabelObject,
    },
    {
      ...commonDatasetConfig,
      categoryPercentage: 0.45,
      backgroundColor: '#00966E',
      label: 'Insurance',
      data: [0, 0],
      datalabels: datalabelObject,
    },
    {
      ...commonDatasetConfig,
      categoryPercentage: 0.45,
      backgroundColor: '#0037A1',
      label: 'Debt',
      data: [0, 0],
      datalabels: datalabelObject,
    },
  ],
}

// same for investmentOption, salarySacrifice, insurance, deductibleContributions
type InvestmentOptionMemberEngagement = {
  datasets: { issued: number[]; actioned: number[] }
  labels: string[]
}

const investmentOptionsConfig = {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'sat', 'sun'],
  datasets: [
    {
      ...commonDatasetConfig,
      backgroundColor: '#62A3FF',
      label: 'Advice issued',
      data: [24, 110, 33, 47, 55, 55, 55],
    },
    {
      ...commonDatasetConfig,
      backgroundColor: '#FF0000',
      label: 'Advice actioned',
      data: [10, 60, 29, 33, 54, 44, 33],
    },
  ],
}

const salarySacrificeConfig = {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  datasets: [
    {
      ...commonDatasetConfig,
      backgroundColor: '#BB01CF',
      label: 'Advice issued',
      data: [24, 110, 33, 47, 55],
    },
    {
      ...commonDatasetConfig,
      backgroundColor: '#FF0000',
      label: 'Advice actioned',
      data: [10, 60, 29, 33, 54],
    },
  ],
}

const insuranceConfig = {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  datasets: [
    {
      ...commonDatasetConfig,
      backgroundColor: '#00966E',
      label: 'Advice issued',
      data: [24, 110, 33, 47, 55],
    },
    {
      ...commonDatasetConfig,
      backgroundColor: '#FF0000',
      label: 'Advice actioned',
      data: [10, 60, 29, 33, 54],
    },
  ],
}

const contributionsConfig = {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  datasets: [
    {
      ...commonDatasetConfig,
      backgroundColor: '#FD8900',
      label: 'Advice issued',
      data: [24, 110, 74, 97, 75],
    },
    {
      ...commonDatasetConfig,
      backgroundColor: '#FF0000',
      label: 'Advice actioned',
      data: [10, 50, 39, 83, 54],
    },
  ],
}


const debtConfig = {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  datasets: [
    {
      ...commonDatasetConfig,
      backgroundColor: '#0037A1',
      label: 'Advice issued',
      data: [24, 110, 74, 97, 75],
    },
    {
      ...commonDatasetConfig,
      backgroundColor: '#FF0000',
      label: 'Advice actioned',
      data: [10, 50, 39, 83, 54],
    },
  ],
}

export default {
  'super-investments': investmentOptionsConfig,
  'salary-sacrifice': salarySacrificeConfig,
  'deductible-contributions': contributionsConfig,
  insurance: insuranceConfig,
  debt: debtConfig,
  all: allModulesConfig,
}
