<template>
  <section class="flex flex-col gap-y-10 rounded-lg p-10 bg-white">
    <h6 class="text-blue-1">All average scores</h6>
    <div id="graph-container" class="relative">
      <div class="w-full h-[250px] relative" :class="{ 'opacity-20': loading }">
        <canvas id="line-chart"></canvas>
      </div>
      <div
        id="line-legend-container"
        class="mt-[20px] flex flex-row justify-center items-center"></div>
      <div
        v-show="loading"
        class="rounded flex flex-row justify-center items-center absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
        <BeatLoader color="#0064FF" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, nextTick, watch } from 'vue'
import { Chart, ChartConfiguration } from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import { useDebounceFn } from '@vueuse/core'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { format } from 'date-fns'

Chart.register(annotationPlugin)
const chart = ref<Chart | null>(null)
type Props = {
  dataset: Array<{
    label: string
    data: Record<string, number>
    participation: Record<string, number>
  }>
  loading: boolean
}
const props = defineProps<Props>()

const setupGraph = (): ChartConfiguration => {
  const labels = generateLabels()
  const borderColor = ['#0064FF', '#EE0490', '#FD8900', '#00966E', '#FF0000']
  const datasets = []
  props.dataset.forEach((data, index) => {
    datasets.push({
      label: data.label,
      data: Object.values(data.data),
      borderColor: borderColor[index],
      borderWidth: 3,
      pointRadius: 0,
      borderCapStyle: 'round',
      order: index,
      participation: Object.values(data.participation),
    })
  })

  return {
    type: 'line',
    data: {
      labels,
      datasets,
    },
    options: {
      maintainAspectRatio: false,
      layout: { padding: 0 },
      interaction: { intersect: false, mode: 'nearest', axis: 'xy' },
      scales: {
        x: {
          border: { display: false },
          grid: { display: false },
          title: { display: false },
          ticks: {
            source: 'auto',
            autoSkip: true,
            maxTicksLimit: labels.length,
          },
        },
        y: {
          border: { display: false },
          ticks: {
            padding: 3,
            maxTicksLimit: 3,
            callback: (value: number) => (value ? `${value}% ` : '0% '),
          },
          grid: { color: '#70D6FF' },
        },
      },
      plugins: {
        tooltip: {
          backgroundColor: '#000032',
          labelTextColor: '#fff',
          callbacks: {
            title: (context) => context[0].label,
            label: (context) => {
              let label = context.dataset.label || ''

              if (label) {
                label += ' average : '
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y + '%'
              }
              return ` ${label}`
            },
            labelColor: (context) => {
              return {
                borderColor: '#fff',
                backgroundColor: context.dataset.borderColor,
                borderRadius: 2,
              }
            },
            afterLabel: (context) => {
              let label = '  Clients completed:'
              if (context.dataset?.participation) {
                label += 'Participation: ' + context.dataset.participation[context.dataIndex]
              }
              return label
            },
          },
        },
        htmlLegend: { containerID: 'line-legend-container' },
        legend: { display: false, position: 'bottom', align: 'center' },
      },
    },
    plugins: [htmlLegendPlugin],
  }
}

const drawGraph = useDebounceFn(() => {
  const config = setupGraph()
  let canvas: HTMLCanvasElement
  nextTick(() => {
    canvas = document.getElementById('line-chart') as HTMLCanvasElement
    if (chart.value) {
      chart.value?.destroy()
    }
    chart.value = new Chart(canvas, config)
  })
}, 300)

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const div = getOrCreateLegendList(options.containerID)
    if (!div) {
      console.warn('LegendList not found')
      return null
    }
    // Remove old legend items
    while (div.firstChild) {
      div.firstChild.remove()
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)
    items.forEach((item) => {
      const legendItem = document.createElement('div')
      Object.assign(legendItem.style, {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'Raleway',
        flexWrap: 'wrap',
        padding: '5px',
        borderRadius: '3px',
        border: '1px solid #EEEBEB',
        marginLeft: '5px',
      })

      if (item.hidden) {
        // disabled style
        legendItem.style.background = 'white'
        legendItem.style.border = '1px solid #EEEBEB'
        legendItem.style.borderLeft = '3px solid #EEEBEB'
      } else {
        // enabled style
        legendItem.style.background = 'white'
        legendItem.style.borderLeft = `3px solid ${item.strokeStyle}`
      }

      legendItem.onclick = () => {
        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        chart.update()
      }

      // Text
      const textContainer = document.createElement('p')
      Object.assign(textContainer.style, {
        color: '#696969',
        fontSize: '10px',
        textTransform: 'uppercase',
        fontFamily: 'Raleway',
        fontWeight: 'bold',
        margin: '0',
        padding: '0',
      })
      const text = document.createTextNode(item.text)
      textContainer.appendChild(text)
      legendItem.appendChild(textContainer)
      div.appendChild(legendItem)
    })
  },
}

const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id)
  if (!legendContainer) {
    return console.warn('Null HTMLElement found')
  }
  let listContainer = legendContainer.querySelector('div')

  if (!listContainer) {
    listContainer = document.createElement('div')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.flexWrap = 'wrap'
    listContainer.style.justifyContent = 'center'
    listContainer.style.gap = '5px'
    legendContainer.appendChild(listContainer)
  }

  return listContainer
}

watch(
  () => props.loading,
  (newVal, oldValue) => {
    if (!newVal && oldValue) {
      drawGraph()
    }
  },
  { immediate: true },
)

const generateLabels = () => {
  const labels = Object.keys(props.dataset[0]?.data)
  if (!labels.length) {
    const months = []
    let date = new Date()
    for (let i = 0; i < 12; i++) {
      months.push(date.toLocaleString('default', { month: 'short' }))
      date.setMonth(date.getMonth() + 1)
    }

    return months
  }

  return labels.map((label) => {
    const date = new Date(label)
    const yearMonthDayRegex = /^\d{4}-\d{2}-\d{2}$/
    if (yearMonthDayRegex.test(label)) {
      return format(date, 'dd MMM')
    }
    return format(date, 'MMM yyyy')
  })
}
</script>
