<template>
  <div class="min-h-100% md:max-w-[375px] mx-auto px-6">
    <h5 id="survey-title" class="bold">You’ve requested to deactivate your Otivo account.</h5>
    <p class="mt-6 text-blue-1 mb-[40px]">
      We’re sad to see you go!
      <br />
      <br />
      Before you leave, please let us know why you’re leaving so we can continue to improve. Your
      feedback will be kept confidential and used solely for internal purposes.
    </p>

    <Loader v-if="surveyAnswers.length === 0 || isLoading" />
    <div v-else>
      <div v-for="(questions, key) in surveyAnswers" :key="key">
        <div class="flex mt-[10px]">
          <BaseCheckbox
            :checked="questions.selected"
            :aria-label="questions.text"
            @change="questions.selected = !questions.selected" />
          <label class="ml-[10px] paragraph-1">{{ questions.text }}</label>
        </div>
        <BaseTextArea
          :disabled="!questions.selected"
          v-if="questions.text === 'Other'"
          class="paragraph-3 mt-[10px]"
          name="other-field"
          type="field"
          rows="5"
          placeholder="Type in you answer here"
          v-model:value="otherText">
        </BaseTextArea>
      </div>
    </div>

    <OtivoButton
      class="mt-[40px] w-full"
      colour="red"
      @click="proceed"
      :loading="isSubmitting"
      :disabled="isSubmitting || isLoading">
      Deactivate account
    </OtivoButton>
    <div
      class="mt-[40px] text-center button-1 underline text-otivo-blue hover:text-blue-1 cursor-pointer"
      @click="goBack()">
      Please keep my account active
    </div>
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import UserService from '@/services/UserService.ts'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import { onBeforeMount, ref } from 'vue'
import Loader from '@/components/Loader/Loader.vue'
import BaseTextArea from '@/components/Inputs/BaseTextArea.vue'
import { useToast } from '@/composables/useToast.ts'

const router = useRouter()
const userStore = useUserStore()

const { successToast, errorToast } = useToast()

const surveyAnswers = ref([])
const otherText = ref('')
const isLoading = ref(false)
const isSubmitting = ref(false)

onBeforeMount(async () => {
  try {
    isLoading.value = true
    const res = await UserService.surveyAnswers()
    res.data.forEach((question) => {
      surveyAnswers.value.push({ text: question.reason, id: question.id, selected: false })
    })
  } catch (e) {
    errorToast('Failed to load survey questions')
  } finally {
    isLoading.value = false
  }
})

const proceed = async () => {
  isSubmitting.value = true
  await deactivateAccount()
  isSubmitting.value = false
}

const deactivateAccount = async () => {
  const surveyResponse = surveyAnswers.value
    .filter((question) => question.selected)
    .map((activeResponse) => activeResponse.id)
  const payload = {
    surveyResponse: surveyResponse,
    otherText: otherText.value,
  }
  const res = await UserService.deactivateAccount(payload)
  if (res.status === 200) {
    successToast('Account Deactivated')
    emit('next', 'survey complete')
  } else {
    errorToast('Failed to deactivate account')
    throw new Error('Failed to deactivate account')
  }
  return
}

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const goBack = () => {
  router.push({ name: 'clientDashboard' })
}
</script>

<style scoped></style>
