<template>
  <div class="col-span-2">
    <div class="button-2">Name <img :src="AsteriskIcon" class="inline mb-2" /></div>
    <BaseInput
      v-model:value="liability.name"
      class="w-full mt-[10px]"
      name="debt-name"
      type="text"
      data-test="debtName"
      :error-message="liabilityErrors?.name?.[0]" />
  </div>
  <div class="col-span-2 md:col-span-1">
    <div class="button-2">Balance <img :src="AsteriskIcon" class="inline mb-2" /></div>
    <BaseInput
      v-model:value="liability.balance"
      class="w-full mt-[10px]"
      name="debt-balance"
      placeholder="0"
      type="currency"
      data-test="debtBalance"
      :error-message="liabilityErrors?.balance?.[0]" />
  </div>
  <div class="col-span-2 md:col-span-1">
    <div class="button-2">Interest % p.a. <img :src="AsteriskIcon" class="inline mb-2" /></div>
    <PercentageInput
      v-model:value="liability.interest_rate"
      class="w-full mt-[10px]"
      name="debt-interest_rate"
      data-test="debtInterestRate"
      :error-message="liabilityErrors?.interest_rate?.[0]" />
  </div>
</template>

<script setup lang="ts">
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { computed } from 'vue'
import PercentageInput from '@/components/BasicInputs/PercentageInput.vue'
import AsteriskIcon from '@/components/SVGS/AsteriskIcon.svg?url'

const liabilityStore = useLiabilityStore()
const liability = computed(() => liabilityStore.liability)
const liabilityErrors = computed(() => liabilityStore.liabilityErrors)
</script>
