<template>
  <div class="col-span-2 md:col-span-1">
    <div class="button-2">
      Current repayment
      <img v-if="!liability.paid_monthly" :src="AsteriskIcon" class="inline mb-2" />
    </div>
    <BaseInput
      v-model:value="liability.repayment_amount"
      type="currency"
      class="w-full mt-[10px]"
      name="debt-repayment_amount"
      placeholder="0"
      data-test="debtRepaymentAmount"
      :disabled="liability.paid_monthly"
      :error-message="
        liabilityErrors?.repayment_amount?.[0] || liabilityErrors?.min_payment?.[0]
      " />
    <span
      v-if="minRepayment > 0 && !liabilityErrors?.repayment_amount?.length > 0"
      class="text-grey-2 paragraph-3"
      >The minimum repayment is {{ formatAsCurrency(minRepayment) }}</span
    >
  </div>
</template>

<script setup lang="ts">
import formatAsCurrency from '@/composables/formatAsCurrency.ts'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { computed, ref } from 'vue'
import { debouncedWatch } from '@vueuse/core'
import { Liability, LiabilityType } from '@/types/Liability.ts'
import {
  calculateMinCreditCardRepayment,
  calculateMinInterestOnlyRepayment,
  calculateMinLoanRepayment,
  LoanParams as MinRepaymentParams,
} from '@/composables/debt/computeMinRepayments.ts'
import AsteriskIcon from '@/components/SVGS/AsteriskIcon.svg?url'

const liabilityStore = useLiabilityStore()
const liability = computed(() => liabilityStore.liability)
const liabilityErrors = computed(() => liabilityStore.liabilityErrors)

const minRepayment = ref(0)

debouncedWatch(
  liability.value,
  (currentLiability: Liability) => {
    minRepayment.value = computeMinimumRepayment(currentLiability)
    liability.value.min_payment = Math.round(minRepayment.value) >= 0 ? minRepayment.value : 0
  },
  { debounce: 1000, deep: true, immediate: true },
)

const computeMinimumRepayment = (currentLiability: Liability) => {
  if (currentLiability.paid_monthly) {
    liability.value.repayment_amount = 0
    return 0
  }
  const calculations: Record<LiabilityType | 'default', (params: MinRepaymentParams) => number> = {
    credit_card: calculateMinCreditCardRepayment,
    default: currentLiability.interest_only
      ? calculateMinInterestOnlyRepayment
      : calculateMinLoanRepayment,
  }

  const calculation =
    currentLiability?.type === 'credit_card' ? calculations['credit_card'] : calculations['default']

  return calculation({
    balance: Number(currentLiability.balance),
    interestRate: Number(currentLiability.interest_rate),
    frequency: currentLiability.payment_frequency,
    term: Number(currentLiability.term),
    offsetBalance: Number(currentLiability.offset_balance),
  })
}
</script>

<style scoped></style>
