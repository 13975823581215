<template>
  <div class="md:flex gap-[40px] w-100% md:max-w-[1127px]">
    <div class="md:max-w-[895px] w-100% mt-[65px] md:mt-0">
      <div class="flex flex-col mt-[20px] mb-[40px]">
        <h4 class="text-blue-1 md:text-left text-22px md:text-30px px-[20px] md:px-0">
          Investment options inside super
        </h4>
        <SelectGuidanceSuperAccount
          v-if="isAdmin() && isCFSWhitelabel() && superStore.getSupersArray.length > 1"
          class="sm:w-[375px] sm:self-end px-5 flex mt-[40px]" />
      </div>

      <Transition name="fade">
        <InvestmentOptionsChart v-if="user.has_consented && activeSuper?.investments?.length" />
      </Transition>

      <InvestmentAccountDetails class="mt-10" @optimize-advice="fetchSuperInvestmentAdvice()" />

      <InvestmentExclusions class="mt-10" v-if="activeSuper?.investments?.length" />

      <TransitionGroup name="fade" mode="in-out">
        <div
          v-if="user.has_consented && state.loading && isUser()"
          :class="{ 'rounded-t-none': state.graphLoaded }"
          class="flex flex-col justify-center p-4 text-center bg-white rounded-lg my-[20px] shadow">
          <h5 class="text-otivo-blue" data-test="loadingInvestmentOptions">
            Hang tight... We're searching through your available investment options now.
          </h5>
          <loader />
        </div>
        <template
          v-if="
            user.has_consented && state.isEligibleForRecommendations && state.showRecommendations
          ">
          <InvestmentOptionsRecommendation />

          <RecommendedInvestmentOptions />
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onUnmounted, ref, watch, watchEffect } from 'vue'

import ClientConfirmationConfirmSuper from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationConfirmSuper.vue'
import ClientConfirmationSOADisplay from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationSOADisplay.vue'
import ClientConfirmationSuccessModal from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationSuccessModal.vue'
import InvestmentAccountDetails from '@/components/CFS/IntrafundInvestment/Partials/InvestmentAccountDetails.vue'
import InvestmentOptionsChart from '@/components/CFS/IntrafundInvestment/Partials/InvestmentOptionsChart.vue'
import InvestmentOptionsRecommendation from '@/components/CFS/IntrafundInvestment/Partials/InvestmentOptionsRecommendation.vue'
import Loader from '@/components/Loader/Loader.vue'
import RecommendedInvestmentOptions from '@/components/CFS/IntrafundInvestment/Partials/RecommendedInvestmentOptions.vue'
import SelectGuidanceSuperAccount from '@/components/CFS/Guidance/SelectGuidanceSuperAccount.vue'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { superInvestmentRecommendationHandlers } from '@/components/CFS/IntrafundInvestment/Utils/superInvestmentRecommendationHandlers.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import { usePageContentStore } from '@/store/pinia/PageContentStore.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import InvestmentExclusions from '@/components/CFS/IntrafundInvestment/Partials/InvestmentExclusions.vue'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { webSocketHandler } from '@/components/CFS/IntrafundInvestment/Utils/webSocketHandlers.ts'
import { isCFSWhitelabel } from '@/composables/isCFSWhitelabel.ts'

const modalStore = useModalStore()
const pageContentStore = usePageContentStore()
const superStore = useSuperStore()
const userStore = useUserStore()
const { isUser, isAdmin } = useCheckUser()
const { fetchSuperInvestmentAdvice, resetAdviceState } = superInvestmentRecommendationHandlers(
  userStore,
  superStore,
  isUser,
)
const { setupClientSuperWebsocket, setupClientConsentWebsocket, setupAdviceRequestWebsocket } =
  webSocketHandler()

const activeSuper = computed(() => superStore.getActiveSuperObject)
const pageContent = computed(() => pageContentStore.pageContent)
const user = computed(() => userStore.getUser)

const refuseCashChanges = ref(false)

onBeforeMount(() => {
  pageContentStore.fetchContent('intraSuperWidget')
  /**
   * This is for when you return with pre-existing data, we don't want to reshow the full loading state
   */
  if (ready.value) fetchAdvice() // if ready, get advice!
})

const fetchAdvice = () => {
  try {
    fetchSuperInvestmentAdvice()
  } catch (error) {
    console.error('Error fetching super investment advice', error)
  }
}

/**
 * Ready we only want to set to true once to prevent unnecessary re-processing
 * This maintains a healthy graph
 * consent and investments are required to run both the graph and the recommendation
 * ref() used to ensure reactivity for the watch
 */
const ready = ref(false)
watchEffect(() => {
  if (
    user.value.has_consented &&
    activeSuper.value?.investments &&
    activeSuper.value?.investments.length
  ) {
    ready.value = true
  }
})

watch(
  () => ready.value,
  (ready) => {
    if (ready) {
      fetchAdvice()
    }
  },
)

watch(
  () => state.activeModal,
  (newValue) => {
    if (newValue) {
      switch (newValue) {
        case 'confirmSuper':
          modalStore.openModal(ClientConfirmationConfirmSuper, {
            refuseCashChanges: refuseCashChanges.value,
            pageContent: pageContent.value.confirmSuper,
            selectedCurrentOption: state.selectedCurrentOption, // if the selected option has current tag,
            cancelAdvice: () => {
              state.activeModal = ''
              modalStore.closeModal()
            },
          })
          break
        case 'clientSOA':
          modalStore.openModal(ClientConfirmationSOADisplay, {
            pageContent: pageContent.value.soaDisplay,
            onNext: () => {
              //NOTE: I would love this to not be like this in the future, leaving in for v3 release because 'cfs must stay the same'
              if (
                useSessionStore().getWhitelabelData.identifier.toUpperCase() ===
                'COLONIAL_FIRST_STATE'
              )
                state.activeModal = 'successModal'
              else {
                state.activeModal = ''
                modalStore.closeModal()
              }
            },
          })
          break
        case 'successModal':
          modalStore.openModal(ClientConfirmationSuccessModal, {
            pageContent: pageContent.value.successModal,
            selectedCurrentOption: state.selectedCurrentOption,
            onClickTakeBack: () => {
              state.activeModal = ''
              modalStore.closeModal()
            },
          })
          break
        default:
          if (newValue) {
            console.error('Invalid modal type', newValue)
          }
          break
      }
    }
  },
)

// User is set on the parent component so loads after
watch(
  () => user.value,
  () => {
    if (isAdmin()) {
      if (!user.value.has_consented) setupClientConsentWebsocket(user.value)
      setupAdviceRequestWebsocket(user.value.account_id)
    }
    if (isUser() && user.value.has_consented) {
      setupClientSuperWebsocket(user.value.account_id, isUser())
    }
  },
  { immediate: true }, // This is required to trigger the watch on the first load
)

const resetWidgetState = () => {
  state.showRecommendations = false
  state.graphLoaded = false
  resetAdviceState()
}

modalStore.$onAction(({ name }) => {
  if (name === 'closeModal') {
    state.activeModal = ''
  }
})

onUnmounted(() => {
  ready.value = false
})
</script>

<style scoped></style>
