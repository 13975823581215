<template>
  <section class="flex flex-row gap-x-5 justify-between">
    <template v-for="tile in tiles" :key="tile.label">
      <div
        v-if="!loading"
        class="w-1/5 bg-white border border-blue-4 rounded p-5 flex flex-col gap-y-2.5">
        <p class="button-4">{{ tile.label }}</p>
        <h3 class="text-blue-1">{{ tile.value }}</h3>
      </div>
    </template>
    <template v-for="tile in 5" :key="tile">
      <div
        v-if="loading"
        class="mx-auto w-full max-w-sm rounded-md border border-blue-4 bg-white p-5 rounded gap-y-2.5">
        <div class="flex flex-col animate-pulse gap-y-2.5">
          <div class="h-2.5 rounded bg-grey-4"></div>
          <div class="h-10 w-12 rounded bg-grey-4"></div>
        </div>
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
type Props = {
  tiles: Array<{ label: string; value: string }>
  loading: boolean
}
defineProps<Props>()
</script>

<style scoped></style>
