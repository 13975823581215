<template>
  <div class="w-full">
    <DebtInfo v-if="displayPreamble" :acknowledge-preamble="showDynamicOnboarding" />
    <div class="w-full grid md:gap-10" v-else>
      <div class="text-blue-1 w-full px-5 py-10 md:p-0">
        <h4 class="text-blue-1 h4">Debt plan</h4>
        <div v-if="totalDebtAmount > 0">
          <span class="mp2 md:paragraph-3 text-grey-2">Total debt: </span>
          <span class="button-1 text-blue-1">{{ formatAsCurrency(totalDebtAmount) }}</span>
        </div>
      </div>

      <div
        v-if="liabilities.length === 0 && !liabilityStore.loadingStates?.fetchingAll"
        class="bg-blue-6 md:bg-white md:border md:rounded-lg md:border-blue-3 pb-10 px-5 md:p-10">
        <!-- User has no debts  -->
        <h5 class="font-bold text-blue-1">Congratulations! Looks like you’re debt free. 🎉</h5>
        <p class="intro-1 mt-5 text-blue-1">
          If you do have debts, just click the button below to add them. Once we have all your info,
          we’ll work out an easy-to-understand strategy to help you pay them down faster.
        </p>
      </div>
      <div
        v-else-if="liabilitiesIncomplete"
        class="bg-white md:border md:rounded-lg md:border-blue-3 px-5 py-10 md:p-10">
        <h5 class="text-blue-1 bold">Here’s the one thing you need to do next!</h5>
        <Status state="infoNeeded" status-style="outline" class="mt-2.5" data-test="debtInfoNeeded" />
        <p class="intro-1 mt-5 text-blue-1">
          Before we can provide advice, we just need a bit more info. So please fill in the details
          for each of your debts in the table below.
        </p>
        <OtivoButton @click="nextIncompleteDebt" class="mt-10" data-test="getStarted"> Get started </OtivoButton>
      </div>
      <CurrentDebtRecommendationHeader
        class="bg-white md:border md:rounded-lg md:border-blue-3"
        :debt-recommendation="liabilityStore.recommendation"
        v-else-if="recommendation" />
      <ManageDebts
        :total-debt-amount="totalDebtAmount"
        :total-repayment-amount="totalRepaymentAmount" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLiabilityStore } from '@/store/pinia/LiabilityStore'
import { computed, onMounted } from 'vue'
import CurrentDebtRecommendationHeader from '@/views/Otivo/Dashboard/Debt/Components/CurrentDebtRecommendationHeader.vue'
import ManageDebts from '@/views/Otivo/Dashboard/Debt/Components/ManageDebts.vue'
import { useAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'
import DebtInfo from '@/views/Otivo/Dashboard/Debt/Components/DebtInfo.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { useRoute, useRouter } from 'vue-router'
import DynamicOnboarding from '@/components/Onboarding/DynamicOnboarding.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import formatAsCurrency from '@/composables/formatAsCurrency.ts'
import Status from '@/components/Status.vue'
import setupUser from '@/composables/users/setupUser.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'

const liabilityStore = useLiabilityStore()
const accountFlagsStore = useAccountFlagsStore()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const { openModal } = useModalStore()

const liabilities = computed(() => liabilityStore.liabilities)
const recommendation = computed(() => liabilityStore.recommendation)
const totalDebtAmount = computed(() => liabilityStore.debtTotal)
const totalRepaymentAmount = computed(() => liabilityStore.repaymentTotal)

const displayPreamble = computed(
  () => accountFlagsStore.getFlag('debtPlan').questions_completed === null,
)

const liabilitiesIncomplete = computed(() => {
  return liabilities.value.some((liability) => liability.module_status === 'infoNeeded')
})

const nextIncompleteDebt = () => {
  const incompleteDebt = liabilities.value.find(
    (liability) => liability.module_status === 'infoNeeded',
  )
  if (incompleteDebt) {
    useLiabilityStore().setLiability(incompleteDebt)
    router.push({
      name: route.meta.isAdminPortal ? 'AdminDebtDetails' : 'DebtDetails',
      params: { id: incompleteDebt.id, type: incompleteDebt.type },
    })
  }
}

const showDynamicOnboarding = () => {
  openModal(
    DynamicOnboarding,
    {
      onboardingModule: 'debt_plan',
      onComplete: async () => {
        await Promise.all([
          userStore.fetchUser(),
          liabilityStore.fetchLiabilities(),
          liabilityStore.fetchDebtRecommendation(),
          setupUser(),
        ])
      },
    },
    'large',
    true,
  )
}

onMounted(() => {
  liabilityStore.fetchLiabilities()
  if (!liabilitiesIncomplete.value) {
    liabilityStore.fetchDebtRecommendation()
  }
})
</script>
