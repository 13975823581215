import confetti from 'canvas-confetti'

export const useConfetti = () => {
  const show = () => {
    confetti({
      particleCount: 500,
      spread: 300,
      origin: { y: 0.9 },
    })
    confetti({
      particleCount: 500,
      spread: 300,
      origin: { x: 0 },
    })
    confetti({
      particleCount: 500,
      spread: 300,
      origin: { x: 1 },
    })
  }

  const randomise = () => {
    confetti({
      particleCount: 500,
      spread: 300,
      origin: { y: generateRandomNumber() },
    })
    confetti({
      particleCount: 500,
      spread: 300,
      origin: { x: generateRandomNumber() },
    })
    confetti({
      particleCount: 500,
      spread: 300,
      origin: { x: generateRandomNumber() },
    })
  }

  const generateRandomNumber = () => +Math.random().toFixed(2)

  return {
    show,
    randomise,
  }
}
