import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import ContentService from '@/services/ContentService'
import UserService from '@/services/UserService'
import { cloneObject, convertToKebabCase } from '@/utilities'

export type FlagType = Record<string, string | null>
export type GenericNotificationsType = Record<string, string | null>
export type AccountFlagStatus = 'ideal' | 'loading' | 'success' | 'error'

export type FlagKey =
  | 'superInvestments'
  | 'salarySacrificeContributions'
  | 'lumpSumConcessionalContributions'
  | 'lumpSumNonConcessionalContributions'
  | 'personalInsurance'
  | 'debtPlan'
  | 'genericQuestions'
  | 'appNotifications'
  | 'subscriptionRenewalConsent'
  | 'guidance_client_consent_email_sent'
  | 'quarterly_wealth_health_check_enabled'

export interface IAccountFlagsStore {
  getFlag: (flag: FlagKey, value?: string) => string | null | GenericNotificationsType | undefined
  accountFlags: Record<FlagKey, FlagType | GenericNotificationsType | boolean>
  fetchAccountFlags: () => Promise<void>
  updateAccountFlags: (flag: FlagKey, subFlagKey?: string) => Promise<void>
  reset: () => void
  status: AccountFlagStatus
}

const emptyFlags: Record<FlagKey, FlagType | GenericNotificationsType | boolean> = {
  superInvestments: { acknowledged_disclaimer: null },
  salarySacrificeContributions: { acknowledged_disclaimer: null, questions_completed: null },
  lumpSumConcessionalContributions: { acknowledged_disclaimer: null, questions_completed: null },
  lumpSumNonConcessionalContributions: { acknowledged_disclaimer: null, questions_completed: null },
  personalInsurance: { acknowledged_disclaimer: null, questions_completed: null },
  debtPlan: { acknowledged_disclaimer: null, questions_completed: null },
  genericQuestions: { questions_completed: null },
  appNotifications: {
    has_seen_july_1st_notification: null,
    has_acknowledged_wealth_health_check: null,
  },
  subscriptionRenewalConsent: { acknowledged_disclaimer: null },
  guidance_client_consent_email_sent: false,
  quarterly_wealth_health_check_enabled: false,
}

export const useAccountFlagsStore = defineStore('accountFlagsStore', () => {
  const accountFlags = ref<Record<FlagKey, FlagType | GenericNotificationsType | boolean>>(
    cloneObject(emptyFlags),
  )
  const status = ref<AccountFlagStatus>('ideal')

  const reset = () => {
    accountFlags.value = cloneObject(emptyFlags)
  }

  const getFlag = (flag: FlagKey, value?: string): string | null | GenericNotificationsType => {
    const flagData = accountFlags.value[flag]
    if (value) return (flagData as FlagType)[value] ?? null
    return flagData as GenericNotificationsType
  }

  const fetchAccountFlags = async () => {
    try {
      status.value = 'loading'
      const response = await ContentService.getAccountFlags()
      if (response?.data) {
        accountFlags.value = { ...response.data, ...response.data.flags }
        status.value = 'success'
      } else {
        throw new Error('Invalid response format')
      }
    } catch (error) {
      console.error('Error fetching account flags:', error)
      status.value = 'error'
    }
  }

  const updateAccountFlags = async (flag: FlagKey, subFlagKey?: string) => {
    try {
      const cleanedFlag = convertToKebabCase(flag)
      const currentFlagData = accountFlags.value[flag]

      if (typeof currentFlagData === 'boolean') return

      const updatedFlag = subFlagKey
        ? { [cleanedFlag]: { ...currentFlagData, [subFlagKey]: new Date().toISOString() } }
        : { [cleanedFlag]: { acknowledged_disclaimer: new Date().toISOString() } }

      const response = await UserService.UpdateDisclaimerFlags(updatedFlag)
      if (response?.data) {
        accountFlags.value = { ...response.data, ...response.data.flags }
      } else {
        throw new Error('Invalid response format')
      }
    } catch (error) {
      console.error('Error updating account flags:', error)
    }
  }

  return {
    getFlag,
    accountFlags,
    getAccountFlags: computed(() => accountFlags.value),
    fetchAccountFlags,
    updateAccountFlags,
    status,
    reset,
  }
})
