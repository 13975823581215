import { Component } from 'vue'
import { Router, RouteRecordRaw } from 'vue-router'
import createRouter from '@/router/getRouter.ts'
import otivoRoutes from '@/router/otivoRoutes.ts'
import cfsRoutes from '@/router/clients/cfs/cfsRoutes.ts'
import adminPortalRoutes from '@/router/adminPortalRoutes.ts'
import cfsAdminPortalRoutes from '@/router/clients/cfs/adminPortalRoutes.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'

export type InstanceRecord = {
  [key: string]: FrontendInstance
}

export type FrontendInstance = {
  AUTH0_ID: string
  ROUTER: Router
  SENTRY_ID: string
}

type FrontendConfig = {
  entry: string
  auth0_client_id: string
  router: string
  sentry_dsn: string
}

// UPDATE DEFAULT ROUTE OBJECTS HERE
const ROUTES: { [key: string]: RouteRecordRaw[] } = {
  otivoRouter: otivoRoutes,
  cfsRouter: cfsRoutes, // May have to dependency inject stores here to avoid circular dependencies
}

// UPDATE ENTRY POINT COMPONENTS HERE
// const entryPoints: { [key: string]: Component } = {
//   Main: Main,
// }

// export const getEntry = (entryKey: string): Component => {
//   return entryPoints[entryKey]
// }

export const setupInstance = (
  config: FrontendConfig,
  adminPortalEnabled: 0 | 1,
): FrontendInstance => {
  return {
    AUTH0_ID: config.auth0_client_id,
    ROUTER: buildRouter(config.router, adminPortalEnabled),
    SENTRY_ID: config.sentry_dsn,
  }
}

const buildRouter = (routeKey: string, adminPortalEnabled: 0 | 1): Router => {
  const routes = [ROUTES[routeKey]]
  if (adminPortalEnabled) {
    routes.push(getAdminPortalRoutes(routeKey))
  }
  return createRouter(routes.flat() as RouteRecordRaw[])
}

const getAdminPortalRoutes = (routeKey: string): RouteRecordRaw[] => {
  switch (routeKey) {
    case 'otivoRouter':
      return adminPortalRoutes(useSessionStore(), useUserStore(), useAccountFlagsStore())
    case 'cfsRouter':
      return cfsAdminPortalRoutes(useSessionStore(), useUserStore(), useAccountFlagsStore())
    default:
      throw new Error('Invalid route key')
  }
}
