import { RouteRecordRaw } from 'vue-router'
import { isAuthenticated, login, userRole } from '@/lib/AuthenticatorPlugin.ts'
import CorporateWellbeing from '@/views/Otivo/Brand/Solutions/CorporateWellbeing.vue'
import OtivoSuperReport from '@/views/Otivo/Brand/Solutions/OtivoSuperReport.vue'
import AboutUs from '@/views/Otivo/Brand/About/AboutUs.vue'
import ContactUs from '@/views/Otivo/Brand/About/ContactUs.vue'
import HowOtivoMakesMoney from '@/views/Otivo/Brand/About/HowOtivoMakesMoney.vue'
import FinancialFitness from '@/views/Otivo/Brand/Resources/FinancialFitness.vue'
import FAQs from '@/views/Otivo/Brand/Resources/FAQs.vue'
import RedirectToCompany from '@/components/RedirectToCompany/RedirectToCompany.vue'
import Callback from '@/components/Basiq/Callback.vue'
import SampleDashboard from '@/views/Otivo/Brand/SampleDashboard.vue'
import SampleAssets from '@/views/Otivo/Brand/SampleAssets.vue'
import SampleColours from '@/views/Otivo/Brand/SampleColours.vue'
import SampleComponents from '@/views/Otivo/Brand/SampleComponents.vue'
import SampleTypography from '@/views/Otivo/Brand/SampleTypography.vue'
import redirectToUserRoot from '@/composables/redirectToUserRoot.ts'
import HomepageContainer from '@/views/Otivo/HomepageContainer.vue'
import CFSRetirementCalculator from '@/views/Clients/CFS/CFSRetirementCalculator.vue'
import ChatContainer from '@/views/Otivo/Chat/ChatContainer.vue'
import { useLDFlag, FeatureList } from '@/lib/ldClient.ts'

export default [
  {
    path: '/',
    name: 'home',
    component: HomepageContainer,
    beforeEnter: (to, from, next) => {
      redirectToUserRoot(userRole.value, isAuthenticated.value, next)
    },
  },
  {
    path: '/employee-wellbeing',
    name: 'Employee wellbeing',
    meta: {
      type: 'business',
    },
    component: CorporateWellbeing,
  },
  {
    path: '/super-report',
    name: 'The Otivo Super Report',
    meta: {
      type: 'business',
    },
    component: OtivoSuperReport,
  },
  // END BUSINESS
  // START ABOUT
  // {
  //   path: '/advice-overview',
  //   name: 'Advice overview',
  //   component: AdviceOverview,
  // },
  {
    path: '/our-team',
    name: 'About Otivo',
    component: AboutUs,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactUs,
  },
  {
    path: '/how-otivo-makes-money',
    name: 'How Otivo makes money',
    component: HowOtivoMakesMoney,
  },
  // END ABOUT
  // RESOURCES
  {
    path: '/financial-fitness',
    name: 'Financial fitness',
    component: FinancialFitness,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQs,
  },
  {
    path: '/learn',
    name: 'Learn',
    component: () => import('@/views/Otivo/Brand/Resources/Articles/Articles.vue'),
  },
  {
    path: '/learn/search',
    name: 'Search',
    component: () => import('@/views/Otivo/Brand/Resources/Articles/Search.vue'),
  },
  {
    path: '/learn/security',
    name: 'Article Security',
    component: () => import('@/views/Otivo/Brand/Resources/Articles/Security.vue'),
  },
  {
    path: '/learn/:slug+',
    name: 'Slug',
    component: () => import('@/views/Otivo/Brand/Resources/Articles/Article.vue'),
  },
  {
    path: '/ask-otivo-super',
    name: 'Ask Otivo super',
    component: () => import('@/views/Otivo/Brand/Resources/AskOtivoSuper.vue'),
  },
  {
    path: '/home',
    redirect: '/',
  },
  {
    path: '/company/:company',
    name: 'redirect-to-company',
    component: RedirectToCompany,
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback,
  },
  {
    path: '/sample',
    component: SampleDashboard,
    beforeEnter: (to, from, next) => {
      if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') next()
    },
    children: [
      {
        path: 'assets',
        name: 'sampleAssets',
        component: SampleAssets,
      },
      {
        path: 'colours',
        name: 'sampleColours',
        component: SampleColours,
      },
      {
        path: 'components',
        name: 'sampleComponents',
        component: SampleComponents,
      },
      {
        path: 'typography',
        name: 'sampleTypography',
        component: SampleTypography,
      },
    ],
  },
  // This should be on CFS routes, but until they change their URL it'll be here for the time being
  {
    path: '/cfs/retirementCalculator',
    name: 'cfsRetirementCalculator',
    component: CFSRetirementCalculator,
    beforeEnter: () => {
      const trackingScript = document.createElement('script')
      trackingScript.src = import.meta.env.VITE_CFS_TRACKING_URL
      trackingScript.type = 'text/javascript'
      trackingScript.async = true
      document.getElementsByTagName('head')[0].appendChild(trackingScript)
    },
  },
] as Array<RouteRecordRaw>
