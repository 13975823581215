import computeTotalInterest from '@/composables/debt/computeTotalInterest.ts'

type PaymentFrequency = 'weekly' | 'monthly' | 'fortnightly' | 'quarterly' | 'annually'
type CompoundingFrequency = 'daily' | 'monthly' | 'quarterly' | 'annually'
type AmortisationEntry = {
  period: number
  payment: number
  principalPayment: number
  interestPayment: number
  remainingBalance: number
}
export type LoanDetails = {
  paymentAmount: number
  totalInterestPaid: number
  totalAmountPaid: number
  effectiveAnnualRate: number
  amortisationSchedule: AmortisationEntry[]
}

const periodsPerYearMap: Record<PaymentFrequency | CompoundingFrequency, number> = {
  daily: 365,
  weekly: 52,
  fortnightly: 26,
  monthly: 12,
  quarterly: 4,
  annually: 1,
}

export const calculateEffectiveInterestRate = (
  annualRate: number,
  compoundingFrequency: CompoundingFrequency,
): number => {
  const n = periodsPerYearMap[compoundingFrequency]
  const effectiveRate = (1 + annualRate / 100 / n) ** n - 1
  return effectiveRate * 100
}

export type LoanParams = {
  principal: number
  paymentAmount: number
  offsetBalance?: number
  nominalAnnualRate: number
  remainingTerm: number // months
  paymentFrequency: PaymentFrequency
  compoundingFrequency?: CompoundingFrequency
  interestOnly: boolean
}

export const calculatePeriodRate = (effectiveRate: number, paymentFrequency: PaymentFrequency) => {
  const paymentPeriodsPerYear = periodsPerYearMap[paymentFrequency]
  let periodRate = Math.pow(1 + effectiveRate / 100, 1 / paymentPeriodsPerYear) - 1
  periodRate = Math.round(periodRate * 10 ** 16) / 10 ** 16 // for the love of all things that are holy round to 16.d.p
  return periodRate
}

export const useCalculateLoanRepayments = (params: LoanParams): LoanDetails => {
  const {
    principal,
    paymentAmount,
    offsetBalance = 0,
    nominalAnnualRate,
    remainingTerm,
    paymentFrequency,
    compoundingFrequency = 'monthly',
    interestOnly,
  } = params

  // effective interest rate
  const effectiveRate = calculateEffectiveInterestRate(nominalAnnualRate, compoundingFrequency)

  //calculate total compounding periods
  const paymentPeriodsPerYear = periodsPerYearMap[paymentFrequency]
  const totalPaymentPeriods = (remainingTerm / 12) * paymentPeriodsPerYear

  // get periodic rate
  const periodRate = calculatePeriodRate(effectiveRate, paymentFrequency)

  // create schedule with starting balance
  const amortisationSchedule: AmortisationEntry[] = [
    {
      period: 0,
      payment: 0,
      principalPayment: 0,
      interestPayment: 0,
      remainingBalance: principal,
    },
  ]

  let remainingBalance = principal
  let totalInterestPaid = 0
  if (interestOnly) {
    for (let period = 1; period <= totalPaymentPeriods; period++) {
      const interestPayment = Math.max(remainingBalance * periodRate, 0)
      const principalPayment = 0
      totalInterestPaid += interestPayment
      amortisationSchedule.push({
        period,
        payment: parseFloat(paymentAmount.toFixed(2)),
        principalPayment: parseFloat(principalPayment.toFixed(2)),
        interestPayment: parseFloat(interestPayment.toFixed(2)),
        remainingBalance: parseFloat(remainingBalance.toFixed(2)),
      })
    }
  } else {
    // Period starts at 1 to account for the pre-generated starting term of the loan
    // e.g everything below zero except remaining balance
    // totalPaymentPeriods also must ignore the starting index hence we don't pay that period as it's the 0th period
    for (let period = 1; period <= totalPaymentPeriods + 1; period += 1) {
      // if you're paying off a lump sum, one and done, it takes no extra periods
      if (paymentAmount >= remainingBalance && period === 1) {
        amortisationSchedule.push({
          period,
          payment: parseFloat(paymentAmount.toFixed(2)),
          principalPayment: parseFloat(paymentAmount.toFixed(2)),
          interestPayment: 0,
          remainingBalance: 0,
        })
        break
      }

      const interestPayment = Math.max((remainingBalance - offsetBalance) * periodRate, 0)
      const principalPayment = paymentAmount - interestPayment
      remainingBalance -= principalPayment
      totalInterestPaid += interestPayment

      if (remainingBalance <= 0) {
        remainingBalance = 0
      }

      amortisationSchedule.push({
        period,
        payment: parseFloat(paymentAmount.toFixed(2)),
        principalPayment: parseFloat(principalPayment.toFixed(2)),
        interestPayment: parseFloat(interestPayment.toFixed(2)),
        remainingBalance: parseFloat(remainingBalance.toFixed(2)),
      })
      if (remainingBalance == 0) break
    }
  }

  const totalInterestPaidLogFormula = computeTotalInterest(
    paymentAmount,
    periodRate,
    offsetBalance < principal ? principal - offsetBalance : 0,
  )
  return {
    paymentAmount,
    totalInterestPaid: totalInterestPaidLogFormula,
    // totalInterestPaid: Math.ceil(totalInterestPaid),
    totalAmountPaid: Math.ceil(principal + totalInterestPaidLogFormula),
    effectiveAnnualRate: effectiveRate,
    amortisationSchedule: amortisationSchedule,
  }
}

export const frequencyMap = {
  monthly: 12,
  fortnightly: 26,
  weekly: 52,
}
