<template>
  <BaseView>
    <div id="financial-fitness" v-if="pageContent?.currentPage === 'financialFitnessPage'">
      <Breadcrumbs />
      <FitnessTest v-if="showModal" @close-modal="closeModal" />
      <LandingPageModule
        :show-logos="false"
        class="items-center"
        background-color="blue-5"
        :landing-page-content="pageContent.financialFitnessHero">
        <template #svgComponent>
          <img
            class="max-w-[90%] md:max-w-[588px] m-auto pt-5 transform transition-all duration-1000 hover:scale-105 px-5 md:px-0"
            :src="FinancialFitnessGroup"
            alt="Three people exercising next to a phone" data-test="financialFitnessImage"/>

          <div class="flex justify-center items-center pt-5 gap-2 mt-10">
            <HowFitAreYou />
            <OtivoButton colour="blue" data-test="financialFitnessBtn" @click="openModal">
              {{ pageContent.financialFitnessHero.buttonText }}
            </OtivoButton>
          </div>
        </template>
      </LandingPageModule>
      <RegularCurve fill="blue-5" class="bg-white" rotate />
      <Awards
        class="bg-white py-16"
        :color="{
          textColour: 'grey-1',
          svgLineFill: 'grey-1',
          svgImageBackgroundFill: 'white',
        }" />
      <ThreeTileDisplay
        top-curve-fill="grey-1"
        bottom-curve-fill="grey-1"
        text-colour="white"
        class="!bg-grey-1"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import FitnessTest from '@/components/FitnessTest/FitnessTest.vue'
import HowFitAreYou from '@/components/SVGS/HowFitAreYou.vue'
import FinancialFitnessGroup from '@/assets/img/financial-fitness.webp?url'
import LandingPageModule from '@/components/BrandComponents/LandingPageModule.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import Awards from '@/components/Footer/Awards.vue'
import { useHead } from '@unhead/vue'

const store = useStore()
const showModal = ref(false)

onBeforeMount(() => store.dispatch('ContentStore/getContent', 'financialFitnessPage'))
const pageContent = computed(() => store.getters['ContentStore/pageContent'])

useHead({
  title: "Otivo's financial fitness test",
})

const openModal = () => {
  showModal.value = true
}

const closeModal = () => {
  showModal.value = false
}
</script>
