<template>
  <div class="flex flex-col md:flex-row gap-[5px] md:gap-[20px] p-[5px] rounded-[10px] mt-[20px]">
    <BaseTabButton
      class="w-full"
      v-for="(option, index) in tabButtonData"
      :key="index"
      :is-active="option.value === activeTab.value"
      :option="option"
      :data-test="option.label"
      @change="(val) => toggle(val)" />
  </div>
</template>

<script setup lang="ts">
import BaseTabButton from '@/components/Inputs/BaseTabButton.vue'
import { InputItem } from '@/types/InputItem.ts'
import { onMounted, ref } from 'vue'

type Props = {
  tabButtonData: Array<InputItem<string>>
  existingValue?: string | number
}

const props = defineProps<Props>()
const emits = defineEmits<{
  (e: 'toggle', val: InputItem<string>): InputItem<string>
}>()
const activeTab = ref<InputItem<string>>({ label: 'Asset', value: 'asset' })
const toggle = (val: InputItem<string>) => {
  activeTab.value = val
  emits('toggle', val)
}

onMounted(() => {
  if (props.existingValue) {
    activeTab.value = props.tabButtonData.find((item) => {
      // @TODO Figure out why the backend sometimes sends 0 and 1 instead of yes and no
      let value = props.existingValue?.toString().toLowerCase()
      if (value === '0') value = 'no'
      if (value === '1') value = 'yes'
      return item.value.toLowerCase() === value
    }) as InputItem<string>
  }
})
</script>

<style scoped></style>
