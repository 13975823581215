export type DebtRecommendation = {
  debt: {
    plan: string
    stage: number
    action: boolean
    name: string
    why: string
    heading: string
    body: string
    if_you_do_not_act: string
    toolTip: string
    recommended_commit: number
    reason: string
    repayment_amount: number
    plan_id: number
    type: string
    group: string
  }
  orderedPlans: string[]
}

export const RepaymentTypeEnum = {
  MINIMUM_REPAYMENT: 'Minimum repayment',
  CURRENT_REPAYMENT: 'Current repayment',
  RECOMMENDED_REPAYMENT: 'Recommended repayment',
} as const

export type RepaymentType = (typeof RepaymentTypeEnum)[keyof typeof RepaymentTypeEnum]
export type Repayment = {
  repayment_type: RepaymentType
  monthly_amount: number
  time_to_pay_off: string
  total_interest: number
  infoCircleMessage?: string
}
